<template>
  <div>
    <v-card
      id="programs"
      class="mb-4"
      data-testid="programs"
      border
      flat
      tile
    >
      <v-card-title class="tab-title d-flex">
        <span>{{ $t(terms.programs) }}</span>

        <v-spacer />
        <v-select
          @update:model-value="updateQuery({ subsidyProgramId: $event })"
          :aria-label="$t('Subsidy program')"
          :items="subsidyPrograms"
          :model-value="$route.query.subsidyProgramId"
          class="me-3 mxw-350"
          density="compact"
          item-title="name"
          item-value="id"
          variant="filled"
          hide-details
        />

        <template v-if="!$store.state.pages.Provider.features.enable_program_lock">
          <v-btn
            @click="openDraft()"
            color="primary"
            data-cy="new-program-button"
          >
            <v-icon
              class="fs-24"
              icon="add"
            />
            {{ $t(`Create new ${terms.program.toLowerCase()}`) }}
          </v-btn>
        </template>
      </v-card-title>
      <v-divider />
      <v-card-text class="my-4">
        <v-row class="mb-2">
          <v-col class="fs-18">
            <span>
              {{
                $t(
                  description ||
                    'Add classrooms, before or after school programs, summer programs etc.',
                )
              }}
            </span>
          </v-col>
        </v-row>

        <v-row v-if="programs.length > 0">
          <v-col
            v-for="(program, index) in programs"
            :key="index"
            class="mb-4"
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
              :data-testid="`program-${program.id}`"
              role="listitem"
              border
              flat
              tile
            >
              <v-card-title>
                <v-row>
                  <v-col cols="10">
                    <span
                      v-text="$t(program.name)"
                      class="fs-22"
                      data-cy="program-name"
                    />
                  </v-col>
                  <v-col
                    class="d-flex justify-end"
                    cols="2"
                  >
                    <ActionMenu
                      @click:action:delete="destroy(program)"
                      @click:action:duplicate="duplicate(program)"
                      @click:action:edit="editProgram(program)"
                      @click:action:seats="$refs.editSeatsDialog.open(program)"
                      :button-icon-size="20"
                      :items="actionItems"
                      button-data-cy="edit-program-button"
                      button-icon="more_vert"
                      left
                      x-small
                    />
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text>
                <ProviderProgram
                  :dashboard="dashboard"
                  :program="program"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ProgramEditor
      @save="save"
      ref="programEditor"
      :age-groups="ageGroups"
      :processing="processing"
      :subsidy-programs="subsidyPrograms"
    />

    <SeatsEditor
      @change="loadDashboard"
      ref="editSeatsDialog"
      :subsidy-program="subsidyProgramFilter"
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import ProgramEditor from '@/shared/components/provider/ProgramEditor.vue';
import { ProgramParams } from '@/shared/services/program-params';
import ProviderProgram from '@/shared/components/provider/ProviderProgram.vue';
import SeatsEditor from '@/specialist/components/provider/SeatsEditor.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useTerms from '@/shared/composables/useTerms';
import useSubsidyPrograms from '@/specialist/composables/useSubsidyPrograms';

const eventBus = useEventBus();
const { updateQuery } = useRouterHelper();
const route = useRoute();
const store = useStore();
const { terms } = useTerms();
const { dashboard, load, programs, subsidyPrograms, subsidyProgramFilter } = useSubsidyPrograms();

const props = defineProps({
  ageGroups: {
    type: Array,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
  providerId: {
    type: String,
    default: null,
  },
  regularHours: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['progress', 'refresh']);

// This exists in program-params - determine if we can remove
const hours = [
  {
    day: 0,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 1,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 2,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 3,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 4,
    open: 700,
    close: 1800,
    included: true,
  },
  {
    day: 5,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 6,
    open: 700,
    close: 1800,
    included: false,
  },
];

const confirmDialog = ref(null);
const processing = ref(false);
const programEditor = ref(null);

const actionItems = computed(() => {
  const items = [{ avatar: 'edit', event: 'edit', title: 'Edit' }];

  if (
    subsidyProgramFilter.value?.enable_deferred_acceptance &&
    store.state.profile.org_enrollments_admin
  ) {
    items.push({ event: 'seats', title: 'Edit seats', avatar: 'chair_alt' });
  }

  if (store.state.pages.Provider.features.enable_program_lock) return items;

  items.push({ event: 'duplicate', title: 'Duplicate', avatar: 'content_copy' });
  items.push({ event: 'delete', title: 'Delete', avatar: 'delete' });
  return items;
});

watch(() => props.providerId, load, { immediate: true });

watch(
  () => route.query.tab,
  (newVal) => {
    if (newVal === 'programs') void load();
  },
  { immediate: true },
);

async function destroy(program) {
  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to remove this ${terms.value.program.toLowerCase()}?`,
  );
  if (!confirm) return;

  processing.value = true;
  Api.program.destroy(props.providerId, program.id, async () => {
    processing.value = false;
    await load();
    emit('refresh');
  });
}

function duplicate(program) {
  const draft = JSON.parse(JSON.stringify(program));
  draft.id = null;
  if (!draft.hours) {
    draft.regular_hours = true;
    draft.hours = getDefaultHours();
  }
  programEditor.value?.open(draft);
}

function editProgram(program) {
  const draft = JSON.parse(JSON.stringify(program));
  if (!draft.hours) {
    draft.regular_hours = true;
    draft.hours = getDefaultHours();
  }
  programEditor.value?.open(draft);
}

function getBlankProgram() {
  return {
    age_group_ids: [],
    age_max_months: true,
    age_min_months: true,
    custom: {},
    hours: getDefaultHours(),
    is_free: false,
    is_donation_based: false,
    regular_hours: true,
    season: 'Year round',
    schema_id: Object.values(store.state.schemas).filter(
      (schema) => schema.data_type === 'Program',
    )[0].id,
  };
}

function getDefaultHours() {
  if (!props.regularHours) return hours;

  return hours.map(function (new_day) {
    const old_day = props.regularHours.find((old_day) => old_day.day === new_day.day);
    if (old_day) {
      new_day.close = old_day.close;
      new_day.included = true;
      new_day.open = old_day.open;
      return new_day;
    }
    new_day.included = false;
    return new_day;
  });
}

function openDraft() {
  const draft = getBlankProgram();
  programEditor.value?.open(draft);
}

function save(draft) {
  processing.value = true;
  const params = new ProgramParams(draft).asJson();
  Api.program.updateOrCreate(
    props.providerId,
    params,
    async () => {
      await load();
      processing.value = false;
      programEditor.value?.close();
      eventBus.chime('Saved');
      emit('refresh');
    },
    (error) => {
      processing.value = false;
      eventBus.longChime(error.response.data.errors[0]);
    },
  );
}
</script>

<style>
.h-56 {
  height: 56px;
}

.w-150 {
  width: 150px;
}
</style>
