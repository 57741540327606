<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <v-card
      class="mt-3 mx-3 bt-1 br-1 bl-1 bc-outlined-gray"
      tile
    >
      <v-card-text class="fs-15 py-0">
        <v-row dense>
          <v-col
            class="d-flex align-center"
            cols="9"
          >
            <v-toolbar>
              <FilterMenu
                :active="filters.application_program_id.length > 0"
                :title="programFilterLabel"
                classes="d-none d-md-inline-flex"
              >
                <template #card>
                  <v-autocomplete
                    v-model="filters.application_program_id"
                    @update:model-value="loadPayments()"
                    :items="programs"
                    item-title="name"
                    item-value="id"
                    variant="filled"
                    chips
                    closable-chips
                    hide-details
                    multiple
                  />
                </template>
              </FilterMenu>

              <FilterMenu
                :active="!!filters.business_id"
                :title="businessFilterLabel"
                classes="d-none d-md-inline-flex"
              >
                <template #card>
                  <v-autocomplete
                    v-model="filters.business_id"
                    v-model:search="businessQuery"
                    @update:model-value="loadPayments()"
                    :items="businesses"
                    item-title="name"
                    item-value="id"
                    variant="filled"
                    clearable
                    hide-details
                    hide-no-data
                  />
                </template>
              </FilterMenu>

              <FilterMenu
                :active="!!filters.start_date"
                classes="d-none d-md-inline-flex"
              >
                <template #card>
                  <v-row dense>
                    <LabeledDatePicker
                      v-model="filters.start_date"
                      @change="loadPayments"
                    />
                  </v-row>
                </template>
                <template #title>
                  <LongDate
                    :date="filters.start_date"
                    nulltext="Start date"
                    prefix="From"
                  />
                </template>
              </FilterMenu>

              <FilterMenu
                :active="!!filters.end_date"
                classes="d-none d-md-inline-flex"
              >
                <template #card>
                  <v-row dense>
                    <LabeledDatePicker
                      v-model="filters.end_date"
                      @change="loadPayments"
                    />
                  </v-row>
                </template>
                <template #title>
                  <LongDate
                    :date="filters.end_date"
                    nulltext="End date"
                    prefix="To"
                  />
                </template>
              </FilterMenu>

              <FilterMenu
                :active="!!filters.status"
                :title="statusFilterLabel"
                classes="d-none d-md-inline-flex"
              >
                <template #card>
                  <v-select
                    v-model="filters.status"
                    @update:model-value="loadPayments()"
                    :items="statuses"
                    variant="filled"
                    clearable
                    hide-details
                    hide-no-data
                  />
                </template>
              </FilterMenu>
            </v-toolbar>
          </v-col>

          <v-col
            class="d-flex justify-end align-center"
            cols="3"
          >
            <template v-if="filters.ids.length > 0 || allChecked">
              <v-btn
                :href="link"
                color="primary"
                target="_blank"
              >
                {{ $t('Download payments') }} ({{
                  allChecked ? collection.length : filters.ids.length
                }})
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <PaymentTable
        @check="toggleCheck"
        @check:all="toggleCheckAll"
        @open="$refs.paymentEditor.open($event.id)"
        @toggle:sort="toggleSort"
        ref="paymentTable"
        :collection="collection"
        :filters="filters"
        :processing="processing"
      />
    </v-container>

    <v-pagination
      v-model="filters.page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />

    <PaymentEditor
      @change="loadPayments"
      ref="paymentEditor"
    />

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import PaymentEditor from '@/specialist/components/PaymentEditor.vue';
import PaymentTable from '@/specialist/components/PaymentTable.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LongDate from '@/shared/components/LongDate.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();

const allChecked = ref(false);
const businesses = ref([]);
const businessQuery = ref(null);
const businessQueryCount = ref(0);
const collection = ref([]);
const filters = ref(defaultFilters());
const pages = ref(1);
const paymentEditor = ref(null);
const processing = ref(false);
const programs = ref([]);
const paymentTable = ref(null);
const selectedItems = ref({});

const statuses = ref([
  { text: 'Draft', value: 'draft' },
  { text: 'Approved', value: 'approved' },
  { text: 'Canceled', value: 'canceled' },
  { text: 'Processing', value: 'processing' },
  { text: 'Processed', value: 'processed' },
]);

const businessFilterLabel = computed(() => {
  if (filters.value.business_id) {
    return businesses.value.find((business) => business.id === filters.value.business_id)?.name;
  }

  return 'Business';
});

const programFilterLabel = computed(() => {
  if (filters.value.application_program_id && filters.value.application_program_id.length > 0) {
    return `(${filters.value.application_program_id.length}) ${t('programs')}`;
  }

  return 'Program';
});

const link = computed(() => Api.organization.payment.downloadUrl(filters.value));

const statusFilterLabel = computed(() => {
  if (filters.value.status) {
    return statuses.value.find((status) => status.value === filters.value.status).text;
  }

  return 'Status';
});

watch(businessQuery, (newVal) => {
  if (newVal) {
    loadBusinesses(newVal);
  } else {
    businesses.value = [];
  }
});

watch(
  () => filters.value.page,
  () => loadPayments(),
);

onMounted(async () => {
  loadBusinesses();
  await loadPrograms();
  void loadPayments();
});

function defaultFilters() {
  if (store.state.filters.payment) {
    return store.state.filters.payment;
  }

  return {
    business_id: null,
    end_date: null,
    application_program_id: [],
    ids: [],
    page: 1,
    sort_dir: null,
    sort_field: null,
    start_date: null,
  };
}

async function loadPayments() {
  filters.value.ids.splice(0);
  store.commit('setFilters', ['payment', filters.value]);
  const resp = await Api.organization.payment.index(filters.value);
  pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
  collection.value = resp.data;
  selectedItems.value = {};
  processing.value = false;
}

function loadBusinesses(query) {
  businessQueryCount.value += 1;
  const currentBusinessQueryCount = businessQueryCount.value;

  Api.organization.business.index({ query }, (resp) => {
    if (businessQueryCount.value === currentBusinessQueryCount) {
      businesses.value = resp.data;
    }
  });
}

async function loadPrograms() {
  programs.value = [];

  Api.organization.grant_program.index((resp) => {
    programs.value = programs.value.concat(resp.data);
  });

  const resp = await Api.organization.subsidy_program.index();
  programs.value = programs.value.concat(resp.data);
}

function toggleCheck(paymentId) {
  if (allChecked.value) {
    filters.value.ids = collection.value.map((item) => item.id);
    allChecked.value = false;
    paymentTable.value.$refs.checkAll.check(false);
  }

  const index = filters.value.ids.indexOf(paymentId);
  if (index >= 0) {
    filters.value.ids.splice(index, 1);
  } else {
    filters.value.ids.push(paymentId);
  }
}

function toggleCheckAll() {
  allChecked.value = !allChecked.value;

  if (allChecked.value) {
    paymentTable.value.$refs.paymentRow.forEach((item) => item.check(true));
    filters.value.ids = collection.value.map((item) => item.id);
  } else {
    filters.value.ids.splice(0);
    paymentTable.value.$refs.paymentRow.forEach((item) => item.check(false));
  }
}

function toggleSort(field) {
  if (filters.value.sort_field !== field) {
    filters.value.sort_dir = 'desc';
  } else {
    filters.value.sort_dir = filters.value.sort_dir === 'asc' ? 'desc' : 'asc';
  }

  filters.value.sort_field = field;
  void loadPayments();
}
</script>
