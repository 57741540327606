<template>
  <v-card
    class="mt-4"
    border
    tile
  >
    <v-card-title class="fs-22 fw-600">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          cols="6"
          role="heading"
        >
          {{ $t('Related records') }}
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            @click="handleLinkRecord"
            color="primary"
            prepend-icon="link"
            variant="outlined"
          >
            {{ $t('Link record') }}
          </v-btn>

          <v-btn
            @click="handleNewRecord"
            class="ms-3"
            color="primary"
            prepend-icon="add"
          >
            {{ $t('New record') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-data-table-server
      @update:page="handlePageChange"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="linkedRecords"
      :items-length="totalItemCount"
      :items-per-page="pageSize"
      :loading="processing"
      :no-data-text="$t('No related records found')"
      fixed-header
      hover
    >
      <template #item.id="{ item }">
        <span
          @click="openRecord(item)"
          class="cursor-pointer underlined"
        >
          {{ item.id.substring(0, 8).toUpperCase() }}
        </span>
      </template>

      <template #item.type="{ item }">
        {{ $t(toSentenceCase(item.type)) }}
      </template>

      <template #item.created_at="{ item }">
        <LongDateTime :date="item.created_at" />
      </template>

      <template #item.actions="{ item }">
        <div
          v-if="item.linked_record_id"
          class="d-flex justify-end align-center"
        >
          <ActionMenu
            @click:action:unlink="unlinkRecord(item)"
            :items="[{ title: 'Unlink', avatar: 'link_off', event: 'unlink' }]"
            button-icon="more_vert"
          />
        </div>
      </template>
    </v-data-table-server>

    <LinkExistingRecordDialog
      @change="loadLinkedRecords"
      ref="linkExistingRecordDialog"
      :case-id="props.caseId"
      :case-type="props.caseType"
      :linked-records="linkedRecords"
      :schema="props.schema"
    />

    <LinkNewRecordDialog
      @change="loadLinkedRecords"
      ref="linkNewRecordDialog"
      :case-id="props.caseId"
      :case-type="props.caseType"
      :linked-records="linkedRecords"
      :schema="props.schema"
    />

    <AgreementDialog
      @change="load"
      ref="agreementDialog"
      :api="api"
      role="organization"
    />

    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AgreementDialog from '@/shared/components/AgreementDialog.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import LinkExistingRecordDialog from '@/specialist/components/LinkExistingRecordDialog.vue';
import LinkNewRecordDialog from '@/specialist/components/LinkNewRecordDialog.vue';
import { toSentenceCase } from '@/shared/services/string-utils';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const props = defineProps({
  caseId: {
    type: String,
    required: true,
  },
  caseType: {
    type: String,
    required: true,
  },
  schema: {
    type: Object,
    required: true,
  },
});

const tableHeaders = ref([
  {
    title: 'ID',
    value: 'id',
  },
  {
    title: 'Type',
    value: 'type',
  },
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Created',
    value: 'created_at',
  },
  {
    title: 'Status',
    value: 'status',
  },
  {
    title: '',
    value: 'actions',
    align: 'right',
  },
]);

const confirmDialog = ref(null);
const linkedRecords = ref([]);
const linkExistingRecordDialog = ref(null);
const linkNewRecordDialog = ref(null);
const totalItemCount = ref(null);
const pageSize = ref(10);
const processing = ref(false);
const agreementDialog = ref(null);

async function loadLinkedRecords() {
  processing.value = true;
  const {
    data: { children = [], parents = [] },
  } = await api.organization.linkedRecord.index(props.caseId, props.caseType);
  linkedRecords.value = [...children, ...parents].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
  processing.value = false;
}

function handleNewRecord() {
  linkNewRecordDialog.value.open();
}

function handleLinkRecord() {
  linkExistingRecordDialog.value.open();
}

function openRecord(record) {
  if (record.type === 'Agreement') {
    openAgreement(record.id);
  }
  if (record.type === 'Assessment') {
    resolveAndOpen({ name: 'AssessmentShow', params: { assessmentId: record.id } });
  }
  if (record.type === 'BackgroundCheck') {
    resolveAndOpen({ name: 'BackgroundCheckShow', params: { id: record.id } });
  }
  if (record.type === 'Complaint') {
    resolveAndOpen({ name: 'ComplaintShow', params: { id: record.id } });
  }
  if (record.type === 'License') {
    resolveAndOpen({ name: 'LicenseShow', params: { id: record.id } });
  }
  if (record.type === 'Form') {
    resolveAndOpen({ name: 'FormReview', params: { formId: record.id } });
  }
  if (record.type === 'Member') {
    resolveAndOpen({ name: 'StaffShow', params: { memberId: record.id } });
  }
  if (record.type === 'Provider') {
    resolveAndOpen({ name: 'ProviderShow', params: { providerId: record.id } });
  }
}

async function unlinkRecord(record) {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure that you want to unlink this record?',
  );
  if (!confirm) return;

  const resp = await api.organization.linkedRecord.destroy(record.linked_record_id);
  if (resp.status !== 200) return;

  void loadLinkedRecords();
  eventBus.chime('Record unlinked');
}

function resolveAndOpen(params) {
  const routeData = router.resolve(params);
  window.open(routeData.href, '_blank');
}

function openAgreement(agreementId) {
  agreementDialog.value.open(agreementId);
}

function handlePageChange() {}

onMounted(async () => {
  await loadLinkedRecords();
});
</script>
