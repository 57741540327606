<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <v-row>
        <v-col
          class="d-flex align-center"
          cols="8"
        >
          {{ $t(title) }}
        </v-col>
        <v-col>
          <v-select
            @update:model-value="updateQuery({ subsidyProgramId: $event })"
            :items="subsidyPrograms"
            :model-value="$route.query.subsidyProgramId"
            class="me-3 mxw-350"
            data-testId="subsidy-program-select"
            density="compact"
            item-title="name"
            item-value="id"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-0" />

    <v-card-text>
      <v-row class="mb-2 d-flex align-center">
        <v-col
          cols="12"
          lg="9"
        >
          <ActionMenu
            @click:action:change="changeProgram($event)"
            :button-block="$vuetify.display.mdAndDown"
            :button-title="selectedProgram?.name || 'Select a classroom'"
            :items="programActionItems"
            button-class="me-2 bg-super-light-grey px-3 py-2 rounded"
            button-color="black"
            button-icon-side="right"
            data-testid="program-select"
            button-text
          />

          <ActionMenu
            v-if="selectedProgram"
            @click:action:change="changeOperatingLog($event)"
            :button-block="$vuetify.display.mdAndDown"
            :button-title="selectedMonth || 'Select a month'"
            :items="operatingLogActionItems"
            button-class="bg-super-light-grey px-3 py-2 rounded"
            button-color="black"
            button-icon-side="right"
            button-text
          />
        </v-col>

        <v-col
          v-if="selectedOperatingLog"
          class="d-flex justify-end"
          cols="12"
          lg="3"
        >
          <v-btn
            @click="handleOpenOperatingLog"
            :block="$vuetify.display.mdAndDown"
            :loading="processing"
            :variant="!!selectedOperatingLog.submitted_at ? 'outlined' : 'flat'"
            class="me-3"
            color="primary"
          >
            <template v-if="selectedOperatingLog.submitted_at">
              <v-icon
                class="me-2"
                icon="check"
              />
              {{ $t('Submitted') }}
            </template>
            <template v-else>
              {{ $t('Submit') }}
            </template>
          </v-btn>

          <ActionMenu
            @click:action:unsubmit="unsubmitAttendance"
            :items="operatingLogActions"
            button-icon="more_vert"
            label="Download Data"
          />
        </v-col>
      </v-row>

      <AttendanceLogEditor
        v-if="selectedOperatingLog"
        @delete:attendance-log="deleteAttendanceLog($event)"
        @queue:attendance-log-change="queueAttendanceLogChange($event)"
        @queue:attendance-log-question="queueAttendanceLogQuestion($event)"
        @queue:operating-log-change="queueOperatingLogChange($event)"
        :attendance-log-questions="attendanceLogQuestions"
        :attendance-log-schema="attendanceLogSchema"
        :attendance-logs="attendanceLogs"
        :attendance-totals="attendanceTotals"
        :daily-totals="dailyTotals"
        :errors="errors"
        :operating-log="selectedOperatingLog"
        :operating-log-schema="operatingLogSchema"
        :queued-attendance-log-answers="queuedAttendanceLogAnswers"
        :queued-attendance-log-changes="queuedAttendanceLogChanges"
        :queued-attendance-log-question-id="queuedAttendanceLogQuestionId"
        :queued-operating-log-changes="queuedOperatingLogChanges"
        :submit-revision="selectedOperatingLog?.submission_revision"
      />

      <NullState v-else />
    </v-card-text>

    <ResourceDialog
      @close="clearAttendanceChangeQueue"
      ref="attendanceLogStatusDialog"
      :processing="processing"
      :title="`Set ${terms.attendance} status`"
      closeable
      hide-actions
    >
      <template #form>
        <div class="ta-center">
          <v-btn
            v-for="status in $store.state.config.attendance_statuses"
            @click="updateAttendanceLogsStatus(status)"
            :key="status.code"
            :color="status.color"
            :loading="processing"
            class="mb-2 me-2 px-4"
            size="x-large"
            variant="flat"
            block
            tile
          >
            <span>{{ status.code }} - {{ status.title }}</span>
          </v-btn>
        </div>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="updateAttendanceLogsHours"
      ref="attendanceLogHoursDialog"
      :fields="ATTENDANCE_LOG_HOURS_FIELDS"
      :processing="processing"
      title="Set hours attended"
      closeable
    />

    <ResourceDialog
      @close="clearOperatingLogChangeQueue"
      ref="operatingLogStatusDialog"
      :max-width="600"
      :processing="processing"
      title="Set operating status"
      closeable
      hide-actions
    >
      <template #form>
        <div class="ta-center">
          <v-btn
            v-for="status in $store.state.config.operating_statuses"
            @click="updateOperatingLog(status)"
            :key="status.code"
            :color="status.color"
            :loading="processing"
            class="mb-2 me-2 px-4"
            size="x-large"
            variant="flat"
            block
            label
            tile
          >
            {{ status.code }} - {{ status.title }}
          </v-btn>
        </div>
      </template>
    </ResourceDialog>

    <AttendanceLogQuestionDialog
      @close="clearAttendanceLogQuestionQueue"
      @save="updateAttendanceLogsAnswers"
      ref="attendanceLogQuestionDialog"
      :processing="processing"
      :schema-definition="attendanceLogSchema?.definition"
    />

    <OperatingLogEditor @change="selectedOperatingLog = $event" />

    <ChangesQueuedAlert
      @clear:attendance-log-changes="clearAttendanceChangeQueue"
      @clear:attendance-log-question="clearAttendanceLogQuestionQueue"
      @clear:attendance-log-status="clearAttendanceLog"
      @clear:operating-log-changes="clearOperatingLogChangeQueue"
      @clear:operating-log-status="clearOperatingLog"
      @edit:attendance-log-question="openAttendanceLogQuestionDialog"
      @edit:attendance-log-status="handleEditAttendanceLogStatus"
      @edit:operating-log="$refs.operatingLogStatusDialog.open()"
      :attendance-log-schema="attendanceLogSchema"
      :queued-attendance-log-answers="queuedAttendanceLogAnswers"
      :queued-attendance-log-changes="queuedAttendanceLogChanges"
      :queued-operating-log-changes="queuedOperatingLogChanges"
      :show-attendance-log-change-queue="showAttendanceLogChangeQueue"
      :show-attendance-log-question-queue="showAttendanceLogQuestionQueue"
      :show-operating-log-change-queue="showOperatingLogChangeQueue"
    />
  </v-card>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AttendanceLogEditor from '@/shared/components/attendance-logs/AttendanceLogEditor.vue';
import AttendanceLogQuestionDialog from '@/shared/components/attendance-logs/AttendanceLogQuestionDialog.vue';
import ChangesQueuedAlert from '@/shared/components/ChangesQueuedAlert.vue';
import NullState from '@/shared/components/NullState.vue';
import OperatingLogEditor from '@/shared/components/OperatingLogEditor.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { validateAttendance } from '@/shared/services/attendance-validation';
import useAssets from '@/shared/composables/useAssets';
import useAttendance from '@/shared/composables/useAttendance';
import useEventBus from '@/shared/composables/useEventBus';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRoute } from 'vue-router';
import useTerms from '@/shared/composables/useTerms';
import useSubsidyPrograms from '@/specialist/composables/useSubsidyPrograms';

defineProps({
  title: {
    type: String,
    default: undefined,
  },
});

const eventBus = useEventBus();
const route = useRoute();
const { updateQuery } = useRouterHelper();
const { terms } = useTerms();
const { assets } = useAssets();

function scopedApi() {
  return api.organization;
}

const {
  ATTENDANCE_LOG_HOURS_FIELDS,
  attendanceLogHoursDialog,
  attendanceLogStatusDialog,
  attendanceLogQuestionDialog,
  operatingLogStatusDialog,
  attendanceLogs,
  attendanceLogSchema,
  attendanceLogQuestions,
  attendanceTotals,
  errors,
  handleEditAttendanceLogStatus,
  processing,
  operatingLogs,
  operatingLogSchema,
  queuedAttendanceLogQuestionId,
  queuedAttendanceLogAnswers,
  queuedOperatingLogChanges,
  providerId,
  queuedAttendanceLogChanges,
  selectedOperatingLog,
  selectedProgram,
  showAttendanceLogChangeQueue,
  showAttendanceLogQuestionQueue,
  showOperatingLogChangeQueue,
  dailyTotals,
  selectedMonth,
  changeOperatingLog,
  changeProgram,
  clearAttendanceLog,
  clearOperatingLog,
  clearAttendanceChangeQueue,
  clearOperatingLogChangeQueue,
  clearAttendanceLogQuestionQueue,
  loadAttendanceLogs,
  loadAttendanceLogQuestions,
  loadPrograms,
  openAttendanceLogQuestionDialog,
  queueAttendanceLogChange,
  queueAttendanceLogQuestion,
  queueOperatingLogChange,
  updateOperatingLog,
  updateAttendanceLogsAnswers,
  updateAttendanceLogsStatus,
  updateAttendanceLogsHours,
} = useAttendance(scopedApi);
const { programs, subsidyPrograms } = useSubsidyPrograms();

const attendanceLink = computed(() => {
  if (!selectedOperatingLog.value) return null;

  const programName = programs.value.find(
    (program) => program.id === selectedOperatingLog.value.program_id,
  ).name;
  const filename = `${programName} ${terms.value.attendance} for ${selectedOperatingLog.value.year}-${selectedOperatingLog.value.month}`;
  return scopedApi().attendance_log.downloadUrl(filename, selectedOperatingLog.value.id);
});

const operatingLogLink = computed(() => {
  if (!selectedOperatingLog.value) return null;

  const programName = programs.value.find(
    (program) => program.id === selectedOperatingLog.value.program_id,
  ).name;
  const filename = `${programName} ${terms.value.attendance} for ${selectedOperatingLog.value.year}-${selectedOperatingLog.value.month}`;
  return scopedApi().operating_log.downloadUrl(selectedOperatingLog.value.id, filename);
});

const operatingLogActions = computed(() => {
  if (selectedOperatingLog.value) {
    const perennialLinks = [
      { href: attendanceLink.value, title: `Download ${terms.value.attendance}` },
      { href: operatingLogLink.value, title: 'Download Form' },
    ];

    if (selectedOperatingLog.value.submitted_at) {
      perennialLinks.push({ event: 'unsubmit', title: 'Unsubmit' });
    }

    return perennialLinks;
  }

  return [];
});

const operatingLogActionItems = computed(() => {
  return operatingLogs.value.map((ol) => ({
    event: 'change',
    operatingLogId: ol.id,
    title: [assets.months[ol.month], ol.year].join(' '),
  }));
});

const programActionItems = computed(() => {
  return programs.value.map((program) => ({
    event: 'change',
    title: program.name,
    programId: program.id,
  }));
});

watch(
  () => route.params.providerId,
  async (newVal) => {
    providerId.value = newVal;
    selectedProgram.value = null;
    await loadPrograms();
    loadAttendanceLogQuestions();
  },
  { immediate: true },
);

watch(
  () => route.query.tab,
  async (newVal) => {
    if (newVal && newVal === 'attendance') {
      await loadPrograms();
    }
  },
);

watch(
  () => route.query.subsidyProgramId,
  (newVal) => {
    if (newVal) {
      selectedProgram.value = null;
      selectedOperatingLog.value = null;
    }
  },
);

onMounted(async () => {
  await loadPrograms();
  await loadAttendanceLogQuestions();
});

const deleteAttendanceLog = (log) => {
  if (
    confirm(
      `Are you sure you want to remove the ${terms.value.attendance} record from this classroom for ${log.name} for this month?`,
    )
  ) {
    scopedApi().attendance_log.destroy(log.id, () => loadAttendanceLogs());
  }
};

const handleOpenOperatingLog = async () => {
  errors.value = validateAttendance(
    attendanceLogs.value,
    attendanceLogQuestions.value,
    !operatingLogSchema.value.meta.disable_daily_attendance,
  );

  if (errors.value.length > 0) {
    return eventBus.error(`${terms.value.attendance} log is not complete.`);
  }

  return await updateQuery({ action: 'editOperatingLog' });
};

const unsubmitAttendance = async () => {
  await scopedApi().operating_log.update(selectedOperatingLog.value.id, {
    submitted: false,
  });
  const resp = await scopedApi().operating_log.get(selectedOperatingLog.value.id);
  if (resp.status != 200) return;

  eventBus.chime('Unsubmitted');
  selectedOperatingLog.value = resp.data;
};
</script>
