<template>
  <div>
    <ResourceDialog
      ref="seatsEditorDialog"
      :cancellable="false"
      :max-width="800"
      :processing="processing"
      save-button-text="Close"
      title="Edit seats"
      close-on-save
      closeable
      fullscreen
    >
      <template #form>
        <div
          v-for="seatType in seatTypes"
          :key="seatType.id"
          class="mb-8"
        >
          <v-row class="d-flex align-center">
            <v-col>
              <span class="fs-18 fw-600">{{ seatType.name }}</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <span class="fs-18 fw-600">
                {{ seats.filter((seat) => seat.seat_type_id == seatType.id).length }} total
              </span>
            </v-col>
          </v-row>

          <v-divider class="my-3" />

          <v-card
            v-for="seat in seats.filter((seat) => seat.seat_type_id == seatType.id)"
            :key="seat.id"
            border
            flat
            tile
          >
            <v-card-text class="py-2">
              <v-row dense>
                <v-col>
                  <div class="fs-14">
                    {{ seatTypes.find((st) => st.id == seat.seat_type_id).name }}
                  </div>
                  <div class="fs-12 c-light-black">
                    {{ `#${seat.id}` }}
                  </div>
                </v-col>

                <v-col class="d-flex justify-end">
                  <template v-if="seat.enrollment_id">
                    <v-btn
                      variant="text"
                      disabled
                      icon
                    >
                      <v-icon icon="radio_button_checked" />
                    </v-btn>
                  </template>

                  <template v-else>
                    <v-btn
                      @click="destroySeat(seat)"
                      :aria-label="$t('Remove seat')"
                      variant="text"
                      icon
                    >
                      <v-icon icon="close" />
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <template v-if="seats.filter((seat) => seat.seat_type_id == seatType.id).length == 0">
            <p class="fs-16">
              {{ $t('There are no assigned seats of this type.') }}
            </p>
          </template>
        </div>

        <v-row class="mt-4">
          <v-col>
            <v-btn
              @click="addSeat"
              color="primary"
            >
              {{ $t('Add seat') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="createSeat"
      ref="seatTypeSelectorDialog"
      :fields="[
        {
          text: 'Seat type',
          value: 'seat_type_id',
          items: seatTypes,
          itemText: 'name',
          itemValue: 'id',
        },
        {
          text: 'Number of seats to add',
          value: 'quantity',
        },
      ]"
      title="Select type"
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

defineExpose({ open });

const props = defineProps({
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['change']);

const eventBus = useEventBus();

const confirmDialog = ref(null);
const processing = ref(false);
const program = ref(null);
const seats = ref([]);
const seatsEditorDialog = ref(null);
const seatTypes = ref([]);
const seatTypeSelectorDialog = ref(null);

function addSeat() {
  if (seatTypes.value.length === 1) {
    void createSeat({ seat_type_id: seatTypes.value[0].id });
  } else {
    seatTypeSelectorDialog.value?.open();
  }
}

async function createSeat(args) {
  const params = {
    subsidy_program_id: props.subsidyProgram.id,
    seat_type_id: args.seat_type_id,
    program_id: program.value.id,
    quantity: args.quantity,
  };
  processing.value = true;
  await Api.organization.seat.create(params);
  await loadSeats();
  seatTypeSelectorDialog.value?.close();
  processing.value = false;
  emit('change');
}

async function destroySeat(seat) {
  if (!(await confirmDialog.value.confirmWithText('Are you sure you want to delete this seat?')))
    return;

  processing.value = true;
  await Api.organization.seat.destroy(seat.id).catch((error) => eventBus.error(error));
  await loadSeats();
  processing.value = false;
  emit('change');
}

async function loadSeats() {
  const resp = await Api.organization.seat.index(props.subsidyProgram.id, [program.value.id]);
  seats.value = _.orderBy(resp.data, [(seat) => seat.enrollment_id], ['asc']);
}

async function loadSeatTypes() {
  const resp = await Api.organization.subsidy_program.seat_type.index(props.subsidyProgram.id);
  seatTypes.value = resp.data;
}

async function open(programContext) {
  seats.value = [];
  seatTypes.value = [];

  if (!props.subsidyProgram) {
    eventBus.chime('You must select a valid program');
    return;
  }

  processing.value = true;
  program.value = programContext;
  seatsEditorDialog.value?.open();

  await loadSeatTypes();
  await loadSeats();

  processing.value = false;
}
</script>
