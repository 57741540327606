<template>
  <v-text-field
    v-model="formatted_location"
    @click="formatted_location = ''"
    ref="location_autocomplete"
    :aria-label="location_text || $t('Enter a location')"
    :filled="filled"
    :outlined="outlined"
    :placeholder="location_text || $t('Enter a location')"
    :tile="tile"
    class="dark-placeholder mt-0 ta-left"
    color="secondary"
    prepend-inner-icon="location_on"
    hide-details
  />
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    filled: Boolean,
    outlined: {
      default: true,
      type: Boolean,
    },
    startingLocation: {
      type: Object,
      default: null,
    },
    tile: Boolean,
  },

  emits: ['change-location'],

  data() {
    return {
      formatted_location: this.startingLocation ? this.startingLocation.formatted_location : null,
      location: this.startingLocation,
      locationAutocomplete: {},
    };
  },

  computed: {
    location_text() {
      if (!this.location || !this.location.address) {
        return null;
      }
      if (this.location.address === this.location.city) {
        return [this.location.address, this.location.state].join(', ');
      }
      return [this.location.address, this.location.city, this.location.state].join(', ');
    },
  },

  mounted() {
    // eslint-disable-next-line no-undef
    this.locationAutocomplete = new google.maps.places.Autocomplete(
      this.$refs.location_autocomplete.$el.querySelector('input'),
      { types: ['geocode'] },
    );
    this.locationAutocomplete.addListener('place_changed', this.setLocation);
  },

  methods: {
    setLocation() {
      const place = this.locationAutocomplete.getPlace();
      this.formatted_location = place.formatted_address;
      const parts = place.formatted_address.split(',');
      const state_zip = parts[parts.length - 2].split(' ');
      this.location = {
        formatted_location: this.formatted_location,
        address: parts[0],
        city: parts[parts.length - 3],
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: state_zip[1],
        zip: state_zip[2],
      };
      this.$emit('change-location', this.location);
    },
  },
};
</script>
