<template>
  <ResourceDialog
    @save="$emit('save', $event)"
    ref="dialog"
    :processing="processing"
    max-width="600"
    title="Edit assignment"
  >
    <template #form="{ localValue }">
      <v-card
        border
        flat
        tile
      >
        <v-data-table
          @update:model-value="localValue.value = $event"
          :headers="headers"
          :items="reviewers"
          item-key="id"
          items-per-page="15"
          select-strategy="single"
          disable-sort
          show-select
        />
      </v-card>
    </template>
  </ResourceDialog>
</template>

<script setup>
import useApi from '@/shared/composables/useApi';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

const { api } = useApi();

defineProps({
  processing: Boolean,
});

defineEmits(['save']);

const dialog = ref(null);
const reviewers = ref([]);

const open = async ({ schemaId }) => {
  reviewers.value = [];
  reviewers.value = (await api.organization.reviewer.index({ schemaId })).data;
  dialog.value.open({
    value: null,
  });
};

const close = () => {
  dialog.value.close();
};

const headers = [
  { title: 'Name', key: 'name' },
  { title: '# of cases', key: 'assignments' },
];

defineExpose({ open, close });
</script>
