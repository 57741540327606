<template>
  <div>
    <v-card
      id="contact"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('Parent/guardian contact information') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-row
          class="mb-2 pt-2"
          dense
        >
          <v-col
            class="text-h6"
            cols="12"
          >
            {{ $t('Primary parent/guardian') }}
          </v-col>
        </v-row>
        <v-row class="fs-16 mt-2 mb-2">
          <LabeledTextfield
            v-model="groupBeingEdited.primary_first_name"
            @input="changed = true"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            cols="6"
            data-cy="primary_first_name"
            field="primary_first_name"
          />
          <LabeledTextfield
            v-model="groupBeingEdited.primary_last_name"
            @input="changed = true"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            cols="6"
            data-cy="primary_last_name"
            field="primary_last_name"
          />
          <LabeledTextfield
            v-model="groupBeingEdited.primary_phone"
            @input="changed = true"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            cols="6"
            data-cy="primary_phone"
            field="primary_phone"
          />
          <LabeledTextfield
            v-model="groupBeingEdited.primary_email"
            @input="changed = true"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            cols="6"
            data-cy="primary_email"
            field="primary_email"
          />
          <LabeledDateOfBirth
            v-model="groupBeingEdited.primary_dob"
            @change="changed = true"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            cols="12"
            data-cy="primary_dob"
            field="primary_dob"
            message="Date of birth"
          />
          <LabeledTextfield
            v-model="groupBeingEdited.primary_occupation"
            @input="changed = true"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            cols="12"
            data-cy="primary_occupation"
            field="primary_occupation"
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              v-model="addSecondaryParent"
              :disabled="!canEdit"
              class="mt-0 pt-0 mb-2"
              color="secondary"
              density="compact"
              label="Add secondary parent/guardian"
              hide-details
            />
          </v-col>
        </v-row>
        <template v-if="addSecondaryParent">
          <v-row
            class="mb-3 pt-5"
            dense
          >
            <v-col
              class="text-h6 mb-2"
              cols="12"
            >
              {{ $t('Secondary parent/guardian') }}
            </v-col>
          </v-row>
          <div class="fs-16 mt-4">
            <v-row class="mb-2">
              <LabeledTextfield
                v-model="groupBeingEdited.secondary_first_name"
                @input="changed = true"
                :readonly="!canEdit"
                :schema-id="groupBeingEdited.schema_id"
                cols="6"
                data-cy="secondary_first_name"
                field="secondary_first_name"
              />
              <LabeledTextfield
                v-model="groupBeingEdited.secondary_last_name"
                @input="changed = true"
                :readonly="!canEdit"
                :schema-id="groupBeingEdited.schema_id"
                cols="6"
                data-cy="secondary_last_name"
                field="secondary_last_name"
              />
              <LabeledTextfield
                v-model="groupBeingEdited.secondary_phone"
                @input="changed = true"
                :readonly="!canEdit"
                :schema-id="groupBeingEdited.schema_id"
                cols="6"
                data-cy="secondary_phone"
                field="secondary_phone"
              />
              <LabeledTextfield
                v-model="groupBeingEdited.secondary_email"
                @input="changed = true"
                :readonly="!canEdit"
                :schema-id="groupBeingEdited.schema_id"
                cols="6"
                data-cy="secondary_email"
                field="secondary_email"
              />
              <LabeledDateOfBirth
                v-model="groupBeingEdited.secondary_dob"
                @change="changed = true"
                :readonly="!canEdit"
                :schema-id="groupBeingEdited.schema_id"
                cols="12"
                data-cy="secondary_dob"
                field="secondary_dob"
                message="Date of birth"
              />
              <LabeledTextfield
                v-model="groupBeingEdited.secondary_occupation"
                @input="changed = true"
                :readonly="!canEdit"
                :schema-id="groupBeingEdited.schema_id"
                cols="12"
                data-cy="secondary_occupation"
                field="secondary_occupation"
              />
            </v-row>
          </div>
        </template>

        <v-row
          v-for="(field, index) in schemaData.contact.append"
          :key="index"
        >
          <CustomField
            v-model="groupBeingEdited"
            @change="changed = true"
            @input="changed = true"
            :field="field"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            :show-title="true"
            class="fs-16"
            mode="edit"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      id="addresses"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('Addresses') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="fs-16">
          <LabeledTextfield
            v-model="groupBeingEdited.formatted_home_address"
            @input="changed = true"
            ref="homeAutocompleteRef"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            field="formatted_home_address"
            clearable
          />
          <LabeledTextfield
            v-model="groupBeingEdited.formatted_work_address"
            @input="changed = true"
            ref="workAutocompleteRef"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            field="formatted_work_address"
            clearable
          />
          <LabeledTextfield
            v-model="groupBeingEdited.formatted_other_address"
            @input="changed = true"
            ref="otherAutocompleteRef"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            field="formatted_other_address"
            clearable
          />
          <LabeledSimpleSelect
            v-model="groupBeingEdited.travel_mode"
            @change="changed = true"
            :items="travelModes"
            :readonly="!canEdit"
          />
        </v-row>

        <v-row
          v-for="(field, index) in schemaData.addresses.append"
          :key="index"
        >
          <CustomField
            @change="changed = true"
            @input="changed = true"
            :field="field"
            :model-value="groupBeingEdited"
            :readonly="!canEdit"
            :schema-id="groupBeingEdited.schema_id"
            :show-title="true"
            class="fs-16"
            mode="edit"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      id="hours"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('Hours of care') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-row dense>
          <v-col
            class="mb-4"
            cols="4"
          >
            <div class="c-black fs-16">
              {{ `What days does the ${terms.group.toLowerCase()} need care?` }}
            </div>
          </v-col>
          <v-col cols="8">
            <template
              v-for="day in days"
              :key="day.value"
            >
              <v-btn
                @click="toggleDay(day.value)"
                :color="hours[day.value] ? 'primary' : 'dark-grey'"
                :readonly="!canEdit"
                :variant="hours[day.value] ? 'tonal' : 'outlined'"
                class="elevation-0 ms-0 me-2 mb-4 px-2"
              >
                {{ $t(day.name) }}
              </v-btn>
            </template>
          </v-col>
        </v-row>

        <v-row
          v-if="groupBeingEdited.hours.length > 0"
          class="ma-0 pa-0"
          dense
        >
          <v-col
            class="mxw-175"
            cols="2"
          />
          <v-col
            class="ta-left fw-600"
            cols="4"
          >
            <div class="fs-16">
              {{ $t('Drop-off') }}
            </div>
          </v-col>
          <v-col
            class="ta-right ma-0 pa-0"
            cols="4"
          >
            <div class="fs-16 fw-600">
              {{ $t('Pick-up') }}
            </div>
          </v-col>
          <v-col cols="2" />
        </v-row>

        <v-divider />

        <v-hover
          v-for="(day, index) in groupBeingEdited.hours"
          :key="day.day"
          v-slot="{ isHovering, hoverProps }"
        >
          <div v-bind="hoverProps">
            <v-row class="py-3">
              <v-col
                class="align-center d-flex mxw-175"
                cols="2"
              >
                <div class="c-black fs-16">
                  {{ $t($a.assets.weekdays[day.day]) }}
                </div>
              </v-col>
              <v-col cols="8">
                <v-row>
                  <LabeledOpenClose
                    @change="updateDay(day.day, $event)"
                    :model-value="[day.open, day.close]"
                    :readonly="!canEdit"
                  />
                </v-row>
              </v-col>
              <v-col
                class="d-flex justify-center mb-2"
                cols="2"
              >
                <v-btn
                  v-show="isHovering"
                  @click="applyToAll(index)"
                  color="secondary"
                  size="x-small"
                  variant="text"
                  rounded
                >
                  <span>{{ $t('Apply to all') }}</span>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider class="mb-2" />
          </div>
        </v-hover>

        <div class="mt-4">
          <v-row
            v-for="(field, index) in schemaData.hours.append"
            :key="index"
          >
            <CustomField
              @change="changed = true"
              @input="changed = true"
              :field="field"
              :model-value="groupBeingEdited"
              :readonly="!canEdit"
              :schema-id="groupBeingEdited.schema_id"
              :show-title="true"
              class="fs-16"
              mode="edit"
            />
          </v-row>
        </div>

        <SaveBar
          @cancel="cancel"
          @save="save"
          :model-value="changed"
          :processing="processing"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import CustomField from '@/shared/components/CustomField.vue';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import useTerms from '@/shared/composables/useTerms';

const addressTypes = { home: 'home', work: 'work', other: 'other' };
const days = [
  { name: 'Monday', value: 0 },
  { name: 'Tuesday', value: 1 },
  { name: 'Wednesday', value: 2 },
  { name: 'Thursday', value: 3 },
  { name: 'Friday', value: 4 },
  { name: 'Saturday', value: 5 },
  { name: 'Sunday', value: 6 },
];
const travelModes = ['BICYCLE', 'DRIVING', 'TRANSIT', 'WALKING'];
const eventBus = useEventBus();
const store = useStore();
const { terms } = useTerms();

const props = defineProps({
  group: {
    type: Object,
    default: null,
  },
});

const addSecondaryParent = ref(false);
const adultYears = ref([]);
const changed = ref(false);
const processing = ref(false);
const hours = ref({
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
});
const homeAutocomplete = ref({});
const homeAutocompleteRef = ref(null);
const otherAutocomplete = ref({});
const otherAutocompleteRef = ref(null);
const workAutocomplete = ref({});
const workAutocompleteRef = ref(null);
const groupBeingEdited = ref(props.group);
const schemaData = ref({ ...store.state.schemas[props.group.schema_id].meta });

const canEdit = computed(() => store.state.profile.org_groups_edit);

watch(addSecondaryParent, (newVal) => {
  if (!newVal) {
    groupBeingEdited.value.secondary_first_name = null;
    groupBeingEdited.value.secondary_last_name = null;
    groupBeingEdited.value.secondary_phone = null;
    groupBeingEdited.value.secondary_email = null;
    groupBeingEdited.value.secondary_dob = null;
    groupBeingEdited.value.secondary_occupation = null;
  }
});

onMounted(() => {
  addSecondaryParent.value = groupBeingEdited.value.secondary_first_name != null;
  for (let i = 0; i < groupBeingEdited.value.hours.length; i += 1) {
    hours.value[groupBeingEdited.value.hours[i].day.toString()] = true;
  }
  for (let i = 0; i <= 85; i += 1) {
    adultYears.value.push({ text: (2020 - i).toString(), value: (2020 - i).toString() });
  }

  if (google) {
    homeAutocomplete.value = new google.maps.places.Autocomplete(
      homeAutocompleteRef.value.$el.querySelector('input'),
      { types: [] },
    );
    homeAutocomplete.value.addListener('place_changed', () => {
      setLocation(addressTypes.home);
    });

    workAutocomplete.value = new google.maps.places.Autocomplete(
      workAutocompleteRef.value.$el.querySelector('input'),
      { types: [] },
    );
    workAutocomplete.value.addListener('place_changed', () => {
      setLocation(addressTypes.work);
    });

    otherAutocomplete.value = new google.maps.places.Autocomplete(
      otherAutocompleteRef.value.$el.querySelector('input'),
      { types: [] },
    );
    otherAutocomplete.value.addListener('place_changed', () => {
      setLocation(addressTypes.other);
    });
  }
});

function applyToAll(index) {
  const primaryDay = groupBeingEdited.value.hours[index];
  groupBeingEdited.value.hours.forEach((day) => {
    day.open = primaryDay.open;
    day.close = primaryDay.close;
  });
  changed.value = true;
}

function cancel() {
  changed.value = false;
  window.location.reload();
}

function save() {
  processing.value = true;
  groupBeingEdited.value.name = [
    props.group.primary_first_name,
    props.group.primary_last_name,
  ].join(' ');
  api.organization.group.update(
    groupBeingEdited.value.id,
    groupBeingEdited.value,
    (resp) => {
      changed.value = false;
      groupBeingEdited.value = resp.data;
      processing.value = false;
    },
    (err) => {
      processing.value = false;
      eventBus.error(err);
    },
  );
}

function getAutocompletePlace(field) {
  if (field === addressTypes.home) return homeAutocomplete.value.getPlace();
  if (field === addressTypes.work) return workAutocomplete.value.getPlace();
  else return otherAutocomplete.value.getPlace();
}

function setLocation(addressType) {
  const place = getAutocompletePlace(addressType);
  if (place && place.formatted_address) {
    const parts = place.formatted_address.split(',');
    const state_zip = parts[parts.length - 2].split(' ');
    groupBeingEdited.value[`formatted_${addressType}_address`] = place.formatted_address;
    groupBeingEdited.value[`${addressType}_address`] = parts[0];
    groupBeingEdited.value[`${addressType}_city`] = parts[parts.length - 3].trim();
    groupBeingEdited.value[`${addressType}_latitude`] = place.geometry.location.lat();
    groupBeingEdited.value[`${addressType}_longitude`] = place.geometry.location.lng();
    groupBeingEdited.value[`${addressType}_state`] = state_zip[1].trim();
    groupBeingEdited.value[`${addressType}_zip`] = state_zip[2];
    changed.value = true;
  }
}

function toggleDay(day) {
  hours.value[day] = !hours.value[day];
  if (hours.value[day]) {
    if (!groupBeingEdited.value.hours.find((hour) => hour.day === parseInt(day, 10))) {
      const newDays = groupBeingEdited.value.hours;
      newDays.push({ day: parseInt(day, 10), open: 800, close: 1800 });
      newDays.sort((a, b) => a.day - b.day);
      groupBeingEdited.value.hours = newDays;
    }
  } else {
    const newDays = groupBeingEdited.value.hours.filter((hour) => hour.day !== parseInt(day, 10));
    newDays.sort((a, b) => a.day - b.day);
    groupBeingEdited.value.hours = newDays;
  }
  changed.value = true;
}

function updateDay(day, timeRange) {
  groupBeingEdited.value.hours[day].open = timeRange[0];
  groupBeingEdited.value.hours[day].close = timeRange[1];
  changed.value = true;
}
</script>
