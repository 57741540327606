<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-row>
        <v-col
          class="d-flex align-center"
          cols="4"
        >
          {{ $t(title) }}
        </v-col>
        <v-col>
          <div class="d-flex justify-end">
            <v-select
              @update:model-value="updateQuery({ subsidyProgramId: $event })"
              :items="subsidyPrograms"
              :model-value="$route.query.subsidyProgramId"
              class="me-3 mxw-350"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
            />
            <v-text-field
              v-model="query"
              :placeholder="$t('Search')"
              class="mxw-250"
              density="compact"
              prepend-inner-icon="search"
              variant="solo-filled"
              flat
              hide-details
              rounded
            />
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-4" />

    <v-card-text v-if="enrollments">
      <v-row
        v-if="dashboard"
        class="d-flex justify-space-between fs-18 mb-1"
      >
        <v-col
          v-for="group in dashboard.enrollment_status_groups"
          :key="group.status"
        >
          <v-row dense>
            <v-col class="d-flex justify-center">
              {{ $t(getStatusText(group.status)) }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="c-primary d-flex justify-center fs-20 pointer underlined">
              <template v-if="group.status == 'Enrolled'">
                <span
                  @click="updateQuery({ tab: 'students', status: null })"
                  class="px-3"
                >
                  {{ group.count || 0 }}
                </span>
              </template>
              <template v-else>
                <span
                  @click="updateQuery({ tab: 'interested', status: group.status })"
                  class="px-3"
                >
                  {{ group.count || 0 }}
                </span>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <!-- TODO: The below does not render due to index being outside of the v-for scope : no quick fix found -->
        <!-- <v-divider :key="index" vertical v-if="index < dashboard.enrollment_status_groups.length - 1"></v-divider> -->
      </v-row>

      <v-row class="d-flex align-center mb-2">
        <v-col
          class="d-flex align-center"
          cols="12"
          md="6"
        >
          <template v-if="pages > 1">
            <v-pagination
              v-model="page"
              :length="pages"
              :total-visible="8"
              class="d-inline-block"
            />
          </template>
        </v-col>

        <v-col
          class="d-flex justify-end px-6"
          cols="12"
          md="6"
        >
          <FilterMenu
            :actions="false"
            :active="!!$route.query.status"
            :title="$route.query.status || 'Status'"
            color="white"
            left
          >
            <template #card>
              <v-radio-group
                @update:model-value="updateQuery({ status: $event })"
                :model-value="$route.query.status"
                class="mt-0"
                hide-details
              >
                <v-radio
                  v-for="status in allStatuses"
                  :key="status.value"
                  :label="$t(status.text)"
                  :value="status.value"
                  class="c-black mt-0"
                  color="primary"
                  hide-details
                />
              </v-radio-group>
            </template>
          </FilterMenu>
        </v-col>
      </v-row>

      <v-card
        border
        flat
        tile
      >
        <template v-if="$vuetify.display.smAndUp">
          <v-card
            class="bb-1 pa-0"
            flat
            tile
          >
            <v-card-text>
              <v-row>
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :md="waitlist ? '2' : '4'"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="name"
                  title="Child's name"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="dob"
                  md="2"
                  title="DOB"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="address"
                  md="2"
                  title="Home address"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="status"
                  md="2"
                  title="Status"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="status_updated_at"
                  md="2"
                  title="Status Updated"
                />
                <template v-if="waitlist">
                  <SortableTableHeader
                    @toggle="toggleSort($event)"
                    :sort-dir="filters.sort_dir"
                    :sort-field="filters.sort_field"
                    field="waitlist"
                    md="2"
                    title="Waitlist #"
                  />
                </template>
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider />
        </template>

        <v-card
          flat
          tile
        >
          <v-card-text class="fs-15">
            <v-row dense>
              <v-col class="d-flex align-center">
                <template v-if="filterSummary">
                  <v-icon
                    icon="filter_alt"
                    start
                  />
                  <span class="me-1"> {{ $t('Filtering by') }}:</span>
                  <span class="fw-600">{{ $t(filterSummary) }}</span>
                  <span>.&nbsp;</span>
                </template>
                <span>
                  {{ $t('Displaying first') }} <strong>{{ enrollments.length }}</strong>
                  {{ $t('results') }}, {{ $t('out of ') }} <strong>{{ total }}</strong>
                  {{ $t('total results') }}.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider />

        <NullState
          v-if="enrollments.length == 0"
          class="mt-8"
        />

        <div
          v-for="(er, index) in enrollments"
          :key="er.id"
        >
          <EnrollmentRequestRow
            :er="er"
            :waitlist="waitlist"
            hide-selector
          />
          <v-divider v-if="index < enrollments.length - 1" />
        </div>

        <template v-if="pages > 1">
          <v-divider />
          <v-pagination
            v-model="page"
            :length="pages"
            :total-visible="8"
            class="my-2"
          />
        </template>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { scrollTo } from 'vuetify/lib/composables/goto';
import Api from '@/specialist/services/bright_finder';
import EnrollmentRequestRow from '@/specialist/components/enrollments/EnrollmentRequestRow.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRoute } from 'vue-router';
import useSubsidyPrograms from '@/specialist/composables/useSubsidyPrograms';

const { allStatuses, pendingStatuses, getStatusText } = useEnrollmentStatuses();
const { updateQuery } = useRouterHelper();
const { dashboard, subsidyPrograms, subsidyProgramFilter } = useSubsidyPrograms();

const route = useRoute();

defineProps({
  title: {
    type: String,
    default: null,
  },
  waitlist: {
    default: true,
    type: Boolean,
  },
});

const enrollments = ref(null);
const filters = ref({
  provider_id: route.query.providerId,
  sort_dir: 'asc',
  sort_field: 'waitlist',
});
const page = ref(1);
const pages = ref(null);
const processing = ref(false);
const total = ref(null);
const query = ref(null);
const queryCount = ref(0);

const filterSummary = computed(() => {
  const appliedFilters = [];
  if (route.query.status) appliedFilters.push(route.query.status);
  if (subsidyProgramFilter.value) appliedFilters.push(subsidyProgramFilter.value);
  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
});

watch(
  () => page.value,
  () => {
    loadEnrollments();
    scrollTo(0);
  },
  { immediate: true },
);

watchEffect(() => {
  if (query.value || route.query.status || route.query.subsidyProgramId) {
    page.value = 1;
    loadEnrollments();
  }
});

function loadEnrollments() {
  processing.value = true;
  filters.value.provider_id = route.params.providerId;
  filters.value.page = page.value;
  filters.value.query = query.value;
  filters.value.status = route.query.status;
  filters.value.subsidy_program_id = route.query.subsidyProgramId;

  queryCount.value += 1;
  const currentQueryCount = queryCount.value;
  const newFilters = { ...filters.value };
  if (!newFilters.status || newFilters.status.length === 0) {
    newFilters.status = pendingStatuses.value.map((status) => status.value);
  }
  Api.organization.enrollment.index(newFilters, (resp) => {
    if (queryCount.value === currentQueryCount) {
      enrollments.value = resp.data.map((er) => ({ ...er, checked: false }));
      pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
      total.value = parseInt(resp.headers['x-total-count'] || 0, 10);
      processing.value = false;
    }
  });
}

function toggleSort(field) {
  if (filters.value.sort_field !== field) {
    filters.value.sort_dir = 'desc';
  } else {
    filters.value.sort_dir = filters.value.sort_dir === 'asc' ? 'desc' : 'asc';
  }
  filters.value.sort_field = field;
  loadEnrollments();
}
</script>
