<template>
  <v-container v-if="schema">
    <FormDialog
      @save="create"
      ref="dialog"
      :schema-id="schema.id"
    />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';

const route = useRoute();
const schema = ref(null);
const dialog = ref(null);
const eventBus = useEventBus();

function create() {
  if (schema.value?.meta?.submitted_redirect_url) {
    window.location.replace(schema.value.meta.submitted_redirect_url);
  }
}

async function load() {
  await loadSchema();
  const memberParams = {
    is_anonymous: true,
    organization_id: window.organization_id,
    is_parent: true,
  };
  Api.member.create(
    memberParams,
    () => dialog.value.open(),
    (err) => eventBus.error(err),
  );
}

async function loadSchema() {
  const resp = await Api.public_api.organization.schema
    .get(route?.params.formSchemaId)
    .catch(() => eventBus.longChime('Form could not be found'));
  if (!resp) return;

  schema.value = resp.data;
}

watch(
  () => route?.params.formSchemaId,
  async (newFormSchemaId) => {
    if (newFormSchemaId) {
      await load();
    }
  },
  { immediate: true },
);

onMounted(load);
</script>
