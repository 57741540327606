<template>
  <div
    v-if="subsidyProgram"
    class="py-4"
  >
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      :back-route="{
        name: 'ChildSubsidyProgramShow',
        params: { subsidyProgramId: subsidyProgram.id },
      }"
      name="IEP Referrals"
    />

    <v-card
      border
      flat
    >
      <v-card-title>
        <span>Under construction</span>
      </v-card-title>
      <v-card-text>
        <p>This is a new feature set and more will be coming soon.</p>

        <p>
          For now, you can upload new IEP referrals using the button below and view existing
          referrals by creating an IEP Referral report. The file must have the following columns:
        </p>

        <ol class="py-4 px-12">
          <li>parent_email</li>
          <li>child_first_name</li>
          <li>child_dob - format YYYY-MM-DD</li>
          <li>team_id</li>
          <li>program_id</li>
        </ol>

        <p>To archive referrals, upload file with columns:</p>
        <ol class="py-4 px-12">
          <li>id - id of existing referral; pull from report</li>
          <li>archived - text = "true"</li>
        </ol>
        <p>File must be CSV format.</p>

        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              accept=".csv"
              label="Upload IEP Referrals"
            />
            <v-btn
              @click="upload"
              :disabled="!file"
              color="primary"
              variant="flat"
              tile
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import Api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const route = useRoute();

const file = ref(null);
const subsidyProgram = ref(null);
const subsidyProgramId = route.params.subsidyProgramId;

const upload = async () => {
  const reader = new FileReader();
  reader.readAsText(file.value, 'UTF-8');
  reader.onload = (evt) => {
    const params = {
      file: evt.target.result,
      subsidy_program_id: subsidyProgramId,
    };
    Api.organization.iep_referral
      .upload(params)
      .then(() => {
        eventBus.chime('IEP Referrals uploaded');
      })
      .catch(() => {
        eventBus.chime('Failed to upload IEP Referrals');
      });
  };
};

const load = async () => {
  const response = await Api.organization.subsidy_program.get(subsidyProgramId);
  subsidyProgram.value = response.data;
};

onMounted(() => {
  load();
});
</script>
