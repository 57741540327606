<template>
  <v-window-item value="funding-sources">
    <template v-if="!processing">
      <div
        v-for="source in fundingSources"
        :key="source.id"
        class="mb-4"
      >
        <FundingSourceCard
          @destroy="loadFundingSources"
          @move="reorder(source, $event)"
          :display-index="getDisplayPositionIndex(source)"
          :schemas="schemas"
          :source="source"
          :total-count="fundingSources.length"
          ordered
        />
      </div>

      <v-btn
        @click="draftFundingSource"
        class="mt-4"
        color="primary"
        prepend-icon="add"
      >
        {{ $t('New funding source') }}
      </v-btn>

      <ResourceDialog
        @save="createFundingSource"
        :fields="fundingSourceFields"
        :processing="processing"
        action="new_funding_source"
        save-button-text="Create"
        title="Create funding source"
      />
    </template>
  </v-window-item>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRoute } from 'vue-router';

defineProps({
  schemas: {
    type: Array,
    default: null,
  },
});

const route = useRoute();
const { updateQuery } = useRouterHelper();

const funds = ref([]);
const fundingSources = ref([]);
const processing = ref(false);

const fundingSourceFields = computed(() => [
  { required: true, text: 'Name', value: 'name' },
  {
    required: true,
    text: 'Fund',
    value: 'fund_id',
    items: funds.value,
    itemText: 'name',
    itemValue: 'id',
  },
]);

async function createFundingSource(newVal) {
  processing.value = true;

  const { data } = await Api.organization.funding_source.create({
    ...newVal,
    owner_id: route.params.subsidyProgramId,
    owner_type: 'SubsidyProgram',
  });

  await updateQuery({ action: null });
  fundingSources.value.push(data);

  processing.value = false;
}

async function draftFundingSource() {
  await updateQuery({ action: 'new_funding_source' });
}

function getDisplayPositionIndex(source) {
  if (source.display_order !== null) return source.display_order - 1;

  return fundingSources.value.indexOf(source);
}

async function load() {
  processing.value = true;

  await loadFundingSources();
  await loadFunds();

  processing.value = false;
}

async function loadFundingSources() {
  const params = { owner_id: route.params.subsidyProgramId };
  const { data } = await Api.organization.funding_source.index(params);
  fundingSources.value = data.filter((source) => !source.provider_program_requirements);
}

async function loadFunds() {
  const resp = await Api.organization.fund.index({});

  if (resp.data) {
    funds.value = resp.data;
  }
}

async function reorder(source, displayOrderChange) {
  const params = {
    display_order_was: source.display_order,
    display_order: getDisplayPositionIndex(source) + 1 + displayOrderChange,
  };

  await Api.organization.funding_source.reorder(source.id, params);
  await loadFundingSources();
}

onMounted(load);
</script>
