<template>
  <ResourceDialog
    @save="createEnrollment"
    ref="dialog"
    :processing="processing"
    save-button-text="Add"
    title="Add"
  >
    <template #form="{ localValue }">
      <v-row>
        <LabeledControl
          :message="providerTerm"
          cols="12"
        >
          <v-autocomplete
            v-model="localValue.providerId"
            @update:model-value="loadPrograms(localValue.providerId)"
            @update:search="loadProviders"
            @vue:mounted="loadProviders('')"
            :items="providers"
            data-cy="provider-autocomplete"
            data-testid="provider-autocomplete"
            item-title="name"
            item-value="id"
            placeholder="Search by name"
            prepend-inner-icon="search"
            variant="filled"
            auto-select-first
            hide-details
          >
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :subtitle="item.raw.location"
                :title="item.raw.name"
              />
            </template>
          </v-autocomplete>
        </LabeledControl>
        <template v-if="programRequired">
          <LabeledSimpleSelect
            v-model="localValue.programId"
            :items="programs"
            :message="programTerm"
            cols="12"
            item-title="name"
            item-value="id"
          />
        </template>
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const props = defineProps({
  programRequired: Boolean,
  programTerm: {
    default: 'Program',
    type: String,
  },
  providerTerm: {
    default: 'Location',
    type: String,
  },
  subsidy: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['change']);
const eventBus = useEventBus();
const store = useStore();

const dialog = ref(null);
const processing = ref(false);
const programs = ref([]);
const providers = ref([]);
const providerQueryCount = ref(0);

defineExpose({
  open,
});

async function createEnrollment(newVal) {
  if (!newVal.providerId) {
    eventBus.chime(`${props.providerTerm} required`);
    return;
  }
  if (!newVal.programId && props.programRequired) {
    eventBus.chime(`${props.programTerm} required`);
    return;
  }

  processing.value = true;
  const params = {
    child_id: props.subsidy.child_id,
    subsidy_id: props.subsidy.id,
    program_id: newVal.programId,
    provider_id: newVal.providerId,
    status: 'Selected',
    subsidy_program_id: props.subsidy.subsidy_program_id,
  };

  const resp = await Api.organization.enrollment.create(params);
  processing.value = false;
  if (resp?.data) {
    emit('change', resp.data);
    dialog.value.close();
  }
}

function open() {
  providers.value = [];
  programs.value = [];
  dialog.value.open({});
}

function loadPrograms(newProviderId) {
  if (!props.programRequired) return;

  processing.value = true;
  Api.public_api.organization.program.index(
    { provider_id: newProviderId, subsidy_program_id: props.subsidy.subsidy_program_id },
    (resp) => {
      programs.value = resp.data;
      processing.value = false;
    },
  );
}

function loadProviders(query) {
  if (!query) {
    providers.value = [];
    return;
  }

  providerQueryCount.value += 1;
  const count = providerQueryCount.value;
  Api.organization.provider.index(
    {
      query,
      subsidy_program_id: props.subsidy.subsidy_program_id,
      page_size: 200,
      exclude_unpublished: !store.state.profile.org_enrollments_admin,
    },
    (resp) => {
      if (providerQueryCount.value === count) providers.value = resp.data;
    },
  );
}
</script>
