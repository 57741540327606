<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <v-row>
        <v-col class="d-flex align-center">
          <span>{{ $t('Photos') }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            @click="$refs.newDialog.open()"
            id="manager_add_photo"
            color="primary"
            tracked
          >
            <v-icon
              class="fs-18"
              start
            >
              add
            </v-icon>
            <span>{{ $t('Upload') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-4" />

    <v-card-text>
      <ProviderImages
        @destroy="destroy"
        ref="providerImages"
        :provider-id="providerId"
        role="manager"
      />
    </v-card-text>

    <ResourceDialog
      ref="newDialog"
      save-button-text="Done"
      title="Upload"
      close-on-save
    >
      <template #form>
        <dashboard
          :props="uppyProps"
          :uppy="uppy"
        />
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ProviderImages from '@/shared/components/provider/ProviderImages.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Uppy from '@uppy/core';
import useUppyUtils from '@/shared/composables/useUppyUtils';
import XHRUpload from '@uppy/xhr-upload';
import { Dashboard } from '@uppy/vue';

const { getUploadAPIEndpoint, getUppyLocale } = useUppyUtils();

const props = defineProps({
  providerId: {
    type: String,
    default: null,
  },
});

const providerImages = ref(null);
const newDialog = ref(null);
const uppy = ref(null);
const uppyProps = ref({
  proudlyDisplayPoweredByUppy: false,
  inline: true,
  height: 200,
  locale: getUppyLocale(),
  width: '100%',
});

onBeforeUnmount(() => {
  uppy.value.close();
});

onMounted(() => {
  uppy.value = new Uppy().use(XHRUpload, {
    endpoint: getUploadAPIEndpoint(),
  });

  uppy.value.on('upload-success', (file, evt) => {
    const asset = {
      file: {
        id: evt.body.id, // remove the Shrine storage prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      },
      provider_id: props.providerId,
    };

    Api.organization.asset.create(asset, () => {
      newDialog.value.close();
      providerImages.value.load();
    });
  });
});

function destroy(asset) {
  Api.organization.asset.destroy(asset.id, () => {
    providerImages.value.load();
  });
}
</script>
