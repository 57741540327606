<template>
  <div v-if="provider">
    <v-card
      :border="outlined"
      class="c-black"
      tile
    >
      <template v-if="ordered">
        <v-row class="d-flex align-center">
          <v-col
            class="d-flex align-center justify-start"
            cols="8"
          >
            <div class="pa-3">
              <template v-if="canEditPreferences">
                <EditableSelectionRanking
                  @move="$emit('move', $event)"
                  :index="index"
                  :length="length"
                />
              </template>
              <template v-else>
                <SelectionRanking :index="index" />
              </template>
            </div>
          </v-col>
          <v-col class="d-flex align-center justify-end me-4">
            <template v-if="displayPublicStatus">
              <v-chip
                v-show="enrollment.status != enrollment.public_status"
                data-testid="public-status-chip"
              >
                <v-icon start> public </v-icon>
                <span v-t="getStatusText(enrollment.public_status)" />
              </v-chip>
            </template>
          </v-col>
        </v-row>

        <v-divider />
      </template>

      <v-card-text class="pa-3">
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col cols="8">
            <span
              v-t="provider.name"
              class="c-black fw-600 fs-20"
              data-testid="provider-name"
              dense
            />
          </v-col>
          <v-col
            v-if="enrollment"
            class="d-flex justify-end"
            cols="4"
          >
            <v-btn
              @click="openEditDialog"
              :aria-label="`Change from ${getDisplayStatus(enrollment)}`"
              :disabled="!canEditEnrollment"
              class="me-3"
              color="primary"
              variant="outlined"
              tile
            >
              {{ $t(getDisplayStatus(enrollment)) }}
            </v-btn>

            <template v-if="canDelete">
              <ActionMenu
                @click:action:destroy="destroy"
                @click:action:draft-due-date="$refs.dueDateDialog.open(enrollment)"
                :items="actionItems"
                left
              />
            </template>

            <ResourceDialog
              @change="checkStatusChange($event)"
              @save="confirmStatusChangeAndUpdate"
              ref="editDialog"
              :fields="editFields"
              :loading="processing"
              :max-width="700"
              :processing="processing"
              title="Edit enrollment"
            >
              <template
                v-if="canEditEnrollmentDates"
                #append="{ localValue }"
              >
                <template v-if="DUE_DATE_STATUSES.includes(localValue.status)">
                  <v-divider class="my-2" />
                  <div class="my-4">
                    <p
                      v-t="'Response deadline'"
                      class="c-black fs-16 fw-500 mb-2"
                    />

                    <v-date-picker
                      @update:model-value="setEnrollmentResponseDueDate"
                      :landscape="$vuetify.display.mdAndUp"
                      :min="formatDate(today)"
                      :model-value="enrollmentResponseDueDateObject"
                      class="b-1 dense bc-primary"
                      data-testid="enrollment-due-date-picker"
                      full-width
                    />
                  </div>
                </template>
                <template v-else-if="localValue.status == ENROLLMENT_STATUSES.ENROLLED">
                  <template v-if="subsidyProgram.enrollment_start_date_required">
                    <v-divider class="my-2" />
                    <div class="my-4">
                      <p
                        v-t="'Start date'"
                        class="c-black fs-16 fw-500 mb-2"
                      />

                      <v-date-picker
                        @update:model-value="setEnrollmentStartDate"
                        :landscape="$vuetify.display.mdAndUp"
                        :model-value="enrollmentStartDateObject"
                        class="b-1 dense bc-primary"
                        data-testid="enrollment-start-date-picker"
                        full-width
                      />
                    </div>
                  </template>
                </template>

                <template v-else-if="END_DATE_STATUSES.includes(localValue.status)">
                  <v-divider class="my-2" />
                  <div
                    v-if="unenrollFutureStartDate(localValue.status)"
                    class="my-4"
                  >
                    <p
                      v-t="
                        `By unenrolling before the start date, you're confirming the child never attended.`
                      "
                    />
                  </div>

                  <div
                    v-else
                    class="my-4"
                  >
                    <p
                      v-t="'Enter the last day the child attended the program'"
                      class="c-black fs-16 fw-500 mb-2"
                    />

                    <v-date-picker
                      @update:model-value="setEnrollmentEndDate"
                      :landscape="$vuetify.display.mdAndUp"
                      :max="formatDate(today)"
                      :min="minimumEnrollmentEndDate"
                      :model-value="enrollmentEndDateObject"
                      class="b-1 dense bc-primary"
                      data-testid="enrollment-end-date-picker"
                      full-width
                    />
                  </div>
                </template>

                <template v-if="showStatusPublishesNotice(localValue.status)">
                  <p class="mt-3 fs-16">
                    <strong
                      v-t="'Note:'"
                      class="me-1"
                    />
                    <span
                      v-t="
                        'This enrollment status change will be automatically published to the family.'
                      "
                    />
                  </p>
                </template>
              </template>
            </ResourceDialog>
          </v-col>
        </v-row>

        <v-row
          class="ma-0 pa-0"
          dense
        >
          <v-col class="d-flex align-center">
            <div
              v-if="providerAddress()"
              class="d-flex"
            >
              <div class="fs-16 fw-400">
                {{ providerAddress() }}
              </div>
            </div>
            <template v-if="store.state.profile.org_enrollments_access">
              <span class="mx-3 fw-400 fw-14">|</span>
              <router-link
                :to="{ name: 'ProviderShow', params: { providerId: provider.id } }"
                class="fs-14 fw-400"
                color="secondary"
                target="_blank"
                small
              >
                <span v-t="'View profile'" />
              </router-link>
            </template>
          </v-col>
        </v-row>

        <v-row
          v-if="provider.quality_rating_score > 0"
          dense
        >
          <v-col class="d-flex align-center justify-start">
            <img
              v-if="
                store.state.site['Custom Ratings'] && store.state.site['Custom Ratings'].length > 0
              "
              :src="store.state.site['Custom Ratings'][0].pass"
              alt="Custom Quality Rating Score"
              height="20"
            />
            <v-icon
              v-else
              alt=""
              color="#FFD200"
              role="presentation"
              size="20"
            >
              star
            </v-icon>
            <span class="fs-16 fw-400">
              {{ $t(provider.quality_rating_name) }}: {{ provider.quality_rating_score }}
              {{ $t('out of') }} {{ provider.quality_rating_max }}
            </span>
          </v-col>
        </v-row>

        <template v-if="programs.length > 0 && enrollment.program_id">
          <v-divider class="my-4" />
          <v-card flat>
            <v-card-text class="pa-0">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="d-flex align-center fs-16 fw-500">
                  <v-icon
                    color="black"
                    size="20"
                    start
                  >
                    chair_alt
                  </v-icon>
                  <span
                    v-text="programs.find((program) => program.id == enrollment.program_id).name"
                  />
                </v-col>
                <v-col class="d-flex justify-end">
                  <template v-if="enrollment.relationships?.seat_type">
                    <v-chip
                      @click:close="releaseSeat(enrollment.relationships.seat.data.id)"
                      :closable="
                        [ENROLLMENT_STATUSES.DECLINED, ENROLLMENT_STATUSES.WITHDRAWN].includes(
                          enrollment.status,
                        )
                      "
                    >
                      {{ enrollment.relationships.seat_type?.data?.attributes?.name }}
                    </v-chip>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="enrollment.due_date || enrollment.start_date || enrollment.end_date">
          <v-divider class="my-4" />
          <v-card flat>
            <v-card-text class="pa-0">
              <v-row
                class="d-flex align-center"
                dense
              >
                <v-col class="fs-16">
                  <LongDate
                    :date="enrollment.due_date"
                    prefix="Response deadline:"
                  />
                  <br v-if="enrollment.due_date" />
                  <LongDate
                    :date="enrollment.start_date"
                    prefix="Enrollment start date:"
                  />
                  <br v-if="enrollment.start_date" />
                  <LongDate
                    :date="enrollment.end_date"
                    prefix="Enrollment end date:"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="fundingSources.length > 0 && enrollment.funding_source_ids.length > 0">
          <v-divider class="my-4" />
          <v-chip
            v-for="fsid in enrollment.funding_source_ids"
            :key="fsid"
            class="me-3"
          >
            {{ fundingSources.find((fundingSource) => fundingSource.id == fsid).name }}
          </v-chip>
        </template>
      </v-card-text>
    </v-card>

    <FormReviewDialog
      ref="formReviewDialog"
      show-unpublished-questions
    />

    <FormCard
      v-for="form in forms"
      @destroy="destroyForm(form)"
      @status="setFormStatus(form, $event)"
      :key="form.id"
      :form="form"
      :programs="programs"
      :provider="provider"
      back="subsidy"
    />

    <template v-if="enrollment && subsidyProgram?.enrolled_form_schema_id">
      <FormDialog
        @save="update"
        ref="enrolledDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.enrolled_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
        show-unpublished-questions
      />
    </template>

    <template v-if="enrollment && subsidyProgram?.unenroll_form_schema_id">
      <FormDialog
        @save="update"
        ref="unenrollDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.unenroll_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
      />
    </template>

    <template v-if="enrollment && subsidyProgram?.withdraw_form_schema_id">
      <FormDialog
        @save="update"
        ref="withdrawDialog"
        :enrollment-id="enrollment.id"
        :group-id="enrollment.group_id"
        :provider-id="enrollment.provider_id"
        :schema-id="subsidyProgram.withdraw_form_schema_id"
        :subsidy-id="enrollment.subsidy_id"
      />
    </template>

    <ResourceDialog
      @save="updateDueDate"
      ref="dueDateDialog"
      :fields="[{ text: 'Set response deadline', value: 'due_date', type: 'date' }]"
      title="Update response deadline"
    />
  </div>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import useApi from '@/shared/composables/useApi';
import EditableSelectionRanking from '@/shared/components/subsidy/EditableSelectionRanking.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import { formatAddress } from '@/shared/services/address';
import FormCard from '@/specialist/components/forms/FormCard.vue';
import FormReviewDialog from '@/shared/components/form/FormReviewDialog.vue';
import LongDate from '@/shared/components/LongDate.vue';
import SelectionRanking from '@/shared/components/subsidy/SelectionRanking.vue';
import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useTerms from '@/shared/composables/useTerms';
import { newUtcDate } from '@/shared/services/date-helper';
import { useStore } from 'vuex';
import { useDate } from 'vuetify';
import useEventBus from '@/shared/composables/useEventBus';

const { api } = useApi();
const date = useDate();
const { terms } = useTerms();
const store = useStore();
const { getStatusText, getValidStatusChanges, isPublishNoticeStatus } = useEnrollmentStatuses();
const eventBus = useEventBus();

const props = defineProps({
  enrollment: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: 0,
  },
  length: {
    type: Number,
    default: 0,
  },
  outlined: Boolean,
  fundingSources: {
    type: Array,
    default: () => [],
  },
  ordered: Boolean,
  readonly: Boolean,
  subsidy: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['change', 'change:destroy', 'move']);

const DUE_DATE_STATUSES = ref([ENROLLMENT_STATUSES.OFFERED, ENROLLMENT_STATUSES.ACCEPTED]);
const END_DATE_STATUSES = ref([ENROLLMENT_STATUSES.UNENROLLED, ENROLLMENT_STATUSES.GRADUATED]);
const canDelete = computed(
  () =>
    store.state.profile.org_enrollments_delete ||
    (store.state.profile.org_enrollments_agent && !props.subsidy.locked),
);
const deferredAcceptanceEnabled = computed(() => props.subsidyProgram.enable_deferred_acceptance);
const forms = ref([]);
const processing = ref(false);
const programs = ref([]);
const provider = ref(null);
const enrollmentResponseDueDate = ref(null);
const enrollmentEndDate = ref(null);
const enrollmentStartDate = ref(null);
const updatedEnrollment = ref(null);
const enrolledDialog = ref(null);
const unenrollDialog = ref(null);
const withdrawDialog = ref(null);
const editDialog = ref(null);
const dueDateDialog = ref(null);
const minimumEnrollmentEndDate = ref(null);

const actionItems = computed(() => {
  const items = [{ event: 'destroy', title: 'Delete', avatar: 'warning' }];
  if (!deferredAcceptanceEnabled.value) return items;
  if (!canEditEnrollmentDates.value) return items;

  if (
    props.enrollment.status === ENROLLMENT_STATUSES.OFFERED ||
    props.enrollment.status === ENROLLMENT_STATUSES.ACCEPTED
  ) {
    items.push({ event: 'draft-due-date', title: 'Set due date', avatar: 'event' });
  }

  return items;
});

const editFields = computed(() => {
  if (store.state.profile.org_enrollments_edit) {
    const fields = [
      {
        text: terms.value.program,
        value: 'program_id',
        items: programs.value,
        itemText: 'name',
        itemValue: 'id',
      },
      {
        text: 'Status',
        value: 'status',
        items: getValidStatusChanges(
          props.enrollment.status,
          deferredAcceptanceEnabled.value,
          store.state.profile,
        ),
        itemText: 'text',
        itemValue: 'value',
      },
    ];

    if (props.fundingSources.length > 0) {
      fields.push({
        text: 'Funding sources',
        value: 'funding_source_ids',
        items: props.fundingSources,
        itemText: 'name',
        itemValue: 'id',
        multiple: true,
      });
    }

    return fields;
  }

  if (store.state.profile.org_enrollments_agent) {
    return [
      {
        text: 'Status',
        value: 'status',
        items: getValidStatusChanges(
          props.enrollment.status,
          deferredAcceptanceEnabled.value,
          store.state.profile,
        ),
      },
    ];
  }

  return [];
});

const enrollmentStartDateObject = computed(() => {
  return dateAsObject(enrollmentStartDate.value);
});

const enrollmentEndDateObject = computed(() => {
  return dateAsObject(enrollmentEndDate.value);
});

const enrollmentResponseDueDateObject = computed(() => {
  return dateAsObject(enrollmentResponseDueDate.value);
});

const canEditEnrollment = computed(() => {
  if (store.state.profile.org_enrollments_edit) return true;

  if (store.state.profile.org_enrollments_agent) {
    return props.enrollment.status === ENROLLMENT_STATUSES.OFFERED;
  }

  return false;
});

const canEditEnrollmentDates = computed(() => {
  return store.state.profile.org_enrollments_edit;
});

const canEditPreferences = computed(() => {
  if (store.state.profile.org_enrollments_edit) return true;

  if (store.state.profile.org_enrollments_agent) {
    return !props.subsidy.locked || props.subsidy.preferences_unlocked;
  }

  return false;
});

const displayPublicStatus = computed(() => {
  return deferredAcceptanceEnabled.value && store.state.profile.org_enrollments_access;
});

const today = computed(() => {
  return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
});

onMounted(async () => {
  await loadForms();
  await load();
});

const checkStatusChange = (newVal) => {
  if (newVal[0] === 'status') {
    const newStatus = newVal[1];
    enrollmentResponseDueDate.value = null;

    if (newStatus === ENROLLMENT_STATUSES.ACCEPTED) {
      const programDueDate = props.subsidyProgram.enrollment_due_date;
      const rollingDueDateDays = props.subsidyProgram.enrollment_rolling_due_date_days;

      if (programDueDate && today.value < new Date(programDueDate)) {
        enrollmentResponseDueDate.value = programDueDate;
      } else if (rollingDueDateDays) {
        const newDate = new Date();
        newDate.setDate(today.value.getDate() + rollingDueDateDays);
        const rollingDueDate = newDate;
        enrollmentResponseDueDate.value = new Date(rollingDueDate);
      }
    } else if (newStatus === ENROLLMENT_STATUSES.ENROLLED) {
      const startDate = props.subsidyProgram.enrollment_start_date;

      if (props.enrollment.start_date) {
        enrollmentStartDate.value = props.enrollment.start_date;
      } else if (startDate && today.value < new Date(startDate)) {
        enrollmentStartDate.value = startDate;
      }
    }
  }
};

// Take a Date or a String as an argument and return a Date object.
// If the argument is a Date object, return it; otherwise, assume the object
// is a string, parse it, and return the resulting Date object.
const dateAsObject = (dateObjectOrString) => {
  if (dateObjectOrString instanceof Date && !isNaN(dateObjectOrString)) return dateObjectOrString;

  return dateObjectOrString ? date.parseISO(dateObjectOrString) : null;
};

const setEnrollmentStartDate = (newDateString) => {
  enrollmentStartDate.value = newDateString;
};

const setEnrollmentEndDate = (newDateString) => {
  enrollmentEndDate.value = newDateString;
};

const setEnrollmentResponseDueDate = (newDateString) => {
  enrollmentResponseDueDate.value = newDateString;
};

const unenrollFutureStartDate = (status) => {
  return (
    status === ENROLLMENT_STATUSES.UNENROLLED && new Date(props.enrollment.start_date) > today.value
  );
};

const confirmStatusChangeAndUpdate = (newVal) => {
  updatedEnrollment.value = newVal;
  const newStatus = newVal.status;

  if (props.enrollment.status === newStatus) {
    update();
    return;
  }

  let providerAndProgram = provider.value.name;
  const program = programs.value?.find((program) => program.id === props.enrollment.program_id);
  if (program) providerAndProgram += `/${program.name}`;

  // eslint-disable-next-line no-alert
  if (
    !confirm(
      `Are you certain you want to change the status for this child's enrollment at ${providerAndProgram} to ${getStatusText(newStatus).toUpperCase()}?`,
    )
  )
    return;

  if (newStatus === ENROLLMENT_STATUSES.ENROLLED && props.subsidyProgram.enrolled_form_schema_id) {
    enrolledDialog.value.open();
  } else if (
    newStatus === ENROLLMENT_STATUSES.UNENROLLED &&
    props.subsidyProgram.unenroll_form_schema_id
  ) {
    unenrollDialog.value.open();
  } else if (
    newStatus === ENROLLMENT_STATUSES.WITHDRAWN &&
    props.subsidyProgram.withdraw_form_schema_id
  ) {
    withdrawDialog.value.open();
  } else {
    update();
  }
};

const destroy = async () => {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete this enrollment?')) return;

  processing.value = true;
  const response = await api.organization.enrollment.destroy(props.enrollment.id);
  processing.value = false;

  if (response?.status !== 200) return;

  emit('change:destroy', response.data);
};

const destroyForm = async (form) => {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to destroy this form?')) return;

  await api.organization.form.destroy(form.id);
  loadForms();
};

const formatDate = (date) => {
  return date.toISOString().substring(0, 10);
};

const getDisplayStatus = (enrollment) => {
  if (store.state.profile.org_enrollments_access) return getStatusText(enrollment.status);
  return getStatusText(enrollment.public_status);
};

const load = async () => {
  await loadPrograms();
  await loadProvider();
};

const loadForms = async () => {
  const params = {
    enrollment_id: props.enrollment.id,
    subsidy_id: props.enrollment.subsidy_id,
  };

  const { data } = await api.organization.form.index(params);
  forms.value = data;
};

const loadPrograms = async () => {
  const params = {
    provider_id: props.enrollment.provider_id,
    subsidy_program_id: props.subsidyProgram.id,
  };
  const resp = await api.public_api.organization.program.index(params);
  programs.value = resp.data;
};

const loadProvider = async () => {
  const resp = await api.public_api.organization.provider.get(props.enrollment.provider_id);
  provider.value = resp.data;
};

const openEditDialog = () => {
  enrollmentResponseDueDate.value = props.enrollment.due_date;
  enrollmentStartDate.value = props.enrollment.start_date;

  // v-date-picker model expects UTC date format like 'Fri Jun 14 2024 05:32:46 GMT-0700', not string '2024-06-13'
  const endDateUtc = props.enrollment.end_date ? newUtcDate(props.enrollment.end_date) : null;
  const todayUtc = today.value ? newUtcDate(today.value) : null;

  enrollmentEndDate.value = endDateUtc || todayUtc;
  minimumEnrollmentEndDate.value = props.enrollment.start_date;
  editDialog.value.open(JSON.parse(JSON.stringify(props.enrollment)));
};

const releaseSeat = async (seatId) => {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to release this seat?')) return;

  const params = {
    id: seatId,
    enrollment_id: null,
  };
  await api.organization.seat.update(params);
  // eslint-disable-next-line vue/no-mutating-props
  props.enrollment.relationships = {};
};

const providerAddress = () => {
  if (!provider.value) return null;

  return formatAddress(provider.value.address1, provider.value.city, provider.value.zip);
};

const update = async (form) => {
  processing.value = true;
  const params = {
    funding_source_ids: updatedEnrollment.value.funding_source_ids,
    program_id: updatedEnrollment.value.program_id,
    status: updatedEnrollment.value.status,
  };

  if (
    updatedEnrollment.value.status === ENROLLMENT_STATUSES.OFFERED ||
    updatedEnrollment.value.status === ENROLLMENT_STATUSES.ACCEPTED
  ) {
    params.due_date = enrollmentResponseDueDate.value;
    params.publish = true;
  } else if (
    updatedEnrollment.value.status === ENROLLMENT_STATUSES.UNENROLLED &&
    new Date(props.enrollment.start_date) > today.value
  ) {
    params.end_date = null;
  } else if (updatedEnrollment.value.status === ENROLLMENT_STATUSES.ENROLLED) {
    params.enrolled_form_id = form?.id;
    params.start_date = enrollmentStartDate.value;
    params.end_date = null;
  } else if (
    updatedEnrollment.value.status === ENROLLMENT_STATUSES.UNENROLLED ||
    updatedEnrollment.value.status === ENROLLMENT_STATUSES.GRADUATED
  ) {
    params.end_date = enrollmentEndDate.value;
  }

  const resp = await api.organization.enrollment.update(props.enrollment.id, params);
  processing.value = false;
  if (resp?.status !== 200) return false;

  emit('change', resp.data);
  editDialog.value.close();
  load();
  if (form) loadForms();
  return true;
};

const setFormStatus = (form, newVal) => {
  let status;
  if (newVal.title === 'Approve') status = 'Approved';
  else if (newVal.title === 'Deny') status = 'Denied';

  updateForm(form.id, { status });
};

const showStatusPublishesNotice = (status) => {
  return isPublishNoticeStatus(status) && props.subsidyProgram.enable_deferred_acceptance === true;
};

const updateDueDate = async (newVal) => {
  processing.value = true;
  await api.organization.enrollment.update(props.enrollment.id, {
    due_date: newVal.due_date,
  });
  dueDateDialog.value.close();
  processing.value = false;
  emit('change');
};

const updateForm = async (formId, newVal) => {
  await api.organization.form
    .update(formId, newVal)
    .catch((error) => eventBus.emit('error', error));
  loadForms();
};
</script>
