<template>
  <CaseReview
    :back-to="backTo"
    :can-delete="$store.state.profile.org_forms_delete || $store.state.profile.org_forms_admin"
    :case-id="$route.params.id"
    :case-repository="Api.organization.form"
    :labels-editable="!readonly"
    :readonly="readonly"
    case-type="Form"
  />
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import CaseReview from '@/specialist/components/CaseReview.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const form = ref(null);

const backTo = computed(() => {
  if (route.query?.back === 'subsidy') return 'SubsidyShow';
  if (route.query?.back === 'provider') return 'ProviderShow';
  return 'FormIndex';
});

const readonly = computed(() => {
  const isAdminOrEditor =
    store.state?.profile?.org_forms_admin || store.state?.profile?.org_forms_edit;
  const isViewerAndCreator =
    store.state?.profile?.org_forms_access && store.state?.profile?.org_forms_create;
  const isSubmitted = form.value?.submitted_at;

  return !(isAdminOrEditor || (!isSubmitted && isViewerAndCreator));
});

onMounted(loadForm);

async function loadForm() {
  const { data } = await Api.organization.form.get(route.params.id);
  form.value = data;
}
</script>
