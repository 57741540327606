<template>
  <v-card
    class="mb-4"
    border
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col>
          <div
            v-t="subsidyProgram.name"
            class="fs-20 mb-2 fw-500"
          />
          <template v-if="subsidy">
            <div>
              <template v-if="subsidy.submitted_at">
                <span
                  v-t="'Submitted at'"
                  class="me-2 fs-14"
                />
                <LongDateTime
                  :date="subsidy.submitted_at"
                  class="fs-14"
                />
              </template>
              <template v-else>
                <span
                  v-t="'Started on'"
                  class="me-2 fs-14"
                />
                <LongDateTime
                  :date="subsidy.created_at"
                  class="fs-14"
                />
              </template>
            </div>
            <div>
              <span class="fs-12 c-light-black">ID# {{ subsidy.id }}</span>
            </div>
          </template>
          <div v-else>
            <span
              v-t="'Not started'"
              class="fs-14"
            />
          </div>
        </v-col>

        <v-col class="ta-right">
          <template v-if="subsidy">
            <template v-if="subsidy.submitted_at">
              <v-btn
                :to="{ name: 'SubsidyShow', params: { id: subsidy.id } }"
                class="focus-very-visible"
                color="primary"
                data-cy="review_application"
                tile
              >
                <v-icon start> check_circle </v-icon>
                <span v-t="'Review'" />
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                :disabled="programClosed()"
                :to="{ name: 'SubsidyShow', params: { id: subsidy.id } }"
                color="primary"
                tile
              >
                <v-icon start> check_circle </v-icon>
                <span v-t="'In progress'" />
              </v-btn>
            </template>
          </template>

          <v-btn
            v-else
            @click="createSubsidy(subsidyProgram)"
            :disabled="programClosed()"
            :loading="processing"
            color="primary"
          >
            {{ $t('Start') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';

const props = defineProps({
  childId: {
    type: String,
    default: null,
  },
  subsidy: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const router = useRouter();
const store = useStore();
const processing = ref(false);

const createSubsidy = async () => {
  processing.value = true;

  const params = {
    child_id: props.childId,
    subsidy_program_id: props.subsidyProgram.id,
  };
  const resp = await Api.organization.subsidy.create(params);
  router.push({ name: 'SubsidyShow', params: { id: resp.data.id } });
};

const programClosed = () => {
  if (
    store.state.profile?.org_subsidies_admin === true &&
    store.state.profile?.org_subsidies_edit === true
  ) {
    return false;
  }

  return !!props.subsidyProgram.closes_at && new Date() >= new Date(props.subsidyProgram.closes_at);
};
</script>
