<template>
  <ResourceDialog
    @save="$emit('save', $event)"
    ref="dialog"
    :processing="processing"
    title="Edit stage"
  >
    <template #form="{ localValue }">
      <v-list class="py-0">
        <v-hover
          v-for="stage in stages"
          :key="stage.id"
          v-slot="{ isHovering, props }"
        >
          <v-list-item
            @click="localValue.value = stage.id"
            v-bind="props"
          >
            <v-list-item-title>{{ $t(stage.title) }}</v-list-item-title>

            <template #append>
              <v-btn
                color="surface"
                data-cy="set-stage-button"
                size="small"
                variant="flat"
                border
                icon
              >
                <v-icon
                  v-if="isHovering || localValue.value == stage.id"
                  color="primary-lighten-2"
                  data-testid="stage-selected-icon"
                >
                  check
                </v-icon>
              </v-btn>
            </template>
          </v-list-item>
        </v-hover>
      </v-list>
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ResourceDialog,
  },

  props: {
    processing: Boolean,
    stages: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['save'],

  methods: {
    close() {
      this.$refs.dialog.close();
    },

    open() {
      this.$refs.dialog.open({ value: null });
    },
  },
};
</script>
