<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-container class="pt-12">
    <v-card
      class="mx-auto pa-4 mobile-padding"
      max-width="600"
      border
      flat
      tile
    >
      <v-card-text>
        <h1
          ref="title"
          v-t="$store.state.pages.Welcome.content.title || 'Welcome to BridgeCare'"
          class="fw-600 fs-32 mb-3 focus-invisible adjust-lh"
          tabindex="0"
        />
        <MarkdownContent
          :content="$store.state.pages.Welcome.content.subtitle || 'Login to access your account.'"
          class="c-black bc-body-lg mb-6"
        />

        <LoginWithSSO>
          <template
            v-if="nonSSOAuthEnabled"
            #append
          >
            <v-divider class="my-8" />
          </template>
        </LoginWithSSO>

        <template v-if="nonSSOAuthEnabled">
          <v-row>
            <LabeledTextfield
              v-model="email"
              @keyup.enter="submit()"
              id="login_code_email"
              color="primary"
              data-cy="email-input"
              message="Email address"
              autofocus
              filled
              hide-details
            />
            <template v-if="$store.state.config.enable_password_authentication">
              <LabeledTextfield
                v-model="password"
                @keyup.enter="submit()"
                color="primary"
                data-cy="password-input"
                message="Password"
                type="password"
                autofocus
                filled
                hide-details
              />
              <v-col>
                <PasswordReset scope="specialist" />
              </v-col>
            </template>
          </v-row>

          <v-row>
            <v-col>
              <button
                @click="submit"
                class="v-btn v-btn--elevated v-theme--light bg-primary v-btn--density-default v-btn--size-x-large v-btn--variant-elevated focus-very-visible"
                data-cy="submit-button"
              >
                <span
                  v-t="'Submit'"
                  class="button-text"
                />
                <span
                  class="button-loader"
                  style="display: none"
                >
                  <div
                    aria-valuemax="100"
                    aria-valuemin="0"
                    class="v-progress-circular v-progress-circular--indeterminate v-progress-circular--visible v-theme--light v-progress-circular--size-default"
                    role="progressbar"
                  >
                    <svg
                      style="transform: rotate(calc(-90deg))"
                      viewBox="0 0 43.63636363636364 43.63636363636364"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="v-progress-circular__underlay"
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="20"
                        stroke-dasharray="125.66370614359172"
                        stroke-dashoffset="0"
                        stroke-width="3.6363636363636367"
                      />
                      <circle
                        class="v-progress-circular__overlay"
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="20"
                        stroke-dasharray="125.66370614359172"
                        stroke-dashoffset="125.66370614359172px"
                        stroke-width="3.6363636363636367"
                      />
                    </svg>
                  </div>
                </span>
              </button>
            </v-col>
          </v-row>
        </template>

        <template
          v-if="
            $store.state.config.enable_magic_link_authentication &&
            $store.state.config.enable_password_authentication
          "
        >
          <v-row>
            <v-col>
              <button
                @click="createLoginCode"
                class="v-btn v-btn--elevated v-theme--light bg-primary v-btn--density-default v-btn--size-x-large v-btn--variant-elevated focus-very-visible"
                data-cy="login-code-button"
              >
                <span
                  v-t="'Request a one-time access code'"
                  class="button-text"
                />
                <span
                  class="button-loader"
                  style="display: none"
                >
                  <div
                    aria-valuemax="100"
                    aria-valuemin="0"
                    class="v-progress-circular v-progress-circular--indeterminate v-progress-circular--visible v-theme--light v-progress-circular--size-default"
                    role="progressbar"
                  >
                    <svg
                      style="transform: rotate(calc(-90deg))"
                      viewBox="0 0 43.63636363636364 43.63636363636364"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        class="v-progress-circular__underlay"
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="20"
                        stroke-dasharray="125.66370614359172"
                        stroke-dashoffset="0"
                        stroke-width="3.6363636363636367"
                      />
                      <circle
                        class="v-progress-circular__overlay"
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        r="20"
                        stroke-dasharray="125.66370614359172"
                        stroke-dashoffset="125.66370614359172px"
                        stroke-width="3.6363636363636367"
                      />
                    </svg>
                  </div>
                </span>
              </button>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="challengeDialogIsVisible"
      :max-width="600"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-text class="pa-6">
          <v-row dense>
            <v-col class="d-flex justify-center">
              <p
                v-t="'Enter the one-time passcode sent to your device:'"
                class="fs-16 fw-600"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="d-flex justify-center">
              <v-otp-input
                v-model="code"
                @finish="createSession"
                :disabled="processing"
                class="mxw-400"
                data-cy="otp-input"
                length="6"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LoginWithSSO from '@/shared/components/LoginWithSSO.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import PasswordReset from '@/shared/components/PasswordReset.vue';
import LabeledTextfield from '../../shared/components/form/LabeledTextfield.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();

const props = defineProps({
  api: {
    type: Object,
    default() {
      return Api;
    },
  },
});

const challenge = ref(null);
const challengeDialogIsVisible = ref(false);
const code = ref(null);
const email = ref(null);
const loaded = ref(false);
const mfaAttempts = ref(0);
const password = ref(null);
const processing = ref(false);
const isRedirecting = ref(false);

onMounted(() => {
  store.dispatch('identify', {
    success() {
      router.push({ name: 'Dashboard' });
    },
    failure() {
      loaded.value = true;
    },
  });
});

const nonSSOAuthEnabled = computed(() => {
  return (
    store.state.config.enable_magic_link_authentication ||
    store.state.config.enable_password_authentication
  );
});

function createLoginCode() {
  if (processing.value == true) {
    return;
  }
  disableButtons(true);
  processing.value = true;
  props.api.member.login_code.create(
    { email: email.value, organization_id: window.organization_id, is_specialist: true },
    (resp) => {
      router.push({
        name: 'CodeSent',
        query: { token: resp.data.code_id, email_sent_to: email.value, is_specialist: true },
      });
      processing.value = false;
      disableButtons(false);
    },
    (err) => {
      processing.value = false;
      disableButtons(false);
      eventBus.chime(err.response.data.errors.join('. '));
    },
  );
}

function createSession() {
  disableButtons(true);
  if (processing.value == true) {
    return;
  }
  processing.value = true;
  props.api.member.session.create(
    {
      challenge_id: challenge.value,
      code: code.value,
      email: email.value,
      organization_id: window.organization_id,
      password: password.value,
      scope: 'specialist',
    },
    (resp) => {
      processing.value = false;
      disableButtons(false);
      const { status } = resp.data;
      if (status === 'challenge') {
        challenge.value = resp.data.challenge_id;
        challengeDialogIsVisible.value = true;
        code.value = null;
        mfaAttempts.value += 1;
        if (mfaAttempts.value > 1) {
          eventBus.chime(
            'Your previous code has expired. A new code has been sent to your device.',
          );
        }
      } else if (status === 'mfa_failed') {
        code.value = null;
        eventBus.chime('You entered an incorrect code. Please re-enter and try again.');
      } else if (status === 'mfa_register') {
        code.value = null;
        isRedirecting.value = true;
        router.push({ name: 'RegisterFactor' });
      } else if (status === 'authenticated') {
        router.push({ name: 'Dashboard' });
      } else {
        eventBus.longChime('An unknown error has occurred. Please contact support for assistance.');
      }
    },
    () => {
      processing.value = false;
      disableButtons(false);
      password.value = null;
      eventBus.chime('Account could not be found for that email and password.');
    },
  );
}

function disableButtons(disabled) {
  let loaderEl = document.getElementsByClassName('button-loader');
  let buttonText = document.getElementsByClassName('button-text');
  for (const el of loaderEl) {
    if (disabled) {
      el.style.display = 'block';
    } else {
      el.style.display = 'none';
    }
  }
  for (const el of buttonText) {
    if (disabled) {
      el.style.display = 'none';
    } else {
      el.style.display = 'block';
    }
  }
  for (const button of document.querySelectorAll('button')) {
    button.disabled = disabled;
  }
}

function submit() {
  disableButtons(true);
  if (store.state.config.enable_password_authentication) {
    createSession();
  } else {
    createLoginCode();
  }
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .v-card .mobile-wrap {
    padding: 10px;
    height: auto;
    white-space: normal;
  }

  .mobile-padding {
    padding: 0 !important;
    word-wrap: normal;
  }
  .adjust-lh {
    line-height: 1 !important;
  }
}
</style>
