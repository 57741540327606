<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="createNewSpecialistForm"
      :createable="displayNewFormButton"
      :small-display="$vuetify.display.smAndDown"
      :title="terms.forms || 'Forms'"
      create-text="New form"
    />

    <v-toolbar
      :extension-height="$vuetify.display.smAndDown ? '160' : '60'"
      :height="$vuetify.display.smAndDown ? (showAssignButtons ? '100' : '40') : 'default'"
      class="mb-3 px-3 bb-1"
    >
      <v-row class="d-flex align-center">
        <template v-if="customViewOwners.length > 0">
          <v-col
            :class="[$vuetify.display.smAndDown ? 'py-2' : '']"
            class="d-flex align-center"
            cols="12"
            sm="9"
          >
            <v-select
              v-model="selectedCustomViewOwner"
              @update:model-value="handleOwnerChange"
              :aria-label="selectedCustomViewOwner?.name"
              :class="[$vuetify.display.smAndDown ? 'w-100' : 'mxw-400']"
              :items="customViewOwners"
              data-cy="schemas_list"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
              return-object
            />
          </v-col>
        </template>

        <v-col
          v-if="showAssignButtons"
          :class="[$vuetify.display.smAndDown ? 'justify-start py-2' : 'justify-end gx-3']"
          class="d-flex align-center"
          cols="12"
          sm="3"
        >
          <v-btn
            @click="handleSelectedItemTeamEdit"
            class="me-3"
            color="primary"
            variant="outlined"
          >
            {{ $t('Assign team') }}
          </v-btn>

          <v-btn
            @click="handleSelectedItemReviewerEdit"
            color="primary"
            variant="outlined"
          >
            {{ $t('Assign member') }}
          </v-btn>
        </v-col>
      </v-row>

      <template
        v-if="selectedCustomViewOwner"
        #extension
      >
        <div
          :class="[$vuetify.display.smAndDown ? 'd-flex flex-wrap ga-2' : 'd-flex flex-grow-1']"
          class="align-center"
        >
          <FilterMenu
            :active="selectedStatuses.length > 0"
            classes="d-md-inline-flex"
            test-id="status-filter-button"
            title="Status"
          >
            <template #card>
              <v-autocomplete
                v-model="selectedStatuses"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by status')"
                :disabled="processing"
                :item-title="(item) => $t(item)"
                :items="statuses"
                :menu="true"
                :placeholder="$t('Filter by status')"
                density="compact"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="teams.length > 0"
            :active="selectedTeams.length > 0"
            classes="d-md-inline-flex"
            test-id="team-filter-button"
            title="Team"
          >
            <template #card>
              <v-autocomplete
                v-model="selectedTeams"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by team name')"
                :disabled="processing"
                :item-title="(item) => $t(item.name)"
                :items="teams"
                :menu="true"
                :placeholder="$t('Filter by team name')"
                density="compact"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="specialists.length > 0"
            :active="selectedReviewers.length > 0"
            classes="d-md-inline-flex"
            title="Assignment"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="selectedReviewers"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by reviewer name')"
                :disabled="processing"
                :items="specialists"
                :menu="true"
                :placeholder="$t('Filter by reviewer name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="selectedCustomViewOwner.enable_stages"
            :active="selectedStages.length > 0"
            classes="d-md-inline-flex"
            title="Stage"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="selectedStages"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by stage title')"
                :item-title="(item) => $t(item.title)"
                :items="stages"
                :menu="true"
                :placeholder="$t('Filter by stage title')"
                density="compact"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              />
            </template>
          </FilterMenu>

          <FilterMenu
            v-if="displayLabels"
            :active="selectedLabels.length > 0"
            classes="d-md-inline-flex"
            title="Label"
            paddingless
          >
            <template #card>
              <v-autocomplete
                v-model="selectedLabels"
                @update:model-value="handleFilterChange"
                :aria-label="$t('Filter by label name')"
                :disabled="processing"
                :items="labels"
                :menu="true"
                :placeholder="$t('Filter by label name')"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="search"
                variant="filled"
                autofocus
                chips
                clearable
                multiple
              >
                <template #chip="{ item, props }">
                  <v-chip v-bind="props">
                    <template #prepend>
                      <v-avatar
                        :color="item.raw.color"
                        start
                      />
                    </template>
                    {{ item.raw.name }}
                  </v-chip>
                </template>
                <template #item="{ item, props }">
                  <v-list-item
                    v-bind="props"
                    :title="$t(item.raw.name)"
                  >
                    <template #prepend="{ isSelected }">
                      <v-checkbox-btn
                        :key="item.value"
                        :model-value="isSelected"
                        :ripple="false"
                        tabindex="-1"
                      />
                    </template>
                    <template #append>
                      <v-avatar
                        :color="item.raw.color"
                        size="20"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </template>
          </FilterMenu>

          <template
            v-if="
              selectedCustomView?.attributes.columns.length > 0 ||
              selectedCustomView?.attributes.filters.length > 0
            "
          >
            <v-btn
              @click="handleCustomViewFiltersEdit"
              :class="filtersButtonClass"
              variant="outlined"
              rounded
            >
              {{ $t('More filters') }}
              <v-icon
                class="ms-3"
                icon="filter_list"
                size="18"
              />
            </v-btn>
          </template>

          <v-spacer />

          <v-autocomplete
            v-if="customViews.length > 1"
            v-model="selectedCustomView"
            @update:model-value="handleSelectedCustomViewUpdate"
            :aria-label="selectedCustomViewOwner?.name"
            :items="customViews"
            class="ms-3"
            data-cy="custom_views_list"
            density="compact"
            item-title="attributes.name"
            max-width="250px"
            variant="filled"
            hide-details
            return-object
          />

          <v-btn
            @click="customViewManageEditor.open()"
            :class="columnsButtonClass"
            class="ms-2"
            prepend-icon="tune"
            variant="outlined"
          >
            {{ $t('Manage views') }}
          </v-btn>
        </div>
      </template>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      @update:model-value="handleModelValueUpdate"
      @update:page="handlePageChange"
      @update:sort-by="handleCustomViewSortsUpdate"
      :headers="headers"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="100"
      :loading="processing"
      :model-value="selectedItems"
      :page="currentPage"
      :sort-by="sortBy"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      item-key="id"
      no-data-text="No forms found"
      fixed-header
      hover
      return-object
      show-select
    >
      <template #item.id="{ item }">
        <span>
          {{ item.id.split('-')[0] }}
        </span>
      </template>

      <template #item.member_id="{ item }">
        <span>
          {{ getFormSubmitter(item) }}
        </span>
      </template>

      <template #item.team_id="{ item }">
        <v-tooltip
          v-if="getTeamName(item)"
          location="bottom"
          center
        >
          <template #activator="{ props }">
            <v-chip
              @click.stop.prevent
              @click:close="removeReviewer(item, true)"
              v-bind="props"
              :closable="store.state.profile.org_providers_admin"
              :disabled="processing"
              close-icon="close"
              size="small"
            >
              <InitialedText
                @click.stop.prevent
                :value="getTeamName(item)"
              />
            </v-chip>
          </template>

          <span>
            {{ getTeamName(item) }}
          </span>
        </v-tooltip>
      </template>

      <template #item.reviewer_id="{ item }">
        <v-tooltip
          v-if="getReviewerName(item)"
          location="bottom"
          center
        >
          <template #activator="{ props }">
            <v-chip
              @click.stop.prevent
              @click:close="removeReviewer(item, false)"
              v-bind="props"
              :closable="store.state.profile.org_providers_admin"
              :disabled="processing"
              close-icon="close"
              size="small"
            >
              <InitialedText
                @click.stop.prevent
                :value="getReviewerName(item)"
              />
            </v-chip>
          </template>

          <span>
            {{ getReviewerName(item) }}
          </span>
        </v-tooltip>
      </template>

      <template #item.submitted_at="{ item }">
        <LongDate
          :date="item.submitted_at"
          short-month
        />
      </template>

      <template #item.status="{ item }">
        {{ $t(item.status) }}
      </template>

      <template #item.enrollment_id="{ item }">
        <router-link
          v-if="formHasEnrollmentAndSubsidy(item)"
          @click.stop.prevent
          :ref="`subsidyLink-${item.subsidy_id}`"
          :to="{
            name: 'SubsidyShow',
            params: {
              id: item.subsidy_id,
            },
            query: {
              tab: 'Enrollment',
            },
          }"
          tracked
        >
          {{ $t(getFormEnrollmentName(item)) }}
        </router-link>

        <span v-else>
          {{ $t(getFormEnrollmentName(item)) }}
        </span>
      </template>

      <template #item.provider_id="{ item }">
        <router-link
          v-if="item.provider_id"
          @click.stop.prevent
          :ref="`providerLink-${item.provider_id}`"
          :to="{
            name: 'ProviderShow',
            params: {
              providerId: item.provider_id,
            },
            query: {
              tab: 'forms',
            },
          }"
          tracked
        >
          {{ $t(getFormProviderName(item)) }}
        </router-link>

        <span v-else>
          {{ $t(getFormProviderName(item)) }}
        </span>
      </template>

      <template #item.stage_id="{ item }">
        <template v-if="stages">
          {{ $t(getStageName(item)) }}
        </template>
      </template>

      <template #item.labels="{ item }">
        <LabelValue
          @edit="labelDialog.open(item)"
          :applied-labels="getLabels(item)"
          :disabled="processing"
          :editable="store.state.profile.org_forms_edit"
        />
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <LabelDialog
      @change="updateEditedLabels"
      @close="loadResults()"
      ref="labelDialog"
      :program-labels="labels"
      label-owner-type="Form"
    />

    <CustomViewFiltersEditor
      @save="handleCustomViewFiltersUpdate"
      ref="customViewFiltersEditor"
      :columns="selectedCustomView?.attributes.columns"
      :processing="processing"
    />

    <CustomViewManageEditor
      @reload="loadCustomViews"
      @save="handleCustomViewUpdate"
      ref="customViewManageEditor"
      :allowed-properties="allowedProperties"
      :custom-view-api="Api.organization.formCustomView"
      :custom-view-owner-id="selectedCustomViewOwner?.id"
      :custom-views="customViews"
    />

    <TeamEditor
      @save="handleSelectedItemTeamUpdate"
      ref="selectedItemTeamEditor"
      :processing="processing"
      :teams="teams"
    />

    <ReviewerEditorV2
      @save="handleSelectedItemReviewerUpdate"
      ref="selectedItemReviewerEditor"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import CustomViewFiltersEditor from '@/specialist/components/custom-views/CustomViewFiltersEditor.vue';
import CustomViewManageEditor from '@/specialist/components/custom-views/CustomViewManageEditor.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import InitialedText from '@/shared/components/InitialedText.vue';
import LabelDialog from '@/specialist/components/LabelDialog.vue';
import LabelValue from '@/specialist/components/LabelValue.vue';
import LongDate from '@/shared/components/LongDate.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ReviewerEditorV2 from '@/specialist/components/ReviewerEditorV2.vue';
import TeamEditor from '@/specialist/components/TeamEditor.vue';
import useTerms from '@/shared/composables/useTerms';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import {
  STATIC_STATUSES,
  NEUTRAL_STATUSES,
  POSITIVE_STATUSES,
} from '@/specialist/services/statuses';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useCustomViews from '@/specialist/composables/useCustomViews';

const customViewFiltersEditor = ref(null);
const customViewManageEditor = ref(null);
const selectedItemReviewerEditor = ref(null);
const selectedItemTeamEditor = ref(null);

const router = useRouter();
const store = useStore();
const { terms } = useTerms();
const {
  allowedProperties,
  currentPage,
  customViews,
  displayLabels,
  getLabels,
  getReviewerName,
  getStageName,
  getTeamName,
  handleSelectedItemReviewerUpdate,
  handleCustomViewFiltersEdit,
  handleCustomViewFiltersUpdate,
  handleCustomViewChange,
  handleCustomViewUpdate,
  handleFilterChange,
  handlePageChange,
  handleOwnerChange,
  handleModelValueUpdate,
  handleCustomViewSortsUpdate,
  handleSelectedCustomViewUpdate,
  handleSelectedItemReviewerEdit,
  handleSelectedItemTeamEdit,
  handleSelectedItemTeamUpdate,
  headers,
  items,
  load,
  labels,
  loadCustomViews,
  loadResults,
  members,
  processing,
  removeReviewer,
  selectedCustomView,
  selectedItems,
  selectedLabels,
  selectedReviewers,
  selectedStages,
  selectedStatuses,
  selectedTeams,
  sortBy,
  customViewOwners,
  selectedCustomViewOwner,
  specialists,
  stages,
  teams,
  totalItemCount,
  updateEditedLabels,
} = useCustomViews(
  loadOwners,
  'schema',
  'schema_id',
  Api.organization.form,
  Api.organization.formCustomView,
  customViewFiltersEditor,
  customViewManageEditor,
  selectedItemReviewerEditor,
  selectedItemTeamEditor,
);

const enrollments = ref([]);
const labelDialog = ref(null);
const providers = ref([]);
const statuses = ref(STATIC_STATUSES.concat(NEUTRAL_STATUSES, POSITIVE_STATUSES));

watch(customViews, (newValue) => {
  if (!newValue[0]) return;

  [selectedCustomView.value] = newValue;
  void handleCustomViewChange(selectedCustomView.value);
});

watch(items, () => {
  if (items.value.length === 0) return;

  const providerIds = _.uniq(items.value.map((form) => form.provider_id).filter(Boolean));
  loadProviders(providerIds);

  const enrollmentIds = _.uniq(items.value.map((form) => form.enrollment_id).filter(Boolean));
  void loadEnrollments(enrollmentIds);
});

const columnsButtonClass = computed(() => {
  return getHighlightedClass(selectedCustomView.value?.attributes.columns.length);
});

const displayNewFormButton = computed(() => {
  const authorizedToCreate =
    store.state.profile.org_forms_admin || store.state.profile.org_forms_create;

  return authorizedToCreate && selectedCustomViewOwner.value?.meta.specialist_requestable;
});

const filtersButtonClass = computed(() => {
  return getHighlightedClass(selectedCustomView.value?.attributes.filters.length);
});

const showAssignButtons = computed(() => {
  const authorizedToAssign =
    store.state.profile.org_forms_admin || store.state.profile.org_forms_edit;

  return authorizedToAssign && selectedItems.value.length > 0;
});

async function createNewSpecialistForm() {
  const response = await Api.organization.form.create({
    schema_id: selectedCustomViewOwner.value.id,
  });

  if (response?.status != 201) return;

  router.push({
    name: store.state.profile.org_forms_review ? 'FormReview' : 'FormShow',
    params: {
      id: response?.data.id,
    },
  });
}

// Enrollment column functions
function formHasEnrollmentAndSubsidy(form) {
  const enrollmentId = form?.enrollment_id || null;
  const subsidyId = form?.subsidy_id || null;

  return enrollmentId && subsidyId;
}

function getHighlightedClass(length) {
  return length > 0 ? 'bc-primary-light bg-primary-extra-light' : 'bg-white bc-very-light-gray';
}

function getFormEnrollmentName(form) {
  const enrollmentId = form?.enrollment_id || null;
  if (!enrollmentId) return '';

  const formEnrollment = enrollments.value.find((enrollment) => enrollment.id === enrollmentId);

  return formEnrollment?.name || '';
}

function getFormLink(id) {
  return {
    name: store.state.profile.org_forms_review ? 'FormReview' : 'FormShow',
    params: { id },
  };
}

function getFormProvider(form) {
  const providerId = form?.provider_id || null;
  if (!providerId) return '';

  const formProvider = providers.value.find((provider) => provider.id === providerId);

  return formProvider || {};
}

function getFormProviderName(form) {
  return getFormProvider(form)?.name || '';
}

function getFormSubmitter(form) {
  const memberId = form?.member_id || null;
  if (!memberId || members.value.length === 0) return '';

  const submitter = members.value.find((member) => member.id === memberId);
  return submitter?.name || '';
}

function handleRowClick(_event, { item }) {
  router.push(getFormLink(item.id));
}

async function loadEnrollments(ids) {
  const chunkSize = 50;
  const chunks = _.chunk(ids, chunkSize);

  await Promise.all(
    chunks.map(async (id) => {
      const response = await Api.organization.enrollment.index({ id });
      enrollments.value = enrollments.value.concat(response.data || []);
    }),
  );
}

async function loadOwners() {
  return Object.values(store.state.schemas).filter((schema) => schema.data_type === 'Form');
}

function loadProviders(id) {
  Api.organization.provider.index({ id }, (response) => (providers.value = response.data));
}

onMounted(load);
</script>
