<template>
  <v-btn
    @click="dialogIsVisible = !dialogIsVisible"
    :color="overallColor"
    class="ms-3"
    size="small"
    variant="flat"
    rounded
  >
    <v-icon
      color="white"
      role="presentation"
      aria-hidden
    >
      {{ overallIcon }}
    </v-icon>

    <v-dialog
      v-model="dialogIsVisible"
      max-width="600"
      scrim="transparent"
    >
      <v-card
        class="py-0 px-0"
        border
        flat
        tile
      >
        <v-card-text class="pt-2 pb-0 px-0">
          <SubsidyEligibilityCard
            :elevation="0"
            :funding-sources="fundingSources"
            :projected-eligibility="projectedEligibility"
            expanded
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="dialogIsVisible = false"
            color="primary"
            variant="flat"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script setup>
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';

const props = defineProps({
  fundingSources: {
    type: Array,
    default: null,
  },
  projectedEligibility: Boolean,
});

const dialogIsVisible = ref(false);

const overallColor = computed(() => {
  return props.projectedEligibility ? 'green' : 'red';
});

const overallIcon = computed(() => {
  return props.projectedEligibility ? 'check_circle' : 'cancel';
});
</script>
