<template>
  <v-card
    class="fill-height"
    elevation="2"
    tile
  >
    <v-card-title class="d-flex bb-1 bc-very-light-grey fs-16 fw-600 pa-5">
      <span tabindex="0">Notes</span>
      <v-spacer />
      <v-btn
        @click="$emit('close')"
        aria-label="Close Note"
        class="focus-visible"
        variant="text"
        icon
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-4">
      <div
        v-for="note in notes"
        :key="note.id"
        class="mb-3"
        tabindex="0"
      >
        <div class="bg-super-light-blue pa-3 fs-16 fw-500">
          <template v-if="note.isEditing">
            <v-row dense>
              <v-col>
                <v-textarea
                  v-model="note.editedText"
                  :model-value="note.editedText"
                  variant="outlined"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="d-flex justify-space-between">
                <v-btn
                  @click="toggleEdit(note)"
                  :loading="processing"
                  size="small"
                  variant="text"
                >
                  {{ $t('Cancel') }}
                </v-btn>
                <v-btn
                  @click="saveNote(note)"
                  :disabled="note.editedText.length === 0"
                  :loading="processing"
                  color="primary"
                  size="small"
                >
                  {{ $t('Save') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <div
            v-else
            class="d-flex justify-space-between"
          >
            <div v-text="note.text" />

            <ActionMenu
              v-if="note.member_id === $store.state.profile.id"
              @click:action:edit="toggleEdit(note)"
              :items="actions"
              button-class="ms-3"
              button-icon="more_vert"
            />
          </div>
        </div>
        <LongDateTime
          v-if="!note.isEditing"
          :date="note.created_at"
          :prefix="note.meta.member.name + ' - '"
          class="fs-12"
        />
        <span
          v-if="note.created_at != note.updated_at"
          v-t="' - Edited'"
          class="fs-12 c-primary"
        />
      </div>

      <v-divider class="my-6" />

      <v-textarea
        v-model="newNoteText"
        aria-label="Enter new note"
        data-testid="new-note-input"
        tabindex="0"
        variant="filled"
      />

      <v-btn
        @click="createNote"
        :disabled="processing"
        color="primary"
        block
      >
        <span v-t="'Add note'" />
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/shared/services/all_bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { mapUpdate } from '@/shared/services/utils';
import { useRoute } from 'vue-router';

const actions = [{ event: 'edit', avatar: 'edit', title: 'Edit' }];

const props = defineProps({
  ownerType: {
    default: 'Subsidy',
    type: String,
  },
  subsidy: {
    type: Object,
    default: null,
  },
});

defineEmits(['close']);

const eventBus = useEventBus();
const route = useRoute();

const newNoteText = ref(null);
const notes = ref([]);
const processing = ref(null);

async function createNote() {
  processing.value = true;

  const params = {
    owner_id: props.subsidy.id,
    owner_type: props.ownerType,
    text: newNoteText.value,
  };

  const resp = await Api.organization.note.create(params).catch((error) => eventBus.error(error));

  processing.value = false;
  if (!resp?.data) return;

  notes.value.push(resp.data);
  newNoteText.value = null;
}

async function loadNotes() {
  const resp = await Api.organization.note.index({ owner_id: route.params.id });
  notes.value = resp.data;
}

async function saveNote(note) {
  processing.value = true;
  const update = await Api.organization.note.update({ ...note, text: note.editedText });

  if (update) notes.value = mapUpdate(notes.value, update);
  processing.value = false;
}

function toggleEdit(note) {
  notes.value = mapUpdate(notes.value, {
    ...note,
    isEditing: !note.isEditing,
    editedText: note.text,
  });
}

onMounted(async () => {
  await loadNotes();
});
</script>
