<template>
  <div>
    <ResourceDialog
      @close="updateQuery({ action: null })"
      @save="create"
      ref="applicationCreatorDialog"
      :fields="fields"
      :max-width="800"
      :processing="processing"
      save-button-text="Create"
      title="New application"
    >
      <template #append="{ localValue }">
        <template v-if="localValue.terms_agreement_id">
          <v-row>
            <v-col>
              <v-btn
                @click="openTermsAgreement(localValue.terms_agreement_id)"
                color="primary"
                variant="outlined"
              >
                {{ $t('Review Terms of Service') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-if="duplicateGroupId">
          <v-alert
            class="mt-4"
            color="primary"
            text-color="white"
          >
            <p class="c-white">
              {{ $t(duplicateGroupIdMessage) }}
            </p>
            <v-btn
              @click="goToGroupShow(duplicateGroupId)"
              append-icon="east"
              variant="outlined"
            >
              {{ $t('Go to existing family') }}
            </v-btn>
          </v-alert>
        </template>
      </template>
    </ResourceDialog>

    <ContentDialog
      ref="agreementDialog"
      :max-width="800"
    >
      <template #content="{ data }">
        <MarkdownContent
          v-if="data"
          :content="data.termsAgreement.content_html"
          class="fs-16"
        />
      </template>
    </ContentDialog>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const { updateQuery } = useRouterHelper();
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const duplicateGroupIdMessage =
  "An account for this family already exists. The name and/or phone number are already associated with an existing account. To continue creating an application, access the family's profile and start an application for the specific child.";

const props = defineProps({
  selectedSubsidyProgram: {
    type: Object,
    default: () => null,
  },
  subsidyPrograms: {
    type: Array,
    default: () => [],
  },
  teams: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['change']);

const agreementDialog = ref(null);
const applicationCreatorDialog = ref(null);
const duplicateGroupId = ref(null);
const member = ref(null);
const processing = ref(false);
const sites = ref([]);
const termsAgreements = ref([]);

const fields = computed(() => {
  const fieldsAry = [
    {
      cols: '12',
      text: 'Application',
      value: 'subsidy_program_id',
      items: props.subsidyPrograms,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
    {
      cols: '12',
      text: 'Language preference',
      value: 'default_locale',
      items: store.state.config.languages,
      itemText: 'text',
      itemValue: 'value',
      required: true,
    },
    {
      cols: '6',
      text: 'Primary parent/guardian first name',
      value: 'primary_first_name',
      required: true,
    },
    {
      cols: '6',
      text: 'Primary parent/guardian last name',
      value: 'primary_last_name',
      required: true,
    },
    {
      cols: '6',
      text: 'Primary parent/guardian email',
      value: 'primary_email',
      required: false,
    },
    {
      cols: '6',
      mask: '(###) ###-####',
      text: 'Primary parent/guardian phone',
      value: 'primary_phone',
      required: false,
    },
    {
      cols: '6',
      text: 'Child first name',
      value: 'first_name',
      required: true,
    },
    {
      cols: '6',
      text: 'Child last name',
      value: 'last_name',
      required: true,
    },
    {
      cols: '12',
      text: 'Child date of birth',
      value: 'dob',
      type: 'dob',
      required: true,
    },
    {
      cols: '12',
      text: 'Primary site',
      value: 'site_id',
      items: sites.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
  ];

  if (props.selectedSubsidyProgram) {
    if (props.teams.length > 0 && props.selectedSubsidyProgram.enable_team_review) {
      fieldsAry.push({
        cols: '12',
        text: 'Team assignment',
        value: 'team_id',
        items: props.teams,
        itemText: 'name',
        itemValue: 'id',
        required: false,
      });
    }
  }

  if (termsAgreements.value.length > 0) {
    fieldsAry.push(
      {
        cols: '12',
        text: 'Applicable Terms of Service',
        value: 'terms_agreement_id',
        items: termsAgreements.value,
        itemText: 'name',
        itemValue: 'id',
        required: true,
      },
      {
        cols: '12',
        text: 'I accept the Terms of Service',
        value: 'terms_agreement_accepted',
        type: 'boolean',
        required: true,
      },
    );
  }

  fieldsAry.push({
    cols: '12',
    hidden: (localValue) => !localValue?.primary_phone,
    text: 'I consent on behalf of the member to receive communication by text message to the cellphone number provided above',
    type: 'boolean',
    value: 'text_opted_in',
  });

  return fieldsAry;
});

watch(() => route.query.action, checkAction);

onMounted(checkAction);

async function checkAction() {
  if (route.query.action === 'create') {
    duplicateGroupId.value = null;
    member.value = null;
    await loadSites();
    await loadTermsAgreements();
    applicationCreatorDialog.value?.open({
      default_locale: store.state.profile?.default_locale,
      site_id: sites.value[0]?.id,
      subsidy_program_id: props.selectedSubsidyProgram?.id,
    });
  } else {
    applicationCreatorDialog.value?.close();
  }
}

async function create(newChild) {
  processing.value = true;
  duplicateGroupId.value = null;

  const parentParams = {
    is_parent: true,
    first_name: newChild.primary_first_name,
    last_name: newChild.primary_last_name,
    email: newChild.primary_email,
    phone: newChild.primary_phone,
    site_id: newChild.site_id,
    terms_agreement_id: newChild.terms_agreement_id,
    terms_agreement_accepted: newChild.terms_agreement_accepted,
    text_opted_in: newChild.text_opted_in,
    default_locale: newChild.default_locale,
  };

  await Api.organization.member.create(
    parentParams,
    async (memberResp) => {
      const childParams = {
        dob: newChild.dob,
        first_name: newChild.first_name,
        last_name: newChild.last_name,
      };
      const childResp = await Api.organization.group.child.create(
        memberResp.data.group_id,
        childParams,
      );

      const subsidyParams = {
        child_id: childResp.data.id,
        subsidy_program_id: newChild.subsidy_program_id,
        team_id: newChild.team_id,
      };
      const subsidyResp = await Api.organization.subsidy.create(subsidyParams);
      if (subsidyResp.data) emit('change', subsidyResp.data);
    },
    async (memberError) => {
      processing.value = false;
      let existingMember;
      const error = memberError.response?.data?.errors[0];
      if (error === 'Email already taken') {
        const params = { query: newChild.primary_email, is_parent: true };
        existingMember = await Api.organization.member.index(params);
      } else if (error === 'Phone already taken') {
        const params = {
          query: newChild.primary_phone.match(/\d+/g).join(''),
          is_parent: true,
        };
        existingMember = await Api.organization.member.index(params);
      } else {
        eventBus.error(memberError);
        return;
      }
      duplicateGroupId.value = existingMember.data[0].group_id;
    },
  );
}

function goToGroupShow(groupId) {
  router.push({ name: 'GroupShow', params: { groupId } });
}

async function loadSites() {
  const resp = await Api.organization.site.index({ site_type: 'parent' });
  sites.value = resp.data;
}

async function loadTermsAgreements() {
  const response = await Api.organization.termsAgreement.index();
  termsAgreements.value = response.data;
}

function openTermsAgreement(termsAgreementId) {
  const termsAgreement = termsAgreements.value.find((ta) => ta.id === termsAgreementId);
  agreementDialog.value?.open({ termsAgreement });
}
</script>
