import Api from '@/specialist/services/bright_finder';
import { useRoute } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { ProgramParams } from '@/shared/services/program-params';

export default function useSubsidyPrograms({ providerId } = {}) {
  const { updateQuery } = useRouterHelper();
  const route = useRoute();

  const dashboard = ref(null);
  const processing = ref(false);
  const programs = ref([]);
  const subsidyPrograms = ref([]);

  const subsidyProgramFilter = computed(() => {
    return subsidyPrograms.value.find((sp) => sp.id === route.query.subsidyProgramId)?.name ?? null;
  });

  async function loadDashboard() {
    dashboard.value = null;
    const provider_id = providerId || route.params.providerId;
    if (!route.query.subsidyProgramId) return;

    const filters = { subsidy_program_id: route.query.subsidyProgramId };
    const resp = await Api.organization.provider.dashboard.get(provider_id, filters);
    dashboard.value = resp.data;
  }

  async function loadPrograms() {
    const provider_id = providerId || route.params.providerId;

    if (!provider_id) return;

    processing.value = true;
    programs.value = [];

    const filters = { subsidy_program_id: route.query.subsidyProgramId };
    const { data } = await Api.program.index(provider_id, filters);
    programs.value = data.map((program) => new ProgramParams(program).asDraft());
    processing.value = false;
  }

  async function load() {
    const resp = await Api.organization.subsidy_program.index();
    subsidyPrograms.value = resp.data;

    if (route.query.subsidyProgramId) {
      await loadDashboard();
      await loadPrograms();
    } else {
      await updateQuery({ subsidyProgramId: subsidyPrograms.value[0]?.id });
    }
  }

  watch(
    () => route.query.subsidyProgramId,
    () => {
      void load();
    },
    { immediate: true },
  );

  return {
    dashboard,
    load,
    loadDashboard,
    processing,
    programs,
    subsidyPrograms,
    subsidyProgramFilter,
  };
}
