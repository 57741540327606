<template>
  <ResourceDialog
    @change="handleChange"
    @save="handleSave"
    ref="dialog"
    :fields="createFields"
    :processing="processing"
    title="Create and link new record"
  />
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import store from '@/specialist/store';

const emit = defineEmits(['change']);

const props = defineProps({
  caseId: {
    type: String,
    required: true,
  },
  caseType: {
    type: String,
    required: true,
  },
  linkedRecords: {
    type: Array,
    required: true,
  },
  schema: {
    type: Object,
    required: true,
  },
});

const dialog = ref(false);
const processing = ref(false);
const selectedType = ref(null);
const assessmentSchemas = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'Assessment',
);
const agreementSchemas = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'Agreement',
);
const backgroundCheckSchemas = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'BackgroundCheck',
);
const complaintSchemas = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'Complaint',
);
const formSchemas = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'Form',
);
const licenseSchemas = Object.values(store.state.schemas).filter(
  (schema) => schema.data_type === 'License',
);

const createFields = computed(() => {
  const selectTypeField = {
    items: getRecordTypes(),
    itemText: 'name',
    itemValue: 'value',
    text: 'Type',
    value: 'type',
    required: true,
    multiple: false,
  };
  const fields = [selectTypeField];

  let items;

  if (selectedType.value) {
    if (selectedType.value === 'Agreement') {
      items = agreementSchemas;
    } else if (selectedType.value === 'Assessment') {
      items = assessmentSchemas;
    } else if (selectedType.value === 'BackgroundCheck') {
      items = backgroundCheckSchemas;
    } else if (selectedType.value === 'Complaint') {
      items = complaintSchemas;
    } else if (selectedType.value === 'License') {
      items = licenseSchemas;
    } else if (selectedType.value === 'Form') {
      items = formSchemas;
    }

    const selectSchemaField = {
      items: items,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      search: true,
      text: 'Schema',
      value: 'schema_id',
    };

    fields.push(selectSchemaField);
  }

  return fields;
});

function getRecordTypes() {
  if (store.state.profile.enable_labs_feature_complaints_and_licenses === true) {
    return [
      { name: 'Agreement', value: 'Agreement' },
      { name: 'Assessment', value: 'Assessment' },
      { name: 'Background check', value: 'BackgroundCheck' },
      { name: 'Complaint', value: 'Complaint' },
      { name: 'Form', value: 'Form' },
      { name: 'License', value: 'License' },
    ];
  } else {
    return [
      { name: 'Agreement', value: 'Agreement' },
      { name: 'Assessment', value: 'Assessment' },
      { name: 'Form', value: 'Form' },
    ];
  }
}

function handleChange(value) {
  if (value[0] === 'type') {
    selectedType.value = value[1];
  }
}

async function handleSave(saveFields) {
  processing.value = true;
  let childRecordId;
  const linkedProvider = props.linkedRecords.find((record) => record.type === 'Provider');
  const linkedMember = props.linkedRecords.find((record) => record.type === 'Member');

  if (saveFields.type === 'Agreement') {
    const { data: agreementData } = await api.organization.agreement.create({
      owner_id: linkedProvider.id,
      owner_type: 'Provider',
      member_id: linkedMember?.id,
      schema_id: saveFields.schema_id,
    });
    childRecordId = agreementData.id;
  }

  if (saveFields.type === 'Assessment') {
    const { data: assessmentData } = await api.organization.assessment.create({
      provider_id: linkedProvider.id,
      schema_id: saveFields.schema_id,
    });
    childRecordId = assessmentData.id;
  }

  if (saveFields.type === 'BackgroundCheck') {
    const { data: backgroundCheckData } = await api.organization.backgroundCheck.create({
      member_id: linkedMember?.id,
      schema_id: saveFields.schema_id,
    });
    childRecordId = backgroundCheckData.id;
  }

  if (saveFields.type === 'Complaint') {
    const { data: complaintData } = await api.organization.complaint.create({
      provider_id: linkedProvider.id,
      schema_id: saveFields.schema_id,
    });
    childRecordId = complaintData.id;
  }

  if (saveFields.type === 'Form') {
    const { data: formData } = await api.organization.form.create({
      provider_id: linkedProvider.id,
      schema_id: saveFields.schema_id,
    });
    childRecordId = formData.id;
  }

  if (saveFields.type === 'License') {
    const { data: licenseData } = await api.organization.license.create({
      provider_id: linkedProvider.id,
      schema_id: saveFields.schema_id,
    });
    childRecordId = licenseData.id;
  }

  const resp = await api.organization.linkedRecord.create({
    record_id: props.caseId,
    record_type: props.caseType,
    child_record_id: childRecordId,
    child_record_type: saveFields.type,
  });
  processing.value = false;
  if (resp.status !== 201) return;

  emit('change');
  dialog.value.close();
}

function open() {
  selectedType.value = null;
  dialog.value.open();
}

defineExpose({ open });
</script>
