<template>
  <div v-if="schema">
    <BreadcrumbTitle
      :back-name="schema.name"
      name="Labels"
    />

    <v-card
      v-for="label in labels"
      :key="label.id"
      class="pa-4 mb-4"
      border
      flat
      tile
    >
      <v-row>
        <v-col>
          <v-menu
            location="left"
            max-height="300"
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="me-3"
                size="small"
                variant="text"
                icon
              >
                <v-avatar
                  :color="label.color"
                  data-testid="label-color"
                  size="20"
                />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="color in colorOptions"
                @click="changeColor(label, color)"
                :key="color"
                data-testid="color-option"
              >
                <template #prepend>
                  <v-avatar
                    :color="color"
                    size="20"
                  />
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-text="label.name" />
        </v-col>

        <v-col class="ta-right">
          <ActionMenu
            @click:action:delete="deleteLabel(label)"
            @click:action:edit="$refs.addEditLabelDialog.open(label)"
            :items="[
              { avatar: 'edit', title: 'Edit', event: 'edit' },
              { avatar: 'delete', title: 'Delete', event: 'delete' },
            ]"
            button-icon="more_vert"
          />
        </v-col>
      </v-row>
    </v-card>

    <template v-if="labels.length == 0">
      <NullState
        @new="$refs.addEditLabelDialog.open({})"
        new-button-title="New label"
      />
    </template>
    <template v-else>
      <v-btn
        @click="$refs.addEditLabelDialog.open({})"
        :disabled="processing"
        color="primary"
      >
        {{ $t('Add label') }}
      </v-btn>
    </template>

    <ResourceDialog
      @save="addEditLabel"
      ref="addEditLabelDialog"
      :fields="[
        { text: 'Name', value: 'name', required: true },
        { text: 'Color', value: 'color', required: true, type: 'color-hex' },
      ]"
      :processing="processing"
      title="Add label"
    />
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import colors from 'vuetify/util/colors';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

const CONFIRM_DELETE_MESSAGE =
  'Are you sure you want to remove this label? Applications that have been assigned this label will retain it until removed.';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
    BreadcrumbTitle,
    NullState,
    ResourceDialog,
  },

  mixins: [API],

  data() {
    return {
      changed: true,
      colorOptions: Object.values(colors).map((value) => value.lighten2),
      draftDialogIsVisible: false,
      labels: [],
      newOwnerId: null,
      newTitle: null,
      ownerItems: [],
      processing: false,
      schema: null,
      teams: [],
    };
  },

  created() {
    this.processing = true;
    this.load();
  },

  methods: {
    async load() {
      this.changed = false;
      await this.loadSchema();
      await this.loadLabels();
    },

    async loadSchema() {
      this.processing = true;
      const { data } = await this.api.organization.schema.get(this.$route.params.schemaId);
      this.schema = data;
      this.processing = false;
      this.changed = false;
    },

    async loadLabels() {
      const resp = await this.api.organization.label.index({ schema_id: this.schema.id });
      this.labels = resp.data;
      this.processing = false;
    },

    async addEditLabel(label) {
      this.processing = true;
      if (label.id) {
        await this.api.organization.label.update(label.id, label);
      } else {
        await this.api.organization.label.create({ schema_id: this.schema.id, label });
      }
      await this.loadLabels();
      this.$refs.addEditLabelDialog?.close();
    },

    changeColor(label, color) {
      // eslint-disable-next-line no-param-reassign
      this.addEditLabel({ ...label, color });
    },

    async deleteLabel(label) {
      // eslint-disable-next-line no-alert
      if (!confirm(CONFIRM_DELETE_MESSAGE)) return;

      this.processing = true;
      await this.api.organization.label.destroy(label.id);
      await this.loadLabels();
    },
  },
};
</script>
