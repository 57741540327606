import useApi from '@/shared/composables/useApi';
import { useStore } from 'vuex';

export function useSchemaFunctionChecks(schemaRef, recordRef) {
  const { api } = useApi();
  const store = useStore();
  const schemaFunctionChecks = ref([]);

  const loadSchemaFunctionChecks = async () => {
    if (!store.state.profile.org_settings_access) return;

    const response = await api.organization.schema_function.run({
      schema_id: schemaRef.value.id,
      record_id: recordRef.value.id,
      result_type: 'check',
    });
    if (response.status !== 201) return;

    schemaFunctionChecks.value = response.data;
  };

  const allSchemaFunctionChecksPassing = computed(() => {
    return schemaFunctionChecks.value.every((result) => result.passing);
  });

  return {
    schemaFunctionChecks,
    allSchemaFunctionChecksPassing,
    loadSchemaFunctionChecks,
  };
}
