<template>
  <div class="py-3">
    <v-data-table-server
      @click:row="handleRowClick"
      @update:model-value="handleModelValueUpdate"
      @update:page="handlePageChange"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="itemsPerPage"
      :loading="processing"
      :model-value="selected"
      :page="page"
      :pages="pages"
      :row-props="rowProps"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      no-data-text="No tasks found"
      select-strategy="page"
      fixed-header
      hover
      show-select
    >
      <template #item.related="{ item }">
        <div v-if="item.group_id || item.provider_id">
          <div
            v-text="'Related ' + (item.group_id ? terms.group : 'provider')"
            class="fs-10 tt-uppercase"
          />
          <router-link
            @click.stop.prevent
            :to="taskLink(item)"
          >
            {{ item.subtitle }}
          </router-link>
        </div>
      </template>

      <template #item.assigned="{ item }">
        <div v-if="item.owner_name">
          <div class="fs-10 tt-uppercase">
            {{ $t('Assigned to') }}
          </div>

          <div>
            {{ $t(item.owner_name) }}
          </div>
        </div>

        <div v-else>
          {{ $t('Unassigned') }}
        </div>
      </template>

      <template #item.dueDate="{ item }">
        <template v-if="item.due_date">
          <div class="fs-10 tt-uppercase">
            {{ $t('Due date') }}
          </div>

          <LongDate :date="item.due_date" />
        </template>
      </template>

      <template #item.created="{ item }">
        <div class="fs-10 tt-uppercase">
          {{ $t('Created') }}
        </div>

        <LongDate :date="item.created_at" />
      </template>
    </v-data-table-server>
  </div>
</template>

<script setup>
import LongDate from '@/shared/components/LongDate.vue';
import useTerms from '@/shared/composables/useTerms';

const { terms } = useTerms();

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  itemsPerPage: {
    type: Number,
    default: null,
  },
  page: {
    type: Number,
    default: null,
  },
  pages: {
    type: Number,
    default: null,
  },
  processing: {
    type: Boolean,
    default: null,
  },
  selected: {
    type: Array,
    default: () => [],
  },
  totalItemCount: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['open', 'pageChange', 'selected']);

const tableHeaders = computed(() => {
  return [
    {
      title: 'Name',
      value: 'title',
    },
    {
      title: `Provider/${terms.value.group}`,
      value: 'related',
    },
    {
      title: 'Assigned',
      value: 'assigned',
    },
    {
      title: 'Due date',
      value: 'dueDate',
    },
    {
      title: 'Created',
      value: 'created',
      align: 'end',
    },
  ];
});

function handlePageChange(page) {
  emit('pageChange', page);
}

function handleRowClick(_event, { item }) {
  emit('open', item);
}

function handleModelValueUpdate(task) {
  emit('selected', task);
}

function taskLink(task) {
  if (task.group_id) {
    return {
      name: 'GroupShow',
      params: {
        groupId: task.group_id,
      },
      query: {
        tab: 'profile',
      },
    };
  }

  if (task.provider_id) {
    return {
      name: 'ProviderShow',
      params: {
        providerId: task.provider_id,
      },
      query: {
        tab: 'profile',
      },
    };
  }

  return null;
}

function rowProps({ item }) {
  return {
    class: item.read ? 'bg-white-smoke' : 'bg-white',
  };
}
</script>
