<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-text>
      <v-row role="row">
        <TableCell
          :content="group.name"
          :to="{ name: 'GroupShow', params: { groupId: group.id } }"
          cols="3"
          target="_self"
        />
        <TableCell
          :content="group.primary_email"
          cols="3"
        />
        <TableCell
          :content="group.primary_phone"
          cols="2"
        />
        <TableCell
          :content="location"
          cols="3"
        />
        <TableCell
          :content="group.created_at"
          cols="1"
          transform="date"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
const props = defineProps({
  group: {
    type: Object,
    default: null,
  },
});

const location = computed(() => {
  return [
    props.group.home_address1,
    props.group.home_city,
    props.group.home_zip,
    props.group.home_county,
    props.group.home_state,
  ]
    .filter((el) => !!el)
    .join(', ');
});
</script>
