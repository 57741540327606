<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-card-title class="tab-title">
      <v-row>
        <v-col
          class="d-flex align-center"
          cols="4"
        >
          {{ $t(title) }}
        </v-col>
        <v-col>
          <div class="d-flex justify-end">
            <v-select
              @update:model-value="updateQuery({ subsidyProgramId: $event })"
              :items="subsidyPrograms"
              :model-value="$route.query.subsidyProgramId"
              class="me-3 mxw-350"
              density="compact"
              item-title="name"
              item-value="id"
              variant="filled"
              hide-details
            />
            <v-text-field
              v-model="query"
              :placeholder="$t('Search students')"
              class="mxw-250"
              density="compact"
              prepend-inner-icon="search"
              variant="solo-filled"
              flat
              hide-details
              rounded
            />
          </div>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text v-if="enrollments">
      <v-row class="d-flex align-center mb-2">
        <v-col
          class="d-flex align-center"
          cols="12"
          md="6"
        >
          <template v-if="pages > 1">
            <v-pagination
              v-model="page"
              :length="pages"
              :total-visible="8"
              class="d-inline-block"
            />
          </template>
        </v-col>

        <v-col
          class="d-flex justify-end px-6"
          cols="12"
          md="6"
        >
          <template v-if="filterPrograms.length > 0">
            <FilterMenu
              ref="programFilter"
              :actions="false"
              :active="!!programFilter"
              :title="programFilter || terms.program"
              classes="ms-3"
              color="white"
              elevation="0"
              min-width="400"
              left
              outlined
            >
              <template #card>
                <v-select
                  @update:model-value="updateQuery({ programId: $event })"
                  :items="filterPrograms"
                  :model-value="$route.query.programId"
                  :placeholder="$t('Search by name or address')"
                  item-title="name"
                  item-value="id"
                  variant="filled"
                  auto-select-first
                  clearable
                  left
                />
              </template>
            </FilterMenu>
          </template>
        </v-col>
      </v-row>

      <v-card
        border
        flat
        tile
      >
        <template v-if="$vuetify.display.smAndUp">
          <v-card
            class="bb-1 pa-0"
            flat
            tile
          >
            <v-card-text>
              <v-row>
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="name"
                  md="3"
                  title="Child's name"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="dob"
                  md="2"
                  title="DOB"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="address"
                  md="2"
                  title="Home address"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  field="provider"
                  md="3"
                  title="Location"
                />
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="sortDirection"
                  :sort-field="sortField"
                  :title="terms.program"
                  field="program"
                  md="2"
                />
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider />
        </template>

        <v-card
          flat
          tile
        >
          <v-card-text class="fs-15">
            <v-row dense>
              <v-col class="d-flex align-center">
                <template v-if="filterSummary">
                  <v-icon
                    icon="filter_alt"
                    start
                  />
                  <span class="me-1"> {{ $t('Filtering by') }}: </span>
                  <span class="fw-600">{{ $t(filterSummary) }}</span>
                  <span>.&nbsp;</span>
                </template>
                <span>
                  {{ $t('Displaying first') }} <strong>{{ enrollments.length }}</strong>
                  {{ $t('results') }}, {{ $t('out of ') }} <strong>{{ total }}</strong>
                  {{ $t('total results') }}.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider />

        <NullState v-if="enrollments.length == 0" />

        <div
          v-for="(enrollment, index) in enrollments"
          :key="enrollment.id"
        >
          <EnrollmentRow
            @check="enrollment.checked = !enrollment.checked"
            :enrollment="enrollment"
            hide-selector
          />
          <v-divider v-if="index < enrollments.length - 1" />
        </div>

        <template v-if="pages > 1">
          <v-divider />
          <v-pagination
            v-model="page"
            :length="pages"
            :total-visible="8"
            class="my-2"
          />
        </template>
      </v-card>
    </v-card-text>

    <v-progress-linear
      v-else
      class="mt-6"
      indeterminate
    />
  </v-card>
</template>

<script setup>
import { scrollTo } from 'vuetify/lib/composables/goto';
import Api from '@/specialist/services/bright_finder';
import EnrollmentRow from '@/specialist/components/enrollments/EnrollmentRow.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';

import useRouterHelper from '@/shared/composables/useRouterHelper';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import useTerms from '@/shared/composables/useTerms';

const { updateQuery } = useRouterHelper();
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const { terms } = useTerms();

defineProps({
  title: {
    type: String,
    default: null,
  },
});

const enrollments = ref(null);
const filterPrograms = ref([]);
const page = ref(1);
const pages = ref(null);
const processing = ref(false);
const query = ref(null);
const queryCount = ref(0);
const sortDirection = ref(null);
const sortField = ref(null);
const subsidyPrograms = ref([]);
const total = ref(0);

const filterSummary = computed(() => {
  const appliedFilters = [];
  if (programFilter.value) appliedFilters.push(programFilter.value);
  if (subsidyProgramFilter.value) appliedFilters.push(subsidyProgramFilter.value);
  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
});

const programFilter = computed(() => {
  return filterPrograms.value.find((program) => program.id === route.query.programId)?.name;
});

const subsidyProgramFilter = computed(() => {
  return subsidyPrograms.value.find(
    (subsidyProgram) => subsidyProgram.id === route.query.subsidyProgramId,
  )?.name;
});

watch(
  () => page.value,
  () => {
    loadEnrollments();
    scrollTo(0);
  },
  { immediate: true },
);

watch(
  () => query.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      page.value = 1;
      loadEnrollments();
    }
  },
  { immediate: true },
);

watch(
  () => [route.query.programId, route.query.subsidyProgramId],
  () => {
    page.value = 1;
    loadEnrollments();
  },
  { immediate: true },
);

onMounted(() => {
  void load();
  loadPrograms();
});

function loadEnrollments() {
  const params = {
    status: ENROLLMENT_STATUSES.ENROLLED,
    page: page.value,
    query: query.value,
    provider_id: route.params.providerId,
    program_id: route.query.programId,
    subsidy_program_id: route.query.subsidyProgramId,
  };
  queryCount.value += 1;
  const currentQueryCount = queryCount.value;

  if (sortField.value) {
    params.sort_field = sortField.value;
    params.sort_dir = sortDirection.value;
    router.push({ query: params });
  }

  Api.organization.enrollment.index(
    params,
    (resp) => {
      if (queryCount.value !== currentQueryCount) return;

      enrollments.value = resp.data.map((enrollment) => {
        enrollment.checked = false;
        return enrollment;
      });

      pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
      total.value = parseInt(resp.headers['x-total-count'] || 0, 10);
      processing.value = false;
    },
    (err) => {
      eventBus.error(err);
    },
  );
}

function loadPrograms() {
  Api.public_api.organization.program.index({ provider_id: route.params.providerId }, (resp) => {
    filterPrograms.value = resp.data;
  });
}

async function load() {
  const resp = await Api.organization.subsidy_program.index();
  subsidyPrograms.value = resp.data;
  if (route.query.subsidyProgramId) {
    loadEnrollments();
  } else {
    await updateQuery({ subsidyProgramId: subsidyPrograms.value[0]?.id });
  }
}

function toggleSort(field) {
  if (sortField.value !== field) {
    sortDirection.value = 'desc';
  } else {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  }
  sortField.value = field;
  loadEnrollments();
}
</script>
