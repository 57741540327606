<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      title="Funding"
      outlined
    />

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-row>
        <v-col>
          <v-card
            class="bg-transparent"
            flat
          >
            <v-card-text>
              <v-card
                class="b-1 bc-outlined-gray"
                elevation="0"
              >
                <v-card-text>
                  <v-row class="d-flex align-center">
                    <v-col cols="6">
                      <div class="fs-14 c-light-black">
                        {{ $t('Customer ID') }}
                      </div>
                      <div
                        v-text="bankAccount?.dwolla_customer_id || '-'"
                        class="fs-15"
                      />
                    </v-col>
                    <v-col cols="6">
                      <div class="fs-14 c-light-black">
                        {{ $t('Funding Source ID') }}
                      </div>
                      <div
                        v-text="bankAccount?.dwolla_funding_source_id || '-'"
                        class="fs-15"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text>
              <v-card
                class="b-1 bc-outlined-gray"
                elevation="0"
              >
                <v-card-title>
                  <h3 class="fs-22 fw-500">
                    {{ $t('Bank account') }}
                  </h3>
                </v-card-title>

                <v-card-text>
                  <template v-if="exists">
                    <v-row class="d-flex align-center">
                      <v-col>
                        <div class="fs-14 c-light-black">
                          {{ $t('Name') }}
                        </div>
                        <div
                          v-text="bankAccount?.bank_account_name || '-'"
                          class="fs-15"
                        />
                      </v-col>
                      <v-col>
                        <div class="fs-14 c-light-black">
                          {{ $t('Type') }}
                        </div>
                        <div
                          v-text="bankAccount?.bank_account_type || '-'"
                          class="fs-15"
                        />
                      </v-col>
                      <v-col>
                        <div class="fs-14 c-light-black">
                          {{ $t('Routing number') }}
                        </div>
                        <div
                          v-text="bankAccount?.bank_routing_number || '-'"
                          class="fs-15"
                        />
                      </v-col>
                      <v-col>
                        <div class="fs-14 c-light-black">
                          {{ $t('Account number') }}
                        </div>
                        <div
                          v-text="bankAccount?.bank_account_number?.padStart(10, '•') || '-'"
                          class="fs-15"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <div
                    v-else
                    class="fs-16"
                  >
                    {{ $t('No bank account present.') }}
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="exists"
                @click="destroy"
                class="me-6"
                color="red"
                size="large"
                variant="text"
              >
                {{ $t('Remove') }}
              </v-btn>
              <v-btn
                @click="draft"
                @save="update"
                :loading="processing"
                color="primary"
                size="large"
              >
                {{ exists ? $t('Update') : $t('Add') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <ResourceDialog
        @save="update"
        ref="bankAccountDialog"
        :consent-label="onDemandAuth.buttonText"
        :consent-text="onDemandAuth.bodyText"
        :fields="bankAccountFields"
        :processing="processing"
        :title="exists ? 'Update bank account' : 'Add bank account'"
        fullscreen
      />

      <ConfirmDialog ref="confirmDialog" />
    </v-container>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const bankAccountFields = [
  { text: 'Account name', value: 'bank_account_name' },
  { text: 'Account number', value: 'bank_account_number' },
  { text: 'Routing number', value: 'bank_routing_number' },
  {
    text: 'Account type',
    value: 'bank_account_type',
    items: ['Checking', 'Savings'],
    placeholder: 'Checking or Savings',
  },
];

const bankAccount = ref(null);
const bankAccountDialog = ref(null);
const confirmDialog = ref(null);
const onDemandAuth = ref({});
const processing = ref(false);

const exists = computed(() => {
  return (
    bankAccount.value &&
    bankAccount.value?.dwolla_customer_id &&
    (bankAccount.value.bank_account_name ||
      bankAccount.value.bank_account_number ||
      bankAccount.value.bank_routing_number ||
      bankAccount.value.bank_account_type)
  );
});

onMounted(load);

async function load() {
  processing.value = true;

  const response = await Api.organization.bankAccount.get();
  processing.value = false;
  if (response.status !== 200) return;

  bankAccount.value = response.data;
}

async function draft() {
  processing.value = true;

  const response = await Api.organization.dwolla.onDemandAuthorization.create();

  onDemandAuth.value = response.data;
  processing.value = false;
  bankAccountDialog.value?.open(bankAccount.value);
}

async function update(account) {
  processing.value = true;
  if (onDemandAuth.value._links) {
    account.on_demand_authorization_link = onDemandAuth.value._links.self.href;
  }
  const response = await Api.organization.bankAccount.update(account);

  bankAccount.value = response.data;
  processing.value = false;
  eventBus.chime('Bank account updated successfully');
  bankAccountDialog.value?.close();
}

async function destroy() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to disconnect this bank account?',
  );
  if (!confirm) return;

  void update({
    bank_account_name: null,
    bank_account_number: null,
    bank_routing_number: null,
    bank_account_type: null,
  });
}
</script>
