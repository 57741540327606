<template>
  <div>
    <v-card
      class="fill-height"
      elevation="2"
      tile
    >
      <v-card-title class="d-flex bb-1 bc-very-light-grey fw-600 pa-5">
        <span tabindex="0">Reviews</span>

        <v-spacer />

        <v-btn
          @click="$emit('close')"
          aria-label="Close Review"
          class="focus-visible"
          size="small"
          variant="text"
          icon
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div
          v-show="reviews?.length == 0"
          v-t="'No reviews submitted yet.'"
          class="fs-16 pa-6"
          tabindex="0"
        />
        <v-list
          v-if="reviews?.length > 0"
          class="mb-3"
        >
          <template
            v-for="(review, index) in reviews"
            :key="review.id"
          >
            <v-divider v-show="index != 0" />

            <v-list-item
              @click="editReview(review, index)"
              class="my-4"
            >
              <v-row>
                <v-col
                  class="d-flex align-center"
                  cols="10"
                >
                  <span class="fs-20 fw-600">
                    {{ reviewSchemas.find((schema) => schema.id == review.schema_id)?.name }}
                  </span>

                  <v-chip
                    :color="getReviewColor(review.published, review.status)"
                    :loading="processing"
                    class="ms-2"
                    size="small"
                    rounded
                  >
                    {{ review.published ? review.status : 'Draft' }}
                  </v-chip>
                </v-col>

                <v-col
                  class="d-flex justify-end"
                  cols="2"
                >
                  <v-icon data-testid="expand-more">
                    {{ review.expanded ? 'expand_less' : 'expand_more' }}
                  </v-icon>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <span v-if="review.member_name">{{ review.member_name }} |</span>
                  <LongDate :date="review.created_at" />
                </v-col>
              </v-row>
            </v-list-item>

            <div
              v-if="review.expanded"
              class="pa-4"
            >
              <QuestionSet
                v-model="reviews[index]"
                :elevation="0"
                :processing="processing"
                :questions="review.questions"
                :schema="review.schema.definition"
                expanded
                flat
                hide-actions
                outlined-sections
                paddingless
                very-dense
              />

              <div
                v-for="question in review.verificationQuestions"
                :key="question.id"
                class="mb-6"
              >
                <FormQuestion
                  :color="null"
                  :elevation="0"
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  hide-actions
                  paddingless
                  tile
                  very-dense
                >
                  <AttachmentUploader
                    @uploaded="attachments.push($event)"
                    :ref="['uploader', question.id, owner.id].join('')"
                    :owner="getAttachmentOwner(question)"
                    class="mb-4"
                    dense
                  />
                  <AttachmentList
                    @delete="loadAttachments"
                    :attachments="
                      attachments.filter(
                        (attachment) =>
                          attachment.tag == ['review', owner.id, question.id].join('-'),
                      )
                    "
                    dense
                  />
                </FormQuestion>
              </div>

              <v-row
                class="mb-4"
                dense
              >
                <LabeledTextarea
                  v-model="review.note"
                  message="Optional note"
                  variant="filled"
                />

                <LabeledSimpleSelect
                  v-if="isSubmitWithoutStatusChangeDisabled(review)"
                  v-model="review.status"
                  :items="statuses"
                  active-class="bg-primary c-white"
                  message="Update status"
                  tile
                />
              </v-row>

              <div class="d-flex mb-6">
                <v-btn
                  @click="destroyReview(review)"
                  class="mr-2 me-auto"
                  color="red"
                  min-width="40"
                  variant="text"
                  icon
                >
                  <v-icon>delete_outlined</v-icon>
                </v-btn>
                <v-btn
                  @click="saveReview(review)"
                  :disabled="isSaveButtonDisabled(review)"
                  :loading="processing"
                  class="ml-2"
                  color="primary"
                >
                  {{ $t('Save') }}
                </v-btn>
                <v-btn
                  @click="publishReview(review)"
                  :disabled="isSaveButtonDisabled(review)"
                  :loading="processing"
                  class="ml-2"
                  color="primary"
                >
                  {{ $t('Save and publish') }}
                </v-btn>
              </div>
            </div>
          </template>
        </v-list>
        <v-row v-show="!anyExpanded">
          <v-col class="d-flex justify-end">
            <v-btn
              @click="$refs.draftDialog.open()"
              color="primary"
            >
              <v-icon
                class="me-1"
                size="16"
              >
                add
              </v-icon>
              Add review
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ResourceDialog
      @save="createDraft"
      ref="draftDialog"
      :fields="draftFields"
      save-button-text="Draft"
      title="Draft review"
    />
  </div>
</template>

<script setup>
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import Api from '@/specialist/services/bright_finder';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LinkedList from '@/shared/services/linked-list';
import LongDate from '@/shared/components/LongDate.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import {
  STATIC_STATUSES,
  NEUTRAL_STATUSES,
  POSITIVE_STATUSES,
} from '@/specialist/services/statuses';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import { useStore } from 'vuex';

defineEmits(['close']);

const props = defineProps({
  associatedSchemaIds: {
    required: true,
    type: Array,
  },
  owner: {
    required: true,
    type: Object,
  },
  ownerType: {
    required: true,
    type: String,
  },
  statuses: {
    default() {
      return STATIC_STATUSES.concat(NEUTRAL_STATUSES, POSITIVE_STATUSES);
    },
    type: Array,
  },
});

const store = useStore();

const attachments = ref([]);
const draftDialog = ref(null);
const processing = ref(false);
const reviews = ref([]);

const anyExpanded = computed(() => reviews.value.some((review) => review.expanded));

const reviewSchemas = computed(() => {
  return Object.values(store.state.schemas)
    .filter(
      (schemaObject) =>
        schemaObject.data_type === 'Review' && props.associatedSchemaIds.includes(schemaObject.id),
    )
    .sort((a, b) => (a.name < b.name ? -1 : 1));
});

const draftFields = computed(() => {
  return [
    {
      text: 'Select review type',
      value: 'schema_id',
      items: reviewSchemas.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
  ];
});

async function createDraft(draft) {
  processing.value = true;

  const options = {
    schema_id: store.state.schemas[draft.schema_id].id,
    owner_id: props.owner.id,
    owner_type: props.ownerType,
  };

  try {
    await Api.organization.review.create(options);
  } finally {
    processing.value = false;
    draftDialog.value?.close();
    await loadReviews();
  }
}

async function destroyReview(draftReview) {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to remove this review?')) return;

  processing.value = true;

  try {
    await Api.organization.review.destroy(draftReview.id);
  } finally {
    await loadReviews();
    processing.value = false;
  }
}

async function editReview(review, index) {
  const selectedReview = { ...review, expanded: !review.expanded };
  reviews.value[index] = selectedReview;
}

async function loadAttachments() {
  const params = {
    owner_id: props.owner.id,
    owner_type: props.ownerType,
  };
  const resp = await Api.organization.attachment.index(params);
  if (resp?.status !== 200) return;

  attachments.value = resp.data;
  processing.value = false;
}

async function loadReviewQuestions(reviews) {
  // Create a unique collection of schema ids
  const schemasArray = [...new Set(reviews.map((review) => review.schema_id))];

  return Api.public_api.organization.question.index({
    owner_id: schemasArray,
    owner_type: 'Schema',
  });
}

async function loadReviews() {
  const reviewsResponse = await Api.organization.review.index({ owner_id: props.owner.id });

  const questionsRes = await loadReviewQuestions(reviewsResponse.data);

  const reviewsData = reviewsResponse.data.map((review) => {
    const schema = store.state.schemas[review.schema_id];
    const questionsBySchema = questionsRes.data.filter(
      (question) => question.owner_id === review.schema_id,
    );
    const sortedQuestions = LinkedList.sort(questionsBySchema);
    const verificationQuestions = sortedQuestions.filter((question) => question.verification);

    return {
      ...review,
      schema,
      questions: sortedQuestions,
      verificationQuestions,
      expanded: false,
    };
  });

  reviews.value = reviewsData;
}

async function publishReview(draftReview) {
  processing.value = true;

  try {
    await Api.organization.review.update(draftReview.id, {
      custom: draftReview.custom,
      note: draftReview.note,
      owner_id: props.owner.id,
      owner_type: props.ownerType,
      published: true,
      status: draftReview.status,
    });
    await loadReviews();
  } finally {
    processing.value = false;
  }
}

async function saveReview(draftReview) {
  processing.value = true;

  await Api.organization.review.update(draftReview.id, {
    custom: draftReview.custom,
    note: draftReview.note,
    owner_id: props.owner.id,
    owner_type: props.ownerType,
    status: draftReview.status,
  });
  processing.value = false;
}

function getReviewColor(published, status) {
  if (!published) return 'grey';
  if (NEUTRAL_STATUSES.includes(status)) return 'grey';
  if (POSITIVE_STATUSES.includes(status)) return 'green';

  return 'red';
}

function getAttachmentOwner(question) {
  return {
    type: props.ownerType,
    id: props.owner.id,
    tag: ['review', props.owner.id, question.id].join('-'),
    tags: ['review', props.owner.id, question.id],
  };
}

function isSubmitWithoutStatusChangeDisabled(review) {
  return !review.schema.meta.enable_review_submission_without_status_change;
}

function isSaveButtonDisabled(review) {
  return isSubmitWithoutStatusChangeDisabled(review) && !review.status;
}

watch(
  () => props.ownerId,
  async () => {
    await loadReviews();
    await loadAttachments();
  },
  { immediate: true },
);
</script>
