<template>
  <v-window-item value="awards">
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="subsidyProgram.enable_awards"
            @input="changed = true"
            subtitle="Automatically calculate family-level and child-level recommended award amounts."
            title="Award calculation"
          />
        </v-row>

        <template v-if="subsidyProgram.enable_awards">
          <v-row>
            <LabeledTextarea
              v-model="subsidyProgram.child_award_formula_description"
              @input="changed = true"
              class="mb-4"
              message="Award calculation description"
            />
            <LabeledSimpleSelect
              v-model="subsidyProgram.child_award_payment_period"
              :items="['Monthly']"
              message="Payment period"
            />
            <LabeledFormulaField
              v-model="subsidyProgram.tuition_payment_formula"
              @input="changed = true"
              message="Tuition payment formula"
            />
            <LabeledFormulaField
              v-model="subsidyProgram.start_date_formula"
              @input="changed = true"
              message="Award start date formula"
            />
            <LabeledFormulaField
              v-model="subsidyProgram.finish_date_formula"
              @input="changed = true"
              message="Award finish date formula"
            />
            <LabeledFormulaField
              v-model="subsidyProgram.child_award_payment_formula"
              @input="changed = true"
              message="Award payment formula"
            />
            <LabeledFormulaField
              v-model="subsidyProgram.child_award_text_formula"
              @input="changed = true"
              message="Award payment description formula"
            />
          </v-row>

          <v-divider class="my-6" />

          <v-row>
            <LabeledSwitch
              v-model="subsidyProgram.enable_copay"
              @input="changed = true"
              subtitle="Enable calculation of parent co-payment."
              title="Copay"
            />
            <LabeledFormulaField
              v-if="subsidyProgram.enable_copay"
              v-model="subsidyProgram.child_copay_payment_formula"
              @input="changed = true"
            />
          </v-row>

          <v-divider class="my-6" />

          <v-row>
            <LabeledSwitch
              v-model="subsidyProgram.enable_max_group_award"
              @input="changed = true"
              subtitle="Enable max family total award."
              title="Max family award"
            />
            <LabeledFormulaField
              v-if="subsidyProgram.enable_max_group_award"
              v-model="subsidyProgram.max_group_award_formula"
              @input="changed = true"
            />
          </v-row>

          <v-divider class="my-6" />

          <v-row>
            <LabeledSwitch
              v-model="subsidyProgram.enable_max_child_award"
              @input="changed = true"
              subtitle="Enable max child total award."
              title="Max child award"
            />
            <LabeledFormulaField
              v-if="subsidyProgram.enable_max_child_award"
              v-model="subsidyProgram.max_child_award_formula"
              @input="changed = true"
            />
          </v-row>
        </template>
      </v-card-text>
    </v-card>

    <SaveBar
      @cancel="cancelChange"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-window-item>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledFormulaField from '@/shared/components/form/LabeledFormulaField.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const subsidyProgram = defineModel('subsidyProgram', {
  type: Object,
  default: null,
});

const props = defineProps({
  loadSubsidyProgram: {
    type: Function,
    required: true,
  },
});

const eventBus = useEventBus();
const route = useRoute();

const changed = ref(false);
const processing = ref(false);

async function cancelChange() {
  changed.value = false;
  await props.loadSubsidyProgram();
}

async function save() {
  processing.value = true;

  const resp = await Api.organization.subsidy_program.update(
    route.params.subsidyProgramId,
    subsidyProgram.value,
  );

  if (resp.data.errors) {
    eventBus.chime(resp.data.errors[0]);
  } else {
    subsidyProgram.value = resp.data;
    changed.value = false;
  }

  processing.value = false;
}
</script>
