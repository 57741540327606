<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      title="My profile"
      outlined
    />

    <div class="ma-3">
      <v-card
        class="mb-3"
        border
        tile
      >
        <v-card-title>
          {{ $t('Contact information') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="profile.first_name"
              cols="6"
              message="First name"
            />

            <LabeledTextfield
              v-model="profile.last_name"
              cols="6"
              message="Last name"
            />

            <LabeledTextfield
              v-model="profile.email"
              cols="6"
              message="Email"
            />

            <LabeledTextfield
              v-model="profile.phone"
              cols="6"
              message="Business phone"
            />
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            @click="updateProfile"
            :loading="processing"
            color="primary"
          >
            {{ $t('Update info') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-show="showPasswordSettings"
        class="mb-3"
        border
        tile
      >
        <v-card-title>
          {{ $t('Password') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="newPassword"
              cols="6"
              message="New password"
              type="password"
            />

            <LabeledTextfield
              v-model="newPasswordConfirmation"
              cols="6"
              message="Confirm new password"
              type="password"
            />
          </v-row>

          <v-row>
            <p class="ma-3">
              {{
                $t(
                  'The password must be 8 characters with 3 out of 4 of the following: Lowercase characters, Uppercase characters, Numbers, Symbols.',
                )
              }}
            </p>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            @click="updatePassword"
            :loading="processing"
            color="primary"
          >
            {{ $t('Update password') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <MFASettingsCard
        v-show="showMFASettings"
        @change="load"
        :profile="profile"
      />
    </div>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import MFASettingsCard from '@/shared/components/MFASettingsCard.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import useEventBus from '@/shared/composables/useEventBus';
import usePasswordValidation from '@/shared/composables/usePasswordValidation';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();
const { isValidPassword } = usePasswordValidation();

const newPassword = ref(null);
const newPasswordConfirmation = ref(null);
const processing = ref(false);
const profile = reactive({});

const showMFASettings = computed(() => store.state.config.enable_mfa_authentication);
const showPasswordSettings = computed(() => store.state.config.enable_password_authentication);

function load() {
  Api.organization.profile.get((response) => {
    Object.assign(profile, response?.data);
  });
}

async function updatePassword() {
  if (!newPassword.value || !newPasswordConfirmation.value) {
    return eventBus.error('Password is required to continue');
  }

  if (newPassword.value !== newPasswordConfirmation.value) {
    return eventBus.error('Passwords must match to continue');
  }

  if (!isValidPassword(newPassword.value, newPasswordConfirmation.value)) {
    return eventBus.error('Password is not valid');
  }

  processing.value = true;

  const id = null;
  const response = await Api.organization.profile.update(id, { password: newPassword.value });

  processing.value = false;
  if (response.status !== 200) return;

  Object.assign(profile, response?.data);
  eventBus.chime('Saved');
  processing.value = false;
}

async function updateProfile() {
  processing.value = true;

  const id = null;
  const response = await Api.organization.profile.update(id, profile);

  processing.value = false;
  if (response.status !== 200) return;

  Object.assign(profile, response?.data);
  eventBus.chime('Saved');
}

onMounted(load);
</script>
