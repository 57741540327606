<template>
  <div v-if="loaded">
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      name="Funding"
    />

    <v-tabs
      v-model="tab"
      bg-color="transparent"
      class="mb-2"
      grow
    >
      <v-tab value="funding-sources"> Subsidy funding sources </v-tab>
      <v-tab value="provider-program-requirements"> Provider program requirements </v-tab>
      <v-tab value="awards"> Awards </v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <div class="my-4" />

      <SubsidyFundingSourcesTab :schemas="schemas" />

      <SubsidyProviderProgramRequirementsTab :schemas="requirementSchemas" />

      <SubsidyAwardsTab
        v-model:subsidy-program="subsidyProgram"
        :load-subsidy-program="loadSubsidyProgram"
      />
    </v-window>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import SubsidyAwardsTab from '@/admin/components/child-subsidy-programs/SubsidyAwardsTab.vue';
import SubsidyFundingSourcesTab from '@/admin/components/child-subsidy-programs/SubsidyFundingSourcesTab.vue';
import SubsidyProviderProgramRequirementsTab from '@/admin/components/child-subsidy-programs/SubsidyProviderProgramRequirementsTab.vue';

import useSubsidy from '@/shared/composables/useSubsidy';
import { useRoute } from 'vue-router';

const route = useRoute();

const loaded = ref(false);
const subsidyProgram = ref(null);
const tab = ref(route.query.tab);

const { groupSchema, childSchema, providerSchema, loadSchemas } = useSubsidy({
  subsidyProgram: subsidyProgram,
});

const schemas = computed(() =>
  [groupSchema.value, childSchema.value, providerSchema.value].filter((schema) => !!schema),
);

const requirementSchemas = computed(() =>
  [groupSchema.value, childSchema.value].filter((schema) => !!schema),
);

const subsidyProgramId = computed(() => route.params.subsidyProgramId);

async function load() {
  await loadSubsidyProgram();
  loaded.value = true;
}

async function loadSubsidyProgram() {
  const resp = await Api.organization.subsidy_program.get(subsidyProgramId.value);

  if (resp.data) {
    subsidyProgram.value = resp.data;
    loadSchemas();
  }
}

onMounted(async () => {
  if (!tab.value) tab.value = 'funding-sources';

  await load();
});
</script>
