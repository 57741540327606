<template>
  <v-card
    class="mb-4"
    border
    tile
  >
    <v-card-title class="fs-20 fw-600">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          class="fs-22 fw-600 ps-7"
          role="heading"
        >
          {{ $t('Enrollment') }}
        </v-col>
        <v-col class="ta-right">
          <template v-if="canCreate">
            <v-btn
              @click="validateMaximumEnrollments"
              :loading="processing"
              color="primary"
              data-cy="create-enrollment-request-button"
              data-testid="create-enrollment-request-button"
              prepend-icon="add"
              variant="flat"
            >
              {{ $t('Add') }}
            </v-btn>

            <v-btn
              v-if="$store.state.profile.org_enrollments_access"
              :to="{
                name: 'ProviderSearch',
                query: {
                  subsidy_id: subsidy.id,
                  group_id: subsidy.group_id,
                },
              }"
              class="ms-3"
              color="secondary"
              prepend-icon="search"
              variant="flat"
            >
              {{ $t('Explore') }}
            </v-btn>
          </template>
          <template v-if="canPublish">
            <v-btn
              v-show="publishable"
              @click="publish"
              class="ms-3"
              color="primary"
              variant="flat"
            >
              {{ $t('Publish') }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-3" />

    <v-card-text>
      <div
        v-if="enrollments.length > 0"
        class="mb-6"
        data-testid="subsidy-enrollments"
      >
        <v-row>
          <v-col cols="12">
            <EnrollmentCard
              v-for="enrollment in enrollments"
              @change="reload"
              @change:destroy="handleEnrollmentDestroy"
              @move="reorder(enrollment, $event)"
              :key="enrollment.id"
              :enrollment="enrollment"
              :funding-sources="fundingSources"
              :index="enrollment.preference_order - 1"
              :length="enrollments.length"
              :ordered="subsidyProgram.allow_preference_order"
              :processing="processing"
              :readonly="readonly"
              :subsidy="subsidy"
              :subsidy-program="subsidyProgram"
              class="mb-4"
              data-testid="enrollment-card"
              outlined
            />
          </v-col>
        </v-row>
      </div>
      <div
        v-if="displaySavedProviders"
        class="mb-6"
      >
        <div class="fs-18 fw-600 d-flex align-center">
          <v-icon
            class="material-icons-outlined"
            color="black"
            icon="add_circle_outline"
            size="22"
            start
          />
          {{ $t('Saved providers') }}
        </div>
        <v-divider class="mb-4 mt-2" />

        <SavedProvider
          v-for="provider_id in subsidyProviderSelections"
          @reservation="createEnrollment($event[0], $event[1], $event[2])"
          :key="provider_id"
          :processing="processing"
          :provider-id="provider_id"
          :readonly="readonly"
          class="my-4"
        />

        <SavedProvider
          v-for="favorite in favoriteProviderSelections"
          @reservation="createEnrollment($event[0], $event[1], $event[2])"
          :key="favorite.id"
          :processing="processing"
          :provider-id="favorite.provider_id"
          :readonly="readonly"
          class="my-4"
        />
      </div>

      <template v-if="showIepProgramId">
        <v-card
          border
          flat
          tile
        >
          <v-card-title class="fs-18 fw-600 d-flex align-center">
            {{ $t('Is there an IEP program ID?') }}'
          </v-card-title>

          <v-divider />

          <v-card-text>
            <LabeledTextfield
              v-model="iepReferralProgramId"
              @input="changed = true"
              :aria-label="$t('Enter an IEP Referred Program ID')"
              :readonly="!canEditReferrals"
              cols="12"
              data-testid="iep-program-input"
            />
          </v-card-text>
        </v-card>
      </template>

      <template v-if="subsidyProgram.allow_enrolled">
        <v-card
          border
          flat
          tile
        >
          <v-card-title class="fs-18 fw-600 d-flex align-center">
            {{ $t(subsidyProgram.current_provider_title) }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <LabeledSimpleSelect
              v-model="subsidy.current_provider_id"
              @update:model-value="
                (current_provider_id) => saveCurrentSelectedIds({ current_provider_id })
              "
              :hard-lock="$store.state.profile.org_subsidies_agent && subsidy.locked"
              :items="allProviders"
              data-testid="current-provider-select"
              item-title="name"
              item-value="id"
              menu-test-id="current-provider-select-menu"
              clearable
              hide-details
            />
          </v-card-text>
        </v-card>
      </template>

      <template v-if="subsidyProgram.enable_sibling_provider_selection">
        <v-card
          border
          flat
          tile
        >
          <v-card-title class="fs-18 fw-600 d-flex align-center">
            {{ $t(subsidyProgram.sibling_provider_title) }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <LabeledSimpleSelect
              v-model="subsidy.sibling_provider_ids"
              @update:model-value="
                (sibling_provider_ids) => saveCurrentSelectedIds({ sibling_provider_ids })
              "
              :hard-lock="$store.state.profile.org_subsidies_agent && subsidy.locked"
              :items="allProviders"
              data-testid="sibling-provider-select"
              item-title="name"
              item-value="id"
              clearable
              multiple
            />
          </v-card-text>
        </v-card>
      </template>

      <template v-if="subsidyProgram.enable_staff_provider_selection">
        <v-card
          border
          flat
          tile
        >
          <v-card-title class="fs-18 fw-600 d-flex align-center">
            {{ $t(subsidyProgram.staff_provider_title) }}
          </v-card-title>

          <v-divider />

          <v-card-text>
            <LabeledSimpleSelect
              v-model="subsidy.staff_provider_ids"
              @update:model-value="
                (staff_provider_ids) => saveCurrentSelectedIds({ staff_provider_ids })
              "
              :hard-lock="$store.state.profile.org_subsidies_agent && subsidy.locked"
              :items="allProviders"
              data-testid="staff-provider-select"
              item-title="name"
              item-value="id"
              clearable
              multiple
            />
          </v-card-text>
        </v-card>
      </template>
    </v-card-text>

    <SaveBar
      @cancel="handleReferredProgramUpdateCancel"
      @save="handleReferredProgramUpdateSave"
      :model-value="changed"
    />

    <ResourceDialog
      @cancel="handleReferredProgramUpdateCancel()"
      @save="confirmSaveReferredProgram()"
      ref="referredProgramDialog"
      :cancellable="true"
      :closeable="true"
      :max-width="600"
      save-button-text="Save and publish"
      title="Are you sure you want to save this IEP program ID?"
    >
      <template #form>
        <v-row data-testid="referredProgramDialog">
          <v-col cols="12">
            <div class="fs-14">
              If the application is submitted, saving will create an enrollment at the specified
              program and notify the family. Please confirm you have entered the correct program.
            </div>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <EnrollmentCreator
      @change="reload"
      ref="enrollmentCreator"
      :program-required="subsidyProgram.allow_program_preference"
      :program-term="terms.program"
      :subsidy="subsidy"
    />
  </v-card>
</template>

<script setup>
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import { useStore } from 'vuex';
import Api from '@/specialist/services/bright_finder';
import EnrollmentCard from '@/specialist/components/enrollments/EnrollmentCard.vue';
import EnrollmentCreator from '@/specialist/components/subsidy/EnrollmentCreator.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SavedProvider from '@/specialist/components/SavedProvider.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useTerms from '@/shared/composables/useTerms';

const { terms } = useTerms();
const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  enrollments: {
    type: Array,
    default: () => [],
  },
  subsidy: {
    type: Object,
    default: null,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  'change:enrollments',
  'createdEnrollment',
  'loadEnrollments',
  'updateSubsidy',
]);

const allProviders = ref([]);
const changed = ref(false);
const canCreate = ref(ableToCreate());
const canPublish = ref(
  store.state.profile.org_enrollments_edit &&
    props.subsidyProgram.enable_publish_of_subsidy_enrollment_public_statuses,
);
const favorites = ref([]);
const filters = ref({
  sort_field: 'preference_order',
  sort_dir: 'asc',
  subsidy_id: props.subsidy.id,
});
const enrollmentCreator = ref(null);
const fundingSources = ref([]);
const iepReferralProgramId = ref(props.subsidy.iep_referral_program_id);
const loading = ref(null);
const processing = ref(null);
const publishable = ref(false);
const referredProgramDialog = ref(null);

const canEditReferrals = computed(() => {
  return store.state.profile.org_iep_referrals_edit;
});

const displaySavedProviders = computed(() => {
  return (
    !processing.value &&
    (favoriteProviderSelections.value.length > 0 || subsidyProviderSelections.value.length > 0)
  );
});

const favoriteProviderSelections = computed(() => {
  return favorites.value
    .filter((favorite) => !props.subsidy.provider_ids.includes(favorite.provider_id))
    .filter((favorite) => !props.enrollments.find((er) => er.provider_id === favorite.provider_id));
});

const readonly = computed(() => {
  return !store.state.profile.org_enrollments_edit;
});

const showIepProgramId = computed(
  () =>
    props.subsidyProgram.enable_iep_direct_placement &&
    props.subsidy.child_application_data.individualized_education_plan,
);

const subsidyUpdatedAt = computed(() => props.subsidy.updated_at);

const subsidyProviderSelections = computed(() => {
  return props.subsidy.provider_ids.filter(
    (pid) => !props.enrollments.find((er) => er.provider_id === pid),
  );
});

function ableToCreate() {
  if (store.state.profile.org_enrollments_agent) {
    if (props.subsidy.submitted_at) {
      return !props.subsidy.locked;
    }
    return true;
  }
  return !!store.state.profile.org_enrollments_create;
}

function createEnrollment(providerId, programId, openingId) {
  processing.value = true;
  const params = {
    child_id: props.subsidy.child_id,
    subsidy_id: props.subsidy.id,
    opening_id: openingId,
    program_id: programId,
    provider_id: providerId,
    status: ENROLLMENT_STATUSES.SELECTED,
    subsidy_program_id: props.subsidy.subsidy_program_id,
  };

  Api.organization.enrollment.create(
    params,
    async (resp) => {
      emit('createdEnrollment', resp.data);
      processing.value = false;
      await loadProviders({ useProps: true });
    },
    (err) => {
      eventBus.error(err);
      processing.value = false;
    },
  );
}

async function loadEnrollments() {
  // TODO: Maybe refactor. The order in which we set enrollments, emit, process, and load values seems suboptimal.
  const resp = await Api.organization.enrollment.index(filters.value);
  const enrollmentsFromApi = resp.data;

  emit('loadEnrollments', enrollmentsFromApi);
  publishable.value = enrollmentsFromApi.some(
    (enrollment) => enrollment.status !== enrollment.public_status,
  );
  processing.value = false;
  loading.value = false;

  await loadProviders({ enrollmentsFromApi });
}

async function load() {
  await loadEnrollments();
  await loadFundingSources();
  loadFavorites();
}

function loadFavorites() {
  Api.organization.favorite.index({ group_id: props.subsidy.group_id }, (resp) => {
    favorites.value = resp.data;
  });
}

async function loadFundingSources() {
  if (!props.subsidyProgram.enable_enrollment_funding_sources) return;

  const params = { owner_id: props.subsidyProgram.id };
  const { data } = await Api.organization.funding_source.index(params);
  fundingSources.value = data.filter((fs) => fs.enrollment_assignable);
}

async function loadProviders({ enrollmentsFromApi = [], useProps = false } = {}) {
  if (enrollmentsFromApi.length === 0 && useProps === false) {
    allProviders.value = [];
    return;
  }

  const enrollmentsStateProviderIds = [
    ...new Set(enrollmentsFromApi.map((enrollment) => enrollment.provider_id)),
  ];
  const ids =
    enrollmentsFromApi.length > 0
      ? enrollmentsFromApi.map((enrollment) => enrollment.provider_id)
      : enrollmentsStateProviderIds;

  const resp = await Api.public_api.organization.provider.index({
    ids,
    unpublished: true,
    page_size: 200,
  });

  allProviders.value = resp?.data || [];
}

async function publish() {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to publish these statuses to the family?')) return;

  processing.value = true;
  const resp = await Api.organization.enrollment
    .publish(props.subsidy.id)
    .catch((error) => eventBus.error(error));
  processing.value = false;
  if (!resp?.data) return;

  await load();
}

async function reload(event) {
  if (event) {
    emit('createdEnrollment', event);
  }
  emit('change:enrollments');
  await load();
}

async function reorder(enrollment, preferenceOrderChange) {
  const params = {
    preference_order_was: enrollment.preference_order,
    preference_order: enrollment.preference_order + preferenceOrderChange,
  };
  await Api.organization.enrollment.reorder(enrollment.id, params);
  await loadEnrollments();
}

async function handleEnrollmentDestroy(enrollment) {
  const deletedProviderId = enrollment?.provider_id;

  const current_provider_id =
    deletedProviderId === props.subsidy.current_provider_id
      ? null
      : props.subsidy.current_provider_id;
  const sibling_provider_ids = props.subsidy.sibling_provider_ids.filter(
    (id) => id !== deletedProviderId,
  );
  const staff_provider_ids = props.subsidy.staff_provider_ids.filter(
    (id) => id !== deletedProviderId,
  );

  await saveCurrentSelectedIds({ current_provider_id, sibling_provider_ids, staff_provider_ids });
  await loadEnrollments();
}

async function saveCurrentSelectedIds(attributes) {
  const resp = await Api.organization.subsidy.update(props.subsidy.id, attributes);

  if (resp?.status !== 200) return;
  eventBus.chime('Saved');
  emit('updateSubsidy', resp.data);
}

function validateMaximumEnrollments() {
  if (
    store.state.profile.org_subsidies_agent === true &&
    store.state.profile.org_subsidies_access === false
  ) {
    if (props.enrollments.length >= props.subsidyProgram?.selection_limit) {
      return eventBus.chime('You have reached the maximum number of enrollments.');
    }
  }

  return enrollmentCreator.value.open();
}

// IEP referral program handling
function handleReferredProgramUpdateCancel() {
  iepReferralProgramId.value = props.subsidy.iep_referral_program_id;
  changed.value = false;
  processing.value = false;
}

async function handleReferredProgramUpdateSave() {
  processing.value = true;
  referredProgramDialog.value.open();
}

async function confirmSaveReferredProgram() {
  const resp = await Api.organization.subsidy.updateIepReferralProgram(props.subsidy.id, {
    iep_referral_program_id: iepReferralProgramId.value,
  });
  if (resp?.status === 200) {
    await loadEnrollments();
    eventBus.chime('Saved');
  } else {
    iepReferralProgramId.value = props.subsidy.iep_referral_program_id;
  }

  processing.value = false;
  changed.value = false;
  referredProgramDialog.value.close();
}
// End IEP referral program handling

watch(subsidyUpdatedAt, load, { immediate: true });

onMounted(load);
</script>
