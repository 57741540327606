<template>
  <div v-if="eligibilityProgram">
    <BreadcrumbTitle
      :back-name="eligibilityProgram.name"
      :back-route="{ name: 'EligibilityProgramShow' }"
      name="Eligibility"
    >
      <template #actions>
        <v-btn
          @click="draftFundingSource"
          color="primary"
          prepend-icon="add"
        >
          {{ $t('New funding source') }}
        </v-btn>
      </template>
    </BreadcrumbTitle>

    <div
      v-for="source in fundingSources"
      :key="source.id"
      class="mb-8"
    >
      <FundingSourceCard
        @destroy="loadFundingSources"
        @move="reorder(source, $event)"
        :display-index="getDisplayPositionIndex(source)"
        :schemas="schemas"
        :show-eligibility-required="false"
        :show-enrollment-assignable="false"
        :show-publicly-listed="false"
        :source="source"
        :total-count="fundingSources.length"
        ordered
      />
    </div>

    <ResourceDialog
      @save="createFundingSource"
      :fields="fundingSourceFields"
      :processing="processing"
      action="new"
      save-button-text="Create"
      title="Create funding source"
    />

    <SaveBar
      @cancel="loadEligibilityProgram"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import { useRoute } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper.js';
import useEventBus from '@/shared/composables/useEventBus.js';

const changed = ref(false);
const eventBus = useEventBus();
const funds = ref([]);
const fundingSources = ref([]);
const id = ref(null);
const processing = ref(false);
const publicSchemas = ref([]);
const eligibilityProgram = ref(null);
const route = useRoute();
const { updateQuery } = useRouterHelper();

const fundingSourceFields = computed(() => {
  return [
    {
      required: true,
      text: 'Name',
      value: 'name',
    },
    {
      required: true,
      text: 'Fund',
      value: 'fund_id',
      items: funds.value,
      itemText: 'name',
      itemValue: 'id',
    },
  ];
});

const schemas = computed(() => {
  if (!eligibilityProgram.value?.eligibilityScreenerSchema?.id) return [];

  const eligibilityScreenerSchema =
    publicSchemas.value.find(
      (schema) => schema.id == eligibilityProgram.value.eligibilityScreenerSchema.id,
    ) || null;

  return eligibilityScreenerSchema ? [eligibilityScreenerSchema] : [];
});

onMounted(async () => {
  id.value = route.params.id;

  await load();
});

async function createFundingSource(newValue) {
  processing.value = true;
  await Api.organization.funding_source.create({
    ...newValue,
    owner_id: id.value,
    owner_type: 'EligibilityProgram',
  });

  await updateQuery({ action: null });
  await loadFundingSources();

  processing.value = false;
}

async function draftFundingSource() {
  await updateQuery({ action: 'new' });
}

function getDisplayPositionIndex(source) {
  if (source.display_order !== null) return source.display_order - 1;

  return fundingSources.value.indexOf(source);
}

async function load() {
  await loadFundingSources();
  await loadFunds();
  await loadEligibilityProgram();
  loadPublicSchemas();
}

async function loadFunds() {
  const response = await Api.organization.fund.index({});
  funds.value = response?.data;
}

async function loadFundingSources() {
  const params = { owner_id: id.value };
  const response = await Api.organization.funding_source.index(params);
  fundingSources.value = response?.data;
}

function loadPublicSchemas() {
  Api.public_api.organization.schema.index((response) => {
    publicSchemas.value = response?.data;
  });
}

async function loadEligibilityProgram() {
  const response = await Api.organization.eligibilityProgram.get(id.value);
  eligibilityProgram.value = response?.data;
}

async function reorder(source, displayOrderChange) {
  const params = {
    display_order_was: source.display_order,
    display_order: getDisplayPositionIndex(source) + 1 + displayOrderChange,
  };

  await Api.organization.funding_source.reorder(source.id, params);
  await loadFundingSources();
}

function save() {
  processing.value = true;
  Api.organization.eligibilityProgram.update(
    route.params.subsidyProgramId,
    eligibilityProgram.value,
    (response) => {
      processing.value = false;
      eligibilityProgram.value = response.data;
      changed.value = false;
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}
</script>
