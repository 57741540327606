<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle title="Businesses" />

    <v-toolbar class="mb-3 px-3 bb-1">
      <v-row>
        <v-col class="mxw-340">
          <v-text-field
            v-model="businessQuery"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            color="secondary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>

        <v-col class="ta-right">
          <v-btn
            v-if="$store.state.profile.org_business_edit"
            @click="$refs.newBusiness.open()"
            color="primary"
            prepend-icon="add"
            variant="flat"
          >
            {{ $t('New business') }}
          </v-btn>

          <ResourceDialog
            @save="createBusiness"
            ref="newBusiness"
            :fields="newBusinessValues"
            :processing="processing"
            title="New business"
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      @update:page="handlePageChange"
      @update:sort-by="handleSortChange"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="businesses"
      :items-length="totalItemCount"
      :items-per-page="pageSize"
      :loading="processing"
      :page="page"
      class="mx-3 b-1 bc-outlined-gray"
      fixed-header
      hover
    >
      <template #item.name="{ item }">
        {{ item.name || '-' }}
      </template>

      <template #item.address="{ item }">
        {{ item.address || '-' }}
      </template>

      <template #item.city="{ item }">
        {{ item.city || '-' }}
      </template>

      <template #no-data>
        <NullState class="my-4" />
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const businesses = ref([]);

const page = ref(1);
const pageSize = ref(null);
const processing = ref(false);
const businessQuery = ref('');
const queryCount = ref(0);
const sortField = ref(null);
const sortDirection = ref(null);
const totalItemCount = ref(null);

const newBusinessValues = [{ text: 'Name', value: 'name' }];
const tableHeaders = [
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Address',
    value: 'address',
    align: 'center',
  },
  {
    title: 'City',
    value: 'city',
    align: 'center',
  },
  {
    title: 'Staff',
    key: 'count_of_staff',
    align: 'center',
  },
  {
    title: 'Locations',
    key: 'count_of_locations',
    align: 'center',
  },
];

watch(
  () => businessQuery.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      page.value = 1;
      void load();
    }
  },
);

onMounted(load);

async function createBusiness(newBiz) {
  processing.value = true;
  const params = {
    ...newBiz,
    schema_id: Object.values(store.state.schemas).find((schema) => schema.data_type === 'Business')
      .id,
    confirmed: true,
  };

  const resp = await Api.organization.business.create(params);
  processing.value = false;
  if (resp.status !== 201) return;

  router.push({ name: 'BusinessShow', params: { businessId: resp.data.id } });
}

function handlePageChange(newPageValue) {
  page.value = newPageValue;
  void load();
}

function handleRowClick(_event, { item }) {
  router.push({ name: 'BusinessShow', params: { businessId: item.id } });
}

function handleSortChange(sorts) {
  // Note: we currently only support a single sort/dir
  // so we only access the first item in passed array
  if (sorts.length === 0) {
    sortField.value = null;
    sortDirection.value = null;
  } else {
    sortField.value = sorts[0].key;
    sortDirection.value = sorts[0].order;
  }
  void load();
}

async function load() {
  const filters = {
    query: businessQuery.value,
    page: page.value || 1,
  };
  if (sortField.value) {
    filters.sort_field = sortField.value;
    filters.sort_dir = sortDirection.value;
  }

  queryCount.value += 1;
  const currentQueryCount = queryCount.value;

  const resp = await Api.organization.business.index(filters);
  if (queryCount.value !== currentQueryCount) return;

  businesses.value = resp.data;
  pageSize.value = parseInt(resp.headers['x-page-size']);
  totalItemCount.value = parseInt(resp.headers['x-total-count']);
}
</script>
