<template>
  <v-dialog
    v-model="visible"
    max-width="768"
  >
    <v-card>
      <v-card-title>{{ $t('Create a new provider') }}</v-card-title>
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="provider.name"
            cols="12"
            data-cy="provider-name-input"
            md="6"
            message="Name (required)"
          />
          <LabeledTextfield
            v-model="provider.owner_email"
            cols="12"
            data-cy="provider-email-input"
            md="6"
            message="Provider registered/owner email"
          />
        </v-row>
        <v-row>
          <LabeledTextfield
            v-model="provider.state_provider_id"
            cols="12"
            data-cy="provider-license-input"
            md="6"
            message="License # if applicable"
          />
          <LabeledSimpleSelect
            v-model="provider.schema_id"
            :items="providerSchemas"
            cols="12"
            item-title="name"
            item-value="id"
            md="6"
            message="Provider type"
          />
        </v-row>
      </v-card-text>
      <v-card-actions class="bc-very-light-gray bt-1 px-4 py-4">
        <v-btn
          @click="visible = false"
          :loading="processing"
          variant="text"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="create()"
          :disabled="disableCreate"
          :loading="processing"
          color="secondary"
          data-cy="create-button"
          variant="flat"
        >
          {{ $t('Create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

defineExpose({ open });

const emit = defineEmits(['create']);

const eventBus = useEventBus();
const store = useStore();

const visible = ref(false);
const processing = ref(false);
const provider = ref(null);

const disableCreate = computed(() => {
  return !provider.value.name || !provider.value.schema_id;
});

const providerSchemas = computed(() => {
  return Object.values(store.state.schemas).filter((schema) => schema.data_type === 'Provider');
});

function open(businessId) {
  processing.value = false;
  provider.value = getDraftProvider(businessId);
  visible.value = true;
}

function getDraftProvider(businessId) {
  return {
    business_id: businessId,
    name: null,
    schema_id: null,
    state: null,
    state_provider_id: null,
  };
}

function create() {
  processing.value = true;
  provider.value.state =
    store.state.schemas[provider.value.schema_id].definition.properties.state.default;
  Api.organization.provider.create(
    provider.value,
    (resp) => {
      visible.value = false;
      emit('create', resp.data);
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors.join('. '));
    },
  );
}
</script>
