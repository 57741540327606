<template>
  <v-card
    :border="!dense"
    tile
  >
    <v-card-title v-if="!dense">
      {{ $t('Award summary') }}
    </v-card-title>

    <v-divider />

    <v-card-text
      v-if="provider"
      class="fs-16"
    >
      <v-row dense>
        <v-col
          cols="12"
          sm="4"
        >
          {{ $t('Total payment amount:') }}
        </v-col>

        <v-col
          class="c-light-black fw-600"
          cols="12"
          sm="8"
        >
          {{ currency(subsidyAward.total_award_amount) }}
        </v-col>
      </v-row>

      <v-divider class="my-3" />

      <v-row dense>
        <v-col
          cols="12"
          sm="4"
        >
          {{ $t('Funds reserved on:') }}
        </v-col>

        <v-col
          class="c-light-black fw-600"
          cols="12"
          sm="8"
        >
          <LongDate :date="subsidyAward.reserved_at" />
        </v-col>
      </v-row>

      <template v-if="!!subsidyAward.submitted_at">
        <v-divider class="my-3" />

        <v-row dense>
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t('Awarded on:') }}
          </v-col>
          <v-col
            class="c-light-black fw-600"
            cols="12"
            sm="8"
          >
            <LongDate :date="subsidyAward.submitted_at" />
          </v-col>
        </v-row>
      </template>

      <template v-if="!!subsidyAward.closed_at">
        <v-divider class="my-3" />
        <v-row dense>
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t('Closed on:') }}
          </v-col>

          <v-col
            class="c-light-black fw-600"
            cols="12"
            sm="8"
          >
            <LongDate :date="subsidyAward.closed_at" />
          </v-col>
        </v-row>
      </template>

      <template v-if="!!subsidyAward.finish_date">
        <v-divider class="my-3" />

        <v-row dense>
          <v-col
            cols="12"
            sm="4"
          >
            {{ $t('Finished on:') }}
          </v-col>

          <v-col
            class="c-light-black fw-600"
            cols="12"
            sm="8"
          >
            <LongDate :date="subsidyAward.finish_date" />
          </v-col>
        </v-row>
      </template>

      <v-divider class="my-3" />

      <v-row dense>
        <v-col
          cols="12"
          sm="4"
        >
          {{ $t('Pay to:') }}
        </v-col>

        <v-col
          class="c-light-black fw-600"
          cols="12"
          sm="8"
        >
          <div v-text="provider.name" />

          <div v-text="providerAddress" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import LongDate from '@/shared/components/LongDate.vue';
import { currency } from '@/plugins/filters';
import { formatAddress } from '@/shared/services/address';

const props = defineProps({
  dense: Boolean,
  provider: {
    type: Object,
    default: null,
  },
  subsidyAward: {
    type: Object,
    default: null,
  },
});

const providerAddress = computed(() => {
  if (!props.provider) return '';

  return formatAddress(
    props.provider.address1,
    props.provider.city,
    props.provider.state,
    props.provider.zip,
  );
});
</script>
