<template>
  <div v-if="survey && schema">
    <v-toolbar
      class="mb-3"
      height="60"
      border
      flat
    >
      <v-row class="d-flex align-center px-4">
        <v-col
          class="d-flex align-center"
          cols="8"
        >
          <v-btn
            :aria-label="$t('Back to all surveys')"
            :to="{ name: 'SurveyIndex' }"
            class="me-3"
            variant="text"
            icon
          >
            <v-icon
              class="c-black"
              size="28"
            >
              west
            </v-icon>
          </v-btn>

          <div
            v-text="survey.id"
            class="c-black d-inline-block fs-22 fw-600"
          />
        </v-col>

        <v-col
          class="d-flex justify-end"
          cols="4"
        >
          <ActionMenu
            @click:action:destroy="destroy"
            :items="[{ event: 'destroy', avatar: 'delete', title: 'Delete' }]"
            button-icon="more_vert"
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container>
      <QuestionSet
        v-model="survey"
        :color="null"
        :elevation="0"
        :questions="validQuestions"
        :schema="schema.definition"
        condensed
        dense
        expanded
        hide-actions
        outlined
        readonly
        tile
      />
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const router = useRouter();
const store = useStore();

const schema = ref(null);
const schemaId = computed(() => schema.value?.id);
const survey = ref([]);

const { loadQuestions, validQuestions } = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef: schemaId,
  syncedObjectRef: schema,
});

watch(
  () => route.params.surveyId,
  () => load(),
  { immediate: true },
);

onMounted(() => load());

async function destroy() {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete this survey?')) return;

  await Api.organization.survey.destroy(route.params.surveyId);
  router.replace({ name: 'SurveyIndex' });
}

async function load() {
  const resp = await Api.organization.survey.get(route.params.surveyId);
  survey.value = resp.data;
  schema.value = store.state.schemas[survey.value.schema_id];

  await loadQuestions();
}
</script>
